import React from 'react';
import { isArray } from 'lodash';
import AdvanceDateRangePicker from '../../AdvanceDateRangePicker/AdvanceDateRangePicker';
import { AdvanceCriteriaInputProps } from '../AdvanceCriteriaInputTools';

const AdvanceCriteriaDateOptions: React.FC<AdvanceCriteriaInputProps> = ({
  criteria,
}) => {
  const initialValue = criteria.value && isArray(criteria.value) ? criteria.value[0] : '';
  return (
    <AdvanceDateRangePicker namePath={[criteria.code]} initialValue={initialValue} />
  );
};

export default AdvanceCriteriaDateOptions;
