/* eslint-disable @typescript-eslint/no-unused-vars */
// TODO Fix eslint errors
import React, { useEffect, useState } from 'react';
import { Form, DatePicker, Button, Input, InputNumber, Select, Tabs, Popover, Space } from 'antd';
import { isNil } from 'lodash';
import dayjs, { Dayjs } from 'dayjs';
import { useLocalStorageState } from 'ahooks';
import { criteriaInputKeyGenerator } from '../CriteriaInputFactory';
import { Locale } from '../../../../localization/LocalizationKeys';
import { CriteriaInputProps } from '../CriteriaInputTools';
import { useLocalization } from '../../../util/useLocalization';
import { DeserializeDate, SerialiseDate, TimeSearch } from './CriteriaDateUtils';
import { DATE_FORMAT } from '../../../util/format';
import AdvanceDateRangePicker from '../../AdvanceDateRangePicker/AdvanceDateRangePicker';

1;

const { RangePicker } = DatePicker;
type TabKey = 'newerThan'|'olderThan'|'exactDate'|'range';


 type DateFormInput = {
   duration?: number;
   durationType?: string;
   exactDate?: Dayjs;
   range?: [Dayjs, Dayjs];
 };

// TODO: This display string should be way better!
const generateDisplayString = (activeKey: TabKey, formValues: string): string => {
  const args = DeserializeDate(formValues);
  switch (activeKey) {
    case 'newerThan':
    case 'olderThan':
      return args.to?.relativePoint ?? args.from?.relativePoint ?? '';
    case 'exactDate':
      return args.to?.timestamp ? dayjs(args.to?.timestamp)?.format(DATE_FORMAT) : '';
    case 'range':
      if (!args.from?.timestamp && !args.to?.timestamp) return '';
      // eslint-disable-next-line max-len
      return `${dayjs(args.from?.timestamp)?.format(DATE_FORMAT) ?? ''} to ${dayjs(args.to?.timestamp)?.format(DATE_FORMAT) ?? ''}`;
    default:
      return '';
  }
};


const CriteriaDateOptions: React.FC<CriteriaInputProps> = ({
  criteria,
  inputPath,
  initialValue,
  disabled,
}) => {
  const key = criteriaInputKeyGenerator(criteria, inputPath);

  return (
    <AdvanceDateRangePicker namePath={[key]} initialValue={initialValue} disabled={disabled} />
  );
};

export default CriteriaDateOptions;
