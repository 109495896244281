import React, { Dispatch, SetStateAction, useState } from 'react';
import { Form, FormInstance, Input, Steps, Typography } from 'antd';
import * as UUID from 'uuid';
import SearchConditionBuilder from './SearchConditionBuilder';
import { Locale } from '../../../../localization/LocalizationKeys';
import {
  EntityTypeEnum,
  SearchInput
} from '../../../../gql/typings';
import AdvanceSearchSave from './AdvanceSearchSave';
import { useLocalization } from '../../../util/useLocalization';

type AdvanceSearchContextType = {
  entityType: EntityTypeEnum;
  form?: FormInstance | undefined;
  setSearchStep?: Dispatch<SetStateAction<number>> | undefined;
};

export const AdvanceSearchContext = React.createContext<AdvanceSearchContextType>(
  { entityType: EntityTypeEnum.PERSON, form: undefined, setSearchStep: undefined }
);

type AdvancedSearchBuilderProps = {
  entityType: EntityTypeEnum;
};

const AdvancedSearchBuilder: React.FC<AdvancedSearchBuilderProps> = ({ entityType }) => {
  const [uuidCode] = useState(UUID.v4().toString());
  const initialValue = { entityType, code: uuidCode };
  const [advanceSearchForm] = Form.useForm<SearchInput>();
  // const [tableMapperMode, setTableMapperMode] = useState(IsMappingJoinBasicEnum.BASIC);
  /*  const { data } = useQuery<AdvancedSearchBuilderQuery, AdvancedSearchBuilderQueryVariables>(DATA_QUERY, {
    variables: {
      entityType,
      mode: tableMapperMode,
    },
  }); */
  // const search = useAdvancedReactiveSearch();
  // const [getSearch, {
  //   data: advanceSearchSingle }] = useLazyQuery<GetSingleAdvanceSearchDataQueryQuery
  // , GetSingleAdvanceSearchDataQueryQueryVariables>(GET_SINGLE_ADVANCE_SEARCH_DATA_QUERY, {
  //   fetchPolicy: 'network-only'
  // });
  // const [getAdvanceSearch, {
  //   data: advanceSearchData
  // }] = useLazyQuery<GetAdvanceSearchDataQueryQuery,
  // GetAdvanceSearchDataQueryQueryVariables>(GET_ADVANCE_SEARCH_DATA_QUERY, {
  //   fetchPolicy: 'network-only'
  // });
  const localization = useLocalization();
  const [searchStep, setSearchStep] = useState(0);

  // useEffect(() => {
  //   if (editState[0]) {
  //     getAdvanceSearch({
  //       variables: {
  //         id: editState[0]
  //       }
  //     });
  //   }
  // }, [editState[0]]);
  //
  // useEffect(() => {
  //   if (advanceSearchData) {
  //     advanceSearchForm.setFieldsValue(advanceSearchData.singleSearch);
  //   }
  //
  // }, [advanceSearchData]);


  return (
    <div className="advanced-search-builder-container">
      <Typography.Paragraph>
        {localization.formatMessage(Locale.Text.Advance_search_description)}
      </Typography.Paragraph>
      <AdvanceSearchContext.Provider value={{
        entityType,
        form: advanceSearchForm,
        setSearchStep
      }}
      >
        <Steps
          style={{ margin: '25px 0' }}
          current={searchStep}
          items={[
            {
              title: localization.formatMessage(Locale.Text.Build_search),
            },
            {
              title: localization.formatMessage(Locale.Text.Save_search),
            }
          ]}
          onChange={(e) => setSearchStep(e)}
        />
        <div className={searchStep === 1 ? 'hide-content' : ''}>
          <Form form={advanceSearchForm} initialValues={initialValue}>
            <Form.Item name='entityType' hidden>
              <Input value={entityType} />
            </Form.Item>
            <Form.Item name='code' hidden>
              <Input value={uuidCode} />
            </Form.Item>
            <SearchConditionBuilder nextStep={() => {
              setSearchStep(1);
            }}
            />
          </Form>
        </div>
        <div className={searchStep === 0 ? 'hide-content' : ''}>
          <AdvanceSearchSave previousStep={() => setSearchStep(0)} />
        </div>
      </AdvanceSearchContext.Provider>
    </div>
  );
};

/* const DATA_QUERY = gql`
  query AdvancedSearchBuilder($entityType: EntityTypeEnum!, $mode: IsMappingJoinBasicEnum!) {
    tableMapper(entityType: $entityType) {
      ...SearchConditionBuilder
    }
  }
  ${SearchConditionBuilder_FRAGMENT}
`; */

export default AdvancedSearchBuilder;

