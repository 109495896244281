import { Input, Spin } from 'antd';
import React, { useState } from 'react';
import { usePersonFieldMutation } from '../../../person/Components/attributeFields/AttributeFields/usePersonFieldMutation';
import { TableFieldUpdateViewProps } from '../../../search_old/types';
import { getVariableField } from '../../../person/fields/utils/personFieldsUtils';
import { CUSTOM_UPSERT_MUTATION } from './customFieldUtils';
import { UpsertCustomFieldInputValueMutationVariables } from '../../../../../gql/typings';

const CustomTextInput: React.FC<TableFieldUpdateViewProps> = (props) => {
  const values = getVariableField(props!.record, props!.options, 'customFields');
  const initialValue = values.nodes.length > 0 ? values.nodes[0]!.value.value : '';
  const [text, setText] = useState(initialValue as string);
  const { Renderer, submit, blocking } = usePersonFieldMutation({
    mutation: CUSTOM_UPSERT_MUTATION,
    controlSetting: props.record?.controlSetting,
    skipDcrWarning: true,
  });

  const buildVariable = (): UpsertCustomFieldInputValueMutationVariables => ({
    recordId: props.record.id,
    customFieldCode: props.options.selectedOption!.code as string,
    customValues: [{ newValue: text }],
  });

  const onEndEditing = () => !blocking && props.endEditing();

  const onSaveChange = () => !blocking && submit(buildVariable()).then(onEndEditing);


  return (
    <Renderer>
      <div style={{ position: 'relative' }}>
        <Input
          value={text}
          autoFocus
          onPressEnter={onSaveChange}
          onBlur={onSaveChange}
          onChange={(e) => setText(e.target.value)}
        />
        {blocking && <Spin size='small' style={{ position: 'absolute', top: '8px', right: '5px' }} />}
      </div>
    </Renderer>
  );
};


export default CustomTextInput;

