import React, { useMemo } from 'react';
import { Col, Input, Row, Tooltip } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { useLocalization } from '../../util/useLocalization';
import { AdvanceCriteriaInputProps } from './AdvanceCriteriaInputTools';
import AdvanceCriteriaInputTypes from './AdvanceCriteriaInputTypes';


export const AdvanceCriteriaInputFactory: React.FC<AdvanceCriteriaInputProps> = ({
  criteria,
  form,
  countryCodes,
  options: {
    hasMaxWidth = true,
    icon = null,
    hideTitle,
    tooltipError,
    transparentBackground
  } = {},
}) => {
  const localization = useLocalization();
  const operator = criteria.operation ? `(${criteria.operation})` : '';
  const label = `${localization.formatMessageByStr(criteria.heading ?? 'Input')} ${operator}`;

  const input = useMemo(() => {
    // @ts-ignore
    const Elm = AdvanceCriteriaInputTypes[criteria.inputType];
    return (Elm ? <Col
      span={24}
      key={criteria.code}
      className="input"
      data-criteria-type={criteria.inputType}
    >
      <Elm key={criteria.code} criteria={criteria} form={form} countryCodes={countryCodes} />
    </Col> : <span key={criteria.code}>'{criteria.inputType}' isn't supported!</span>);
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [criteria.code, criteria.inputType]);

  const style: { display: string; maxWidth?: number } = { display: 'grid' };
  if (hasMaxWidth) style.maxWidth = 220;

  return (
    <Input.Group className={`criteria-input-container ${!transparentBackground ? 'contrast-label' : ''}`} style={style}>
      {!hideTitle && <>

        {criteria.conditionType && <span className="meta-label">
          <span className="meta-label-left">{criteria.conditionType.substring(0, 3)}</span>
          {criteria.inputHeadingMeta && <span className="meta-label-right">{criteria.inputHeadingMeta}</span>}
        </span>}
        <span className="label">
          {label}

          {tooltipError && (
            <Tooltip title={tooltipError}>
              &nbsp;<WarningOutlined style={{ color: 'red' }} />
            </Tooltip>
          )}
        </span>
      </> }
      <Row gutter={[4, 4]}>
        <Col span={icon ? 22 : 24}>
          {input}
        </Col>
        {icon && <Col style={{ display: 'flex', alignItems: 'center' }}>{icon}</Col>}
      </Row>
    </Input.Group>
  );
};

