/* eslint-disable max-len */

/*
 * HBE 2022-06-29
 * MARK: Do not convert this file to typescript
 * Step 1: Add key to Lokalize
 * Step 2: Download Lokalize
 * Step 3: Add key to this file, manually
 */

module.exports.Locale = {
  General: {
    Older_than: {
      id: 'General.Older_than',
    },
    Newer_than: {
      id: 'General.Newer_than',
    },
    Up_until: {
      id: 'General.Up_until',
    },
    lowercase_ago: {
      id: 'General.lowercase_ago',
    },
    Unknown: {
      id: 'General.Unknown',
    },
    Available: {
      id: 'General.Available',
    },
    Selected: {
      id: 'General.Selected',
    },
    Common: {
      id: 'General.Common'
    },
    Exact_date: {
      id: 'General.Exact_date'
    },
    Range: {
      id: 'General.Range'
    },
    Type: {
      id: 'General.Type'
    },
    Duration: {
      id: 'General.Duration'
    },
    Days: {
      id: 'General.Days'
    },
    Days_plural: {
      id: 'General.Days_plural',
    },
    Weeks: {
      id: 'General.Weeks'
    },
    Weeks_plural: {
      id: 'General.Weeks_plural',
    },
    Months: {
      id: 'General.Months'
    },
    Months_plural: {
      id: 'General.Months_plural',
    },
    Years: {
      id: 'General.Years'
    },
    Years_plural: {
      id: 'General.Years_plural',
    },
    Minutes: {
      id: 'General.Minutes'
    },
    Hours: {
      id: 'General.Hours'
    },
    Estonia: {
      id: 'General.Estonia',
    },
    Latvia: {
      id: 'General.Latvia',
    },
    Lithuania: {
      id: 'General.Lithuania',
    },
    Netherlands: {
      id: 'General.Netherlands',
    },
    Belgium: {
      id: 'General.Belgium',
    },
    Site_person: {
      id: 'General.Site_person',
    },
    Home: {
      id: 'General.Home',
    },
    Usages: {
      id: 'General.Usages',
    },
    Male: {
      id: 'General.Male',
    },
    Female: {
      id: 'General.Female',
    },
    Table_columns: {
      id: 'General.Table_columns',
    },
    Clients: {
      id: 'General.Clients',
    },
    Affiliations: {
      id: 'General.Affiliations',
    },
    System: {
      id: 'General.System',
    },
    Here: {
      id: 'General.Here',
    },
    Handled: {
      id: 'General.Handled',
    },
    Merge_requests: {
      id: 'General.Merge_requests',
    },
    General: {
      id: 'General.General',
    },
    January: {
      id: 'General.January',
    },
    February: {
      id: 'General.February',
    },
    March: {
      id: 'General.March',
    },
    April: {
      id: 'General.April',
    },
    May: {
      id: 'General.May',
    },
    June: {
      id: 'General.June',
    },
    July: {
      id: 'General.July',
    },
    August: {
      id: 'General.August',
    },
    September: {
      id: 'General.September',
    },
    October: {
      id: 'General.October',
    },
    November: {
      id: 'General.November',
    },
    December: {
      id: 'General.December',
    },
    KPIs: {
      id: 'General.KPIs',
    },
    Attention: {
      id: 'General.Attention',
    },
    Label: {
      id: 'General.Label',
    },
    English_label: {
      id: 'General.English_label',
    },
    Read: {
      id: 'General.Read',
    },
    Mutation: {
      id: 'General.Mutation',
    },
    Category: {
      id: 'General.Category',
    },
    Sort: {
      id: 'General.Sort',
    },
    Accepted: {
      id: 'General.Accepted'
    },
    Unhandled: {
      id: 'General.Unhandled',
    },
    Rejected: {
      id: 'General.Rejected',
    },
    Queue: {
      id: 'General.Queue',
    },
    Denmark: {
      id: 'General.Denmark',
    },
    Norway: {
      id: 'General.Norway',
    },
    Sweden: {
      id: 'General.Sweden',
    },
    Finland: {
      id: 'General.Finland',
    },
    Switzerland: {
      id: 'General.Switzerland',
    },
    Italy: {
      id: 'General.Italy',
    },
    Austria: {
      id: 'General.Austria',
    },
    Slovakia: {
      id: 'General.Slovakia',
    },
    Ok: {
      id: 'General.Ok',
    },
    CustomField: {
      id: 'General.CustomField',
    },
    CustomFields: {
      id: 'General.CustomFields',
    },
    Favorite: {
      id: 'General.Favorite',
    },
    Count_other: {
      id: 'Count.other',
    },
    Consent_Given: {
      id: 'General.consent_given',
    },
    In_progress: {
      id: 'General.In_progress',
    },
    Activity_own: {
      id: 'General.Activity.own',
    },
    Today: {
      id: 'General.Today',
    },
    Tomorrow: {
      id: 'General.Tomorrow',
    },
    Yesterday: {
      id: 'General.Yesterday',
    },
    Reports: {
      id: 'General.Reports',
    },
    Activities: {
      id: 'General.Activities',
    },
    Activity: {
      id: 'General.Activity',
    },
    Activity_Planning: {
      id: 'General.Activity_Planning',
    },
    Close_Activity_Planning: {
      id: 'General.Close_Activity_Planning',
    },
    Additional: {
      id: 'General.Additional',
    },
    Admin: {
      id: 'General.Admin',
    },
    Brick: {
      id: 'General.Brick',
    },
    Bricks: {
      id: 'General.Bricks',
    },
    Call: {
      id: 'General.Call',
    },
    Charset: {
      id: 'General.Charset',
    },
    Column_index: {
      id: 'General.Column_index',
    },
    Column_name: {
      id: 'General.Column_name',
    },
    Consent: {
      id: 'General.Consent',
    },
    Count: {
      id: 'General.Count',
    },
    Counts: {
      id: 'General.Counts',
    },
    Dashboard: {
      id: 'General.Dashboard',
    },
    Overview: {
      id: 'General.Overview',
    },
    Permission_denied: {
      id: 'General.Permission_denied',
    },
    Check: {
      id: 'General.Check',
    },
    MultiSelect: {
      id: 'General.MultiSelect',
    },
    MultiTag: {
      id: 'General.MultiTag',
    },
    Number: {
      id: 'General.Number',
    },
    Radio: {
      id: 'General.Radio',
    },
    Select: {
      id: 'General.Select',
    },
    Tag: {
      id: 'General.Tag',
    },
    Switch: {
      id: 'General.Switch',
    },
    Text: {
      id: 'General.Text',
    },
    Data_Change_Requests_DCR: {
      id: 'General.Data_Change_Requests_DCR',
    },
    Data_Change_Requests: {
      id: 'General.Data_Change_Requests',
    },
    Database: {
      id: 'General.Database',
    },
    DCR: {
      id: 'General.DCR',
    },
    DCR_Plural: {
      id: 'General.DCR_Plural',
    },
    Dynamic_rules: {
      id: 'General.Dynamic_rules',
    },
    Entities: {
      id: 'General.Entities',
    },
    Entity: {
      id: 'General.Entity',
    },
    Entity_type: {
      id: 'General.Entity_type',
    },
    Existing_list: {
      id: 'General.Existing_list',
    },
    Field: {
      id: 'General.Field',
    },
    History: {
      id: 'General.History',
    },
    Related_data: {
      id: 'General.Related_data',
    },
    File: {
      id: 'General.File',
    },
    First_line_header: {
      id: 'General.First_line_header',
    },
    Functions: {
      id: 'General.Functions',
    },
    Function: {
      id: 'General.Function',
    },
    Future: {
      id: 'General.Future',
    },
    Generate_GDPR_report: {
      id: 'General.Generate_GDPR_report',
    },
    Global: {
      id: 'General.Global',
    },
    Grouping: {
      id: 'General.Grouping',
    },
    Hierarchy: {
      id: 'General.Hierarchy',
    },
    Inactive: {
      id: 'General.Inactive',
    },
    Interaction: {
      id: 'General.Interaction',
    },
    Invalid_input: {
      id: 'General.Invalid_input',
    },
    Item: {
      id: 'General.Item',
    },
    List: {
      id: 'General.List',
    },
    List_description: {
      id: 'General.List_description',
    },
    List_type: {
      id: 'General.List_type',
    },
    List_view: {
      id: 'General.List_view',
    },
    Lists: {
      id: 'General.Lists',
    },
    Menu: {
      id: 'General.Menu',
    },
    Menu_items: {
      id: 'General.Menu_items',
    },
    My_lists: {
      id: 'General.My_lists',
    },
    New_value: {
      id: 'General.New_value',
    },
    No: {
      id: 'General.No',
    },
    Number_of: {
      id: 'General.Number_of',
    },
    Numbers: {
      id: 'General.Numbers',
    },
    Numbers_of: {
      id: 'General.Numbers_of',
    },
    Old_value: {
      id: 'General.Old_value',
    },
    Password: {
      id: 'General.Password',
    },
    Persons_HCP: {
      id: 'General.Persons_HCP',
    },
    Person_Information: {
      id: 'General.Person_Information',
    },
    Work_Information: {
      id: 'General.Work_Information',
    },
    Site_Information: {
      id: 'General.Site_Information',
    },
    Previous: {
      id: 'General.Previous',
    },
    Primary: {
      id: 'General.Primary',
    },
    Profile: {
      id: 'General.Profile',
    },
    Profile_Card: {
      id: 'General.Profile_Card',
    },
    Project: {
      id: 'General.Project',
    },
    Projects: {
      id: 'General.Projects',
    },
    Record: {
      id: 'General.Record',
    },
    Report: {
      id: 'General.Report',
    },
    Report_Description: {
      id: 'General.Report_Description',
    },
    Report_overview: {
      id: 'General.Report_overview',
    },
    Report_type: {
      id: 'General.Report_type',
    },
    Requester: {
      id: 'General.Requester',
    },
    Rule: {
      id: 'General.Rule',
    },
    Search_results: {
      id: 'General.Search_results',
    },
    Search_results_count: {
      id: 'General.Search_results_count',
    },
    Search_results_selected: {
      id: 'General.Search_results_selected',
    },
    Shared: {
      id: 'General.Shared',
    },
    Simple_search: {
      id: 'General.Simple_search',
    },
    Sites_HCO: {
      id: 'General.Sites_HCO',
    },
    Static: {
      id: 'General.Static',
    },
    Status: {
      id: 'General.Status',
    },
    Table_view: {
      id: 'General.Table_view',
    },
    Template: {
      id: 'General.Template',
    },
    Tools: {
      id: 'General.Tools',
    },
    Items_Visible: {
      id: 'General.Items_Visible',
      description: 'This is the label used on tables, which shows which and how many items are currently being showed in the table',
      tags: 'not-final',
    },
    Uncalibrated_title: {
      id: 'General.Uncalibrated_title',
    },
    Username: {
      id: 'General.Username',
      description: 'This is for displaying the text "username". Mostly used for input forms',
    },
    User_responsibilities: {
      id: 'General.User_responsibilities',
    },
    Users: {
      id: 'General.Users',
    },
    Roles: {
      id: 'General.Roles',
    },
    Value: {
      id: 'General.Value',
    },
    Visibility: {
      id: 'General.Visibility',
    },
    Visit: {
      id: 'General.Visit',
    },
    Without_position: {
      id: 'General.Without_position',
    },
    Yes: {
      id: 'General.Yes',
    },
    Contact_information: {
      id: 'General.Contact_information',
    }
  },
  Attribute: {
    Territory: {
      id: 'Attribute.Territory',
    },
    Territory_responsible: {
      id: 'Attribute.Territory_responsible',
    },
    Input_heading: {
      id: 'Attribute.Input_heading'
    },
    Placeholder_text: {
      id: 'Attribute.Placeholder_text'
    },
    Predefined_value: {
      id: 'Attribute.Predefined_value'
    },
    Link_validated: {
      id: 'Attribute.Link_validated',
    },
    Customer_selections: {
      id: 'Attribute.Customer_selections',
    },
    Person_street: {
      id: 'Attribute.Person_street',
    },
    Site_webpage: {
      id: 'Attribute.Site_webpage'
    },
    Status_code: {
      id: 'Attribute.Status_code'
    },
    Person_product_segmentation: {
      id: 'Attribute.Person_product_segmentation'
    },
    Person_city: {
      id: 'Attribute.Person_city',
    },
    Person_county: {
      id: 'Attribute.Person_county',
    },
    Person_commune: {
      id: 'Attribute.Person_commune',
    },
    Person_postal_code: {
      id: 'Attribute.Person_postal_code',
    },
    Visit_county: {
      id: 'Attribute.Visit_county',
    },
    Visit_postal_code: {
      id: 'Attribute.Visit_postal_code',
    },
    Mail_county: {
      id: 'Attribute.Mail_county',
    },
    Mail_postal_code: {
      id: 'Attribute.Mail_postal_code',
    },
    Site_visit_street: {
      id: 'Attribute.Site_visit_street',
    },
    Site_visit_city: {
      id: 'Attribute.Site_visit_city',
    },
    Site_visit_county: {
      id: 'Attribute.Site_visit_county',
    },
    Site_visit_commune: {
      id: 'Attribute.Site_visit_commune',
    },
    Site_visit_postal_code: {
      id: 'Attribute.Site_visit_postal_code',
    },
    Site_mail_street: {
      id: 'Attribute.Site_mail_street',
    },
    Site_mail_city: {
      id: 'Attribute.Site_mail_city',
    },
    Site_mail_county: {
      id: 'Attribute.Site_mail_county',
    },
    Site_mail_commune: {
      id: 'Attribute.Site_mail_commune',
    },
    Site_mail_postal_code: {
      id: 'Attribute.Site_mail_postal_code',
    },
    Site_email: {
      id: 'Attribute.Site_email',
    },
    Other_URLs: {
      id: 'Attribute.Other_URLs',
    },
    Homepage: {
      id: 'Attribute.Homepage',
    },
    HCO_Segment: {
      id: 'Attribute.HCO_Segment',
    },
    Added: {
      id: 'Attribute.Added',
    },
    Group_description: {
      id: 'Attribute.Group_description',
    },
    Selection_description: {
      id: 'Attribute.Selection_description',
    },
    Rule_ID: {
      id: 'Attribute.Rule_ID',
    },
    Selection_type: {
      id: 'Attribute.Selection_type',
    },
    Selections: {
      id: 'Attribute.Selections',
    },
    Opted_out: {
      id: 'Attribute.Opted_out',
    },
    By_user: {
      id: 'Attribute.By_user',
    },
    By_system: {
      id: 'Attribute.By_system',
    },
    Timestamp: {
      id: 'Attribute.Timestamp',
    },
    Customer: {
      id: 'Attribute.Customer',
    },
    Final_value: {
      id: 'Attribute.Final_value',
    },
    Person_lists: {
      id: 'Attribute.Person_lists',
    },
    Person_activities: {
      id: 'Attribute.Person_activities',
    },
    Chain: {
      id: 'Attribute.Chain',
    },
    Site_colleagues: {
      id: 'Attribute.Site_colleagues',
    },
    Person_other_sites: {
      id: 'Attribute.Person_other_sites',
    },
    Parent_site: {
      id: 'Attribute.Parent_site',
    },
    Site_names: {
      id: 'Attribute.Site_names',
    },
    Column_key: {
      id: 'Attribute.Column_key',
    },
    Column_heading: {
      id: 'Attribute.Column_heading',
    },
    Column: {
      id: 'Attribute.Column',
    },
    Fiscal_year: {
      id: 'Attribute.Fiscal_year',
    },
    Fiscal_year_start: {
      id: 'Attribute.Fiscal_year_start',
    },
    Visit_address: {
      id: 'Attribute.Visit_address',
    },
    Mail_address: {
      id: 'Attribute.Mail_address',
    },
    Teams: {
      id: 'Attribute.Teams',
    },
    County: {
      id: 'Attribute.County',
    },
    Login_footer_image: {
      id: 'Text.Login_footer_image',
    },
    Domain_path: {
      id: 'Attribute.Domain_path',
    },
    Active: {
      id: 'Attribute.Active',
    },
    Activity_heading: {
      id: 'Attribute.Activity_heading',
    },
    Activity_End_Date: {
      id: 'Attribute.Activity_End_Date',
    },
    Activity_Start_Date: {
      id: 'Attribute.Activity_Start_Date',
    },
    Activity_overview: {
      id: 'Attribute.Activity_overview',
    },
    Activity_Planning: {
      id: 'Attribute.Activity_Planning',
    },
    Activity_search: {
      id: 'Attribute.Activity_search',
    },
    Activity_status: {
      id: 'Attribute.Activity_status',
    },
    Activity_type: {
      id: 'Attribute.Activity_type',
    },
    Activity_super_type: {
      id: 'Attribute.Activity_super_type',
    },
    Activity_user: {
      id: 'Attribute.Activity_user',
    },
    added_by: {
      id: 'Attribute.added_by',
    },
    Address: {
      id: 'Attribute.Address',
    },
    Site_Address: {
      id: 'Attribute.Site_Address',
    },
    Allow_call: {
      id: 'Attribute.Allow_call',
    },
    Allow_email: {
      id: 'Attribute.Allow_email',
    },
    Allow_fax: {
      id: 'Attribute.Allow_fax',
    },
    Allow_mail: {
      id: 'Attribute.Allow_mail',
    },
    Allow_visit: {
      id: 'Attribute.Allow_visit',
    },
    Amount: {
      id: 'Attribute.Amount',
    },
    aPureBase_Fields: {
      id: 'Attribute.aPureBase_Fields',
    },
    aPureBase_ID: {
      id: 'Attribute.aPureBase_ID',
    },
    aPureBase_ID_list: {
      id: 'Attribute.aPureBase_ID_list',
    },
    Person_aPureBase_ID: {
      id: 'Attribute.Person_aPureBase_ID',
    },
    Site_aPureBase_ID: {
      id: 'Attribute.Site_aPureBase_ID',
    },
    Link_aPureBase_ID: {
      id: 'Attribute.Link_aPureBase_ID',
    },
    Attendee: {
      id: 'Attribute.Attendee',
    },
    Attendees: {
      id: 'Attribute.Attendees',
    },
    Birth: {
      id: 'Attribute.Birth',
    },
    Calendar_overview: {
      id: 'Attribute.Calendar_overview',
    },
    Calendar: {
      id: 'Attribute.Calendar',
    },
    Cherry_pick: {
      id: 'Attribute.Cherry_pick',
    },
    Cherry_picked: {
      id: 'Attribute.Cherry_picked',
    },
    City: {
      id: 'Attribute.City',
    },
    Client_ID: {
      id: 'Attribute.Client_ID',
    },
    Contact_Persons: {
      id: 'Attribute.Contact_Persons',
    },
    Country: {
      id: 'Attribute.Country',
    },
    Countries: {
      id: 'Attribute.Countries',
    },
    Created: {
      id: 'Attribute.Created',
    },
    Created_by: {
      id: 'Attribute.Created_by',
    },
    Created_by_user: {
      id: 'Attribute.Created_by_user',
    },
    Created_date: {
      id: 'Attribute.Created_date',
    },
    Create_new: {
      id: 'Attribute.Create_new',
    },
    Creation_date: {
      id: 'Attribute.Creation_date',
    },
    Criteria_Name: {
      id: 'Attribute.Criteria_Name',
    },
    Code: {
      id: 'Attribute.Code'
    },
    Dashboard_overview: {
      id: 'Attribute.Dashboard_overview',
    },
    Overview_tooltip: {
      id: 'Attribute.Overview_tooltip',
    },
    Overview_create_new_item: {
      id: 'Attribute.create_new_item',
    },
    Date: {
      id: 'Attribute.Date',
    },
    Date_of_birth: {
      id: 'Attribute.Date_of_birth',
    },
    Date_of_creation: {
      id: 'Attribute.Date_of_creation',
    },
    Day: {
      id: 'Attribute.Day',
    },

    DB_source: {
      id: 'Attribute.DB_source',
    },
    DCR: {
      id: 'Attribute.DCR',
    },
    DCR_detail_title: {
      id: 'Attribute.DCR_detail_title',
    },
    DCR_overview: {
      id: 'Attribute.DCR_overview',
    },
    DCR_requester: {
      id: 'Attribute.DCR_requester',
    },
    Delimiter: {
      id: 'Attribute.Delimiter',
    },
    Delivery_Count: {
      id: 'Attribute.Delivery_Count',
    },
    Department: {
      id: 'Attribute.Department',
    },
    Description: {
      id: 'Attribute.Description',
    },
    Difference: {
      id: 'Attribute.Difference',
    },
    Done: {
      id: 'Attribute.Done',
    },
    Dynamic_Lists: {
      id: 'Attribute.Dynamic_Lists',
    },
    Education: {
      id: 'Attribute.Education',
    },
    Person_Education: {
      id: 'Attribute.Person_Education',
    },
    Person_Title: {
      id: 'Attribute.Person_Title'
    },
    Email: {
      id: 'Attribute.Email',
    },
    End_date: {
      id: 'Attribute.End_date',
    },
    End_time: {
      id: 'Attribute.End_time',
    },
    Exam_year: {
      id: 'Attribute.Exam_year',
    },
    External_ID: {
      id: 'Attribute.External_ID',
    },
    External_IDs: {
      id: 'Attribute.External_IDs',
    },
    Exclude_Tags: {
      id: 'Attribute.Exclude_Tags',
    },
    External_Link: {
      id: 'Attribute.External_Link',
    },
    Fax: {
      id: 'Attribute.Fax',
    },
    Find_existing: {
      id: 'Attribute.Find_existing',
    },
    First_name: {
      id: 'Attribute.First_name',
    },
    Middle_name: {
      id: 'Attribute.Middle_name',
    },
    Full_name: {
      id: 'Attribute.Full_name',
    },
    Future_Deliveries: {
      id: 'Attribute.Future_Deliveries',
    },
    GDPR: {
      id: 'Attribute.GDPR',
    },
    Mail_To_Private: {
      id: 'Attribute.Mail_To_Private',
    },
    Instant_Validation: {
      id: 'Attribute.Instant_Validation'
    },
    Gender: {
      id: 'Attribute.Gender',
    },
    Global_Database: {
      id: 'Attribute.Global_Database',
    },
    HCO: {
      id: 'Attribute.HCO',
    },
    HCP_Fullname: {
      id: 'Attribute.HCP_Fullname',
    },
    HCP_Segment: {
      id: 'Attribute.HCP_Segment',
    },
    HCP_Segmentation: {
      id: 'Attribute.HCP_Segmentation',
    },
    HCP_MassAssign: {
      id: 'Attribute.HCP_MassAssign',
    },
    HCO_MassAssign: {
      id: 'Attribute.HCO_MassAssign',
    },
    HCP: {
      id: 'Attribute.HCP',
    },
    HCPs: {
      id: 'Attribute.HCPs',
    },
    Heading: {
      id: 'Attribute.Heading',
    },
    Health_Care_Organization: {
      id: 'Attribute.Health_Care_Organization',
    },
    Health_Care_Professional: {
      id: 'Attribute.Health_Care_Professional',
    },
    Healthcare_professionals: {
      id: 'Attribute.Healthcare_professionals',
    },
    Hour: {
      id: 'Attribute.Hour',
    },
    ID: {
      id: 'Attribute.ID',
    },
    Import_complete: {
      id: 'Attribute.Import_complete',
    },
    Import_date: {
      id: 'Attribute.Import_date',
    },
    Import_overview: {
      id: 'Attribute.Import_overview',
    },
    Imported: {
      id: 'Attribute.Imported',
    },
    Is_primary: {
      id: 'Attribute.Is_primary',
    },
    Language: {
      id: 'Attribute.Language',
    },
    Languages: {
      id: 'Attribute.Languages',
    },
    Last_name: {
      id: 'Attribute.Last_name',
    },
    Latitude: {
      id: 'Attribute.Latitude',
    },
    List_Entity: {
      id: 'Attribute.List_Entity',
    },
    List_overview: {
      id: 'Attribute.List_overview',
    },
    Location: {
      id: 'Attribute.Location',
    },
    Longitude: {
      id: 'Attribute.Longitude',
    },
    Mail_city: {
      id: 'Attribute.Mail_city',
    },
    Mail_commune: {
      id: 'Attribute.Mail_commune',
    },
    Mail_street: {
      id: 'Attribute.Mail_street',
    },
    Mail_address_line_2: {
      id: 'Attribute.Mail_address_line_2',
    },
    Mail_zip: {
      id: 'Attribute.Mail_zip',
    },
    Market_Access: {
      id: 'Attribute.Market_Access',
    },
    Market_Access_HCO: {
      id: 'Attribute.Market_Access_HCO',
    },
    Market_Access_HCP: {
      id: 'Attribute.Market_Access_HCP',
    },
    Mass_assign: {
      id: 'Attribute.Mass_assign',
    },
    Meta_Data: {
      id: 'Attribute.Meta_Data',
    },
    Mobile: {
      id: 'Attribute.Mobile',
    },
    Month: {
      id: 'Attribute.Month',
    },
    More_data: {
      id: 'Attribute.More_data',
    },
    Name: {
      id: 'Attribute.Name',
    },
    Name_Prefix: {
      id: 'Attribute.Name_Prefix',
    },
    None: {
      id: 'Attribute.None',
    },
    Note: {
      id: 'Attribute.Note',
    },
    Note_colon: {
      id: 'Attribute.Note_colon',
    },
    Note_Details: {
      id: 'Attribute.Note_Details',
    },
    Notes: {
      id: 'Attribute.Notes',
    },
    Affiliation_activity_header: {
      id: 'Attribute.Affiliation_activity_header',
    },
    Affiliation_site_header: {
      id: 'Attribute.Affiliation_site_header',
    },
    Affiliation_list_header: {
      id: 'Attribute.Affiliation_list_header',
    },
    Affiliation_person_header: {
      id: 'Attribute.Affiliation_person_header',
    },
    Notes_count: {
      id: 'Attribute.Notes_count',
    },
    Now_Matching: {
      id: 'Attribute.Now_Matching',
    },
    Official_ID: {
      id: 'Attribute.Official_ID',
    },
    Origin_Source: {
      id: 'Attribute.Origin_Source',
    },
    Maintainer_Source: {
      id: 'Attribute.Maintainer_Source',
    },
    Open: {
      id: 'Attribute.Open',
    },
    Own_fields: {
      id: 'Attribute.Own_fields',
    },
    Person_Affiliations: {
      id: 'Attribute.Person_Affiliations',
    },
    Person_Details: {
      id: 'Attribute.Person_Details',
    },
    Person_HCP: {
      id: 'Attribute.Person_HCP',
    },
    Person_Merge: {
      id: 'Attribute.Person_Merge',
    },
    Person_overview: {
      id: 'Attribute.Person_overview',
    },
    Person_type: {
      id: 'Attribute.Person_type',
    },
    Phone: {
      id: 'Attribute.Phone',
    },
    Position: {
      id: 'Attribute.Position',
    },
    Postalmail: {
      id: 'Attribute.Postalmail',
    },
    Preferences: {
      id: 'Attribute.Preferences',
    },
    Preferred_Languages: {
      id: 'Attribute.Preferred_Languages',
    },
    Primary_Position: {
      id: 'Attribute.Primary_Position',
    },
    Primary_Workplace: {
      id: 'Attribute.Primary_Workplace',
    },
    Private: {
      id: 'Attribute.Private',
    },
    Product: {
      id: 'Attribute.Product',
    },
    Product_brand: {
      id: 'Attribute.Product_brand',
    },
    Product_Deliveries: {
      id: 'Attribute.Product_Deliveries',
    },
    Product_Segment: {
      id: 'Attribute.Product_Segment',
    },
    Product_Segmentation: {
      id: 'Attribute.Product_Segmentation',
    },
    Product_segmentation_code: {
      id: 'Attribute.Product_segmentation_code',
    },
    Product_Samples: {
      id: 'Attribute.Product_Samples',
    },
    Products: {
      id: 'Attribute.Products',
    },
    Project_overview: {
      id: 'Attribute.Project_overview',
    },
    Project_Role_Affiliation: {
      id: 'Attribute.Project_Role_Affiliation',
    },
    Project_Title: {
      id: 'Attribute.Project_Title',
    },
    PureAdvance_ID: {
      id: 'Attribute.PureAdvance_ID',
    },
    Person_PureAdvance_ID: {
      id: 'Attribute.Person_PureAdvance_ID',
    },
    Site_PureAdvance_ID: {
      id: 'Attribute.Site_PureAdvance_ID',
    },
    Link_PureAdvance_ID: {
      id: 'Attribute.Link_PureAdvance_ID',
    },
    Records: {
      id: 'Attribute.Records',
    },
    Report_overview: {
      id: 'Attribute.Report_overview',
    },
    Response: {
      id: 'Attribute.Response',
    },
    Res_user: {
      id: 'Attribute.Res_user',
    },
    Responsible_Area: {
      id: 'Attribute.Responsible_Area',
    },
    Responsible_User: {
      id: 'Attribute.Responsible_User',
    },
    Responsible_users: {
      id: 'Attribute.Responsible_users',
    },
    Role: {
      id: 'Attribute.Role',
    },
    Saved_Searches: {
      id: 'Attribute.Saved_Searches',
    },
    Segmentation: {
      id: 'Attribute.Segmentation',
    },
    Segmentation_code: {
      id: 'Attribute.Segmentation_code',
    },
    Selected_Project: {
      id: 'Attribute.Selected_Project',
    },
    Select_match: {
      id: 'Attribute.Select_match',
    },
    Seminar_Center: {
      id: 'Attribute.Seminar_Center',
    },
    Seminar_Language: {
      id: 'Attribute.Seminar_Language',
    },
    Sex: {
      id: 'Attribute.Sex',
    },
    Shared_lists: {
      id: 'Attribute.Shared_lists',
    },
    Site: {
      id: 'Attribute.Site',
    },
    Site_Affiliations: {
      id: 'Attribute.Site_Affiliations',
    },
    Site_Details: {
      id: 'Attribute.Site_Details',
    },
    Site_HCO: {
      id: 'Attribute.Site_HCO',
    },
    Site_Merge: {
      id: 'Attribute.Site_Merge',
    },
    Site_name: {
      id: 'Attribute.Site_name',
    },
    Site_name2: {
      id: 'Attribute.Site_name2',
    },
    Number_of_chairs: {
      id: 'Attribute.Number_of_chairs',
    },
    Number_of_patients: {
      id: 'Attribute.Number_of_patients',
    },
    Number_of_beds: {
      id: 'Attribute.Number_of_beds',
    },
    Site_overview: {
      id: 'Attribute.Site_overview',
    },
    Site_specialty: {
      id: 'Attribute.Site_specialty',
    },
    Site_type: {
      id: 'Attribute.Site_type',
    },
    Site_sector_type: {
      id: 'Attribute.Site_sector_type',
    },
    Ownership: {
      id: 'Attribute.Ownership',
    },
    Specialties: {
      id: 'Attribute.Specialties',
    },
    Summary: {
      id: 'Attribute.Summary',
    },
    Strength: {
      id: 'Attribute.Strength',
    },
    Strength_Unit_Type: {
      id: 'Attribute.Strength_Unit_Type',
    },

    step_unvisited: {
      id: 'Attribute.step_unvisited',
    },
    step_visited: {
      id: 'Attribute.step_visited',
    },
    success: {
      id: 'Attribute.success',
    },
    Tags: {
      id: 'Attribute.Tags',
    },
    Tag_types: {
      id: 'Attribute.Tag_types',
    },
    test: {
      id: 'Attribute.test',
    },
    Text: {
      id: 'Attribute.Text',
    },
    Timestamps: {
      id: 'Attribute.Timestamps',
    },
    TimestampsCreatedUpdated: {
      id: 'Attribute.TimestampsCreatedUpdated',
    },
    Quarter: {
      id: 'Attribute.Quarter',
    },
    Score: {
      id: 'Attribute.Score',
    },
    Skip: {
      id: 'Attribute.Skip',
    },
    Start_date: {
      id: 'Attribute.Start_date',
    },
    Street: {
      id: 'Attribute.Street',
    },
    Address_line_2: {
      id: 'Attribute.Address_line_2',
    },
    Time_span: {
      id: 'Attribute.Time_span',
    },
    Today: {
      id: 'Attribute.Today',
    },
    warning: {
      id: 'Attribute.warning',
    },
    Day3: {
      id: 'Attribute.3Day',
    },
    Week: {
      id: 'Attribute.Week',
    },
    Work_Week: {
      id: 'Attribute.Work_Week',
    },
    Year: {
      id: 'Attribute.Year',
    },
    Years: {
      id: 'Attribute.Years',
    },
    All_Time: {
      id: 'Attribute.All_Time',
    },
    Title: {
      id: 'Attribute.Title',
    },
    Type: {
      id: 'Attribute.Type',
    },
    Private_address: {
      id: 'Attribute.Private_address',
    },
    Untitled: {
      id: 'Attribute.Untitled',
    },
    Updated: {
      id: 'Attribute.Updated',
    },
    Validated: {
      id: 'Attribute.Validated',
    },
    User_name: {
      id: 'Attribute.User_name',
    },
    Visit_city: {
      id: 'Attribute.Visit_city',
    },
    Commune: {
      id: 'Attribute.Commune',
    },
    Visit_commune: {
      id: 'Attribute.Visit_commune',
    },
    Visit_street: {
      id: 'Attribute.Visit_street',
    },
    Visit_address_line_2: {
      id: 'Attribute.Visit_address_line_2',
    },
    Visit_zip: {
      id: 'Attribute.Visit_zip',
    },
    Postal_code: {
      id: 'Attribute.Postal_code',
    },
    Web_page: {
      id: 'Attribute.Web_page',
    },
    Workplace: {
      id: 'Attribute.Workplace',
    },
    Working_specialty: {
      id: 'Attribute.Working_specialty',
    },
    Fields: {
      id: 'Attribute.Fields',
    },
  },
  Command: {
    Click_to_sort_ascending: {
      id: 'Command.Click_to_sort_ascending'
    },
    Click_to_sort_descending: {
      id: 'Command.Click_to_sort_descending'
    },
    Click_to_cancel_sorting: {
      id: 'Command.Click_to_cancel_sorting'
    },
    Show_affiliations: {
      id: 'Command.Show_affiliations'
    },
    Include: {
      id: 'Command.Include'
    },
    Exclude: {
      id: 'Command.Exclude'
    },
    Count: {
      id: 'Command.Count'
    },
    User_input: {
      id: 'Command.User_input'
    },
    Custom_input: {
      id: 'Command.Custom_input'
    },
    Intersection: {
      id: 'Command.Intersection'
    },
    Union: {
      id: 'Command.Union'
    },
    Allow_no_value: {
      id: 'Command.Allow_no_value'
    },
    Allow_selecting_multiple: {
      id: 'Command.Allow_selecting_multiple'
    },
    Auto_generate_name: {
      id: 'Command.Auto_generate_name'
    },
    No_predefined: {
      id: 'Command.No_predefined'
    },
    Enter_Number_Of_Beds: {
      id: 'Command.Enter_Number_Of_Beds'
    },
    Enter_aPureBase_IDs: {
      id: 'Command.Enter_aPureBase_IDs'
    },
    Enter_number_of_chairs: {
      id: 'Command.Enter_number_of_chairs'
    },
    Select_Site_Sector_Type: {
      id: 'Command.Select_Site_Sector_Type'
    },
    Select_Value_Chain: {
      id: 'Command.Select_Value_Chain'
    },
    Show_all_selection_types: {
      id: 'Command.Show_all_selection_types',
    },
    Only_show_dynamic_selection_types: {
      id: 'Command.Only_show_dynamic_selection_types',
    },
    Select_existing: {
      id: 'Command.Select_existing',
    },
    Include_opted_out: {
      id: 'Command.Include_opted_out',
    },
    Select_therapeutic_focus: {
      id: 'Command.Select_therapeutic_focus',
    },
    Select_customer: {
      id: 'Command.Select_customer',
    },
    Load_more: {
      id: 'Command.Load_more',
    },
    Activate_record: {
      id: 'Command.Activate_record',
    },
    Inactivate_record: {
      id: 'Command.Inactivate_record',
    },
    Merge_record: {
      id: 'Command.Merge_record',
    },
    Merge_records: {
      id: 'Command.Merge_records',
    },
    Choose: {
      id: 'Command.Choose',
    },
    Fix: {
      id: 'Command.Fix',
    },
    Set_as_non_primary: {
      id: 'Command.Set_as_non_primary',
    },
    Set_as_primary: {
      id: 'Command.Set_as_primary',
    },
    Update_address_information: {
      id: 'Command.Update_address_information',
    },
    Enter_ID: {
      id: 'Command.Enter_ID',
    },
    Select_other_date: {
      id: 'Command.Select_other_date',
    },
    Validate_link: {
      id: 'Command.Validate_link',
    },
    Select_country_and_year: {
      id: 'Command.Select_country_and_year',
    },
    Back_to_search: {
      id: 'Command.Back_to_search',
    },
    Reset_to_system_defaults: {
      id: 'Command.Reset_to_system_defaults',
    },
    Impersonate: {
      id: 'Command.Impersonate',
    },
    Configure_table_columns: {
      id: 'Command.Configure_table_columns',
    },
    Configure_table_layout: {
      id: 'Command.Configure_table_layout'
    },
    Add_new_column: {
      id: 'Command.Add_new_column',
    },
    Please_select_column: {
      id: 'Command.Please_select_column',
    },
    Add_country: {
      id: 'Command.Add_country',
    },
    Perform_automatic_merges_without_conflicts: {
      id: 'Command.Perform_automatic_merges_without_conflicts',
    },
    Add_activity_type: {
      id: 'Command.Add_activity_type',
    },
    Select_report: {
      id: 'Command.Select_report',
    },
    Validate: {
      id: 'Command.Validate',
    },
    Select_cycle: {
      id: 'Command.Select_cycle',
    },
    Amount_cycles_pr_year: {
      id: 'Command.Amount_cycles_pr_year',
    },
    Reset_sorting: {
      id: 'Command.Reset_sorting',
    },
    Expand_Search: {
      id: 'Command.Expand_Search',
    },
    Assign_field_to_a_group: {
      id: 'Command.Assign_field_to_a_group',
    },
    Add_new_value: {
      id: 'Command.Add_new_value',
    },
    Open_DCR: {
      id: 'Command.Open_DCR',
    },
    Enter_position_information: {
      id: 'Command.Enter_position_information',
    },
    Update_Contact_Information: {
      id: 'Command.Update_Contact_Information',
    },
    New_Type: {
      id: 'Command.New_Type',
    },
    Retry: {
      id: 'Command.Retry',
    },
    Open_project_details: {
      id: 'Command.Open_project_details',
    },
    Enter_address_information: {
      id: 'Command.Enter_address_information',
    },
    Yes_remove: {
      id: 'Command.Yes_remove',
    },
    Add: {
      id: 'Command.Add',
    },
    Add_option: {
      id: 'Command.Add_option',
    },
    Add_field: {
      id: 'Command.Add_field',
    },
    Add_brand: {
      id: 'Command.Add_Brand',
    },
    Add_consent: {
      id: 'Command.Add_consent',
    },
    Add_Criteria: {
      id: 'Command.Add_Criteria',
    },
    Add_input: {
      id: 'Command.Add_input',
    },
    Add_new_Attendees: {
      id: 'Command.Add_new_Attendees',
    },
    Add_Note: {
      id: 'Command.Add_Note',
    },
    Add_Person: {
      id: 'Command.Add_Person',
    },
    Add_Persons: {
      id: 'Command.Add_Persons',
    },
    Add_Product: {
      id: 'Command.Add_Product',
    },
    Add_to_list: {
      id: 'Command.Add_to_list',
    },
    Add_to_new_static_list: {
      id: 'Command.Add_to_new_static_list',
    },
    Add_to_search: {
      id: 'Command.Add_to_search',
    },
    Add_to_static_list: {
      id: 'Command.Add_to_static_list',
    },
    Add_tag: {
      id: 'Command.Add_tag',
    },
    Add_type: {
      id: 'Command.Add_type',
    },
    Allow: {
      id: 'Command.Allow',
    },
    Archive_selected_lists: {
      id: 'Command.Archive_selected_lists',
    },
    Back: {
      id: 'Command.Back',
    },
    Cancel: {
      id: 'Command.Cancel',
    },
    Cancelled: {
      id: 'Command.Cancelled',
    },
    Change: {
      id: 'Command.Change',
    },
    Clear: {
      id: 'Command.Clear',
    },
    Clear_search: {
      id: 'Command.Clear_search',
    },
    Close: {
      id: 'Command.Close',
    },
    Close_Menu: {
      id: 'Command.Close_Menu',
    },
    Complete: {
      id: 'Command.Complete',
    },
    Complete_Activity: {
      id: 'Command.Complete_Activity',
    },
    Completed: {
      id: 'Command.Completed',
    },
    Confirm: {
      id: 'Command.Confirm',
    },
    Continue: {
      id: 'Command.Continue',
    },
    Create: {
      id: 'Command.Create',
    },
    Create_Activity: {
      id: 'Command.Create_Activity',
    },
    Create_DCR: {
      id: 'Command.Create_DCR',
    },
    Create_Person: {
      id: 'Command.Create_Person',
    },
    Create_Site: {
      id: 'Command.Create_Site',
    },
    Create_new_list: {
      id: 'Command.Create_new_list',
    },
    Create_new_password: {
      id: 'Command.Create_new_password',
    },
    Create_Note: {
      id: 'Command.Create_Note',
    },
    Create_Project: {
      id: 'Command.Create_Project',
    },
    Create_Static_List: {
      id: 'Command.Create_Static_List',
    },
    Create_Dynamic_List: {
      id: 'Command.Create_Dynamic_List',
    },
    Delete: {
      id: 'Command.Delete',
    },
    Delete_Activity: {
      id: 'Command.Delete_Activity',
    },
    Date: {
      id: 'Command.Date'
    },
    Edit: {
      id: 'Command.Edit',
    },
    Edit_Record: {
      id: 'Command.Edit_Record',
    },
    End: {
      id: 'Command.End',
    },
    Enter_First_Name: {
      id: 'Command.Enter_First_Name',
    },
    Enter_Official_ID: {
      id: 'Command.Enter_Official_ID',
    },
    Enter_heading: {
      id: 'Command.Enter_heading',
    },
    Enter_Name: {
      id: 'Command.Enter_name',
    },
    Enable_Global_search: {
      id: 'Command.Enable_Global_search',
    },
    Enter_address: {
      id: 'Command.Enter_address',
    },
    Enter_city: {
      id: 'Command.Enter_city',
    },
    Enter_Commune: {
      id: 'Command.Enter_Commune',
    },
    Enter_description: {
      id: 'Command.Enter_description',
    },
    Enter_Education_Specialty: {
      id: 'Command.Enter_Education_Specialty',
    },
    Enter_email: {
      id: 'Command.Enter_email',
    },
    Enter_Fax: {
      id: 'Command.Enter_Fax',
    },
    Enter_Last_Name: {
      id: 'Command.Enter_Last_Name',
    },
    Enter_Middle_Name: {
      id: 'Command.Enter_Middle_Name',
    },
    Enter_Latitude: {
      id: 'Command.Enter_Latitude',
    },
    Enter_Longitude: {
      id: 'Command.Enter_Longitude',
    },
    Enter_Notes: {
      id: 'Command.Enter_Notes',
    },
    Enter_phone: {
      id: 'Command.Enter_phone',
    },
    Enter_site_name: {
      id: 'Command.Enter_site_name',
    },
    Enter_Site_Type: {
      id: 'Command.Enter_Site_Type',
    },
    Enter_Street: {
      id: 'Command.Enter_Street',
    },
    Enter_title: {
      id: 'Command.Enter_title',
    },
    Enter_website: {
      id: 'Command.Enter_website',
    },
    Enter_postal_code: {
      id: 'Command.Enter_postal_code',
    },
    Enter_Department: {
      id: 'Command.Enter_department',
    },
    Export: {
      id: 'Command.Export',
    },
    File_preview: {
      id: 'Command.File_preview',
    },
    File_upload: {
      id: 'Command.File_upload',
    },
    Generate: {
      id: 'Command.Generate',
    },
    Generate_Report: {
      id: 'Command.Generate_Report',
    },
    Global_search: {
      id: 'Command.Global_search',
    },
    Include_inactive: {
      id: 'Command.Include_inactive',
    },
    Only_active: {
      id: 'Command.Only_active',
    },
    Include_unplaced: {
      id: 'Command.Include_unplaced',
    },
    Import: {
      id: 'Command.Import',
    },
    Import_steps: {
      id: 'Command.Import_steps',
    },
    Input: {
      id: 'Command.Input',
    },
    Input_for: {
      id: 'Command.Input_for',
    },
    Login: {
      id: 'Command.Login',
    },
    Logout: {
      id: 'Command.Logout',
    },
    Manual_search: {
      id: 'Command.Manual_search',
    },
    New: {
      id: 'Command.New',
    },
    New_Item: {
      id: 'Command.New_Item',
    },
    Next: {
      id: 'Command.Next',
    },
    No_data: {
      id: 'Command.No_data',
    },
    Password_reset: {
      id: 'Command.Password_reset',
    },
    Password_confirm: {
      id: 'Command.Password_confirm',
    },
    Preferences: {
      id: 'Command.Preferences',
    },
    Preview: {
      id: 'Command.Preview',
    },
    Processing: {
      id: 'Command.Processing',
    },
    Recover: {
      id: 'Command.Recover',
    },
    Remember_me: {
      id: 'Command.Remember_me',
    },
    Remove: {
      id: 'Command.Remove',
    },
    Remove_Persons: {
      id: 'Command.Remove_Persons',
    },
    Remove_selected_activities: {
      id: 'Command.Remove_selected_activities',
    },
    Remove_selected_attendees: {
      id: 'Command.Remove_selected_attendees',
    },
    Reset: {
      id: 'Command.Reset',
    },
    Save: {
      id: 'Command.Save',
    },
    Save_as_default: {
      id: 'Command.Save_as_default'
    },
    Save_as_dynamic_list: {
      id: 'Command.Save_as_dynamic_list',
    },
    Save_as_new_search: {
      id: 'Command.Save_as_new_search',
    },
    Save_changes: {
      id: 'Command.Save_changes',
    },
    Save_Match: {
      id: 'Command.Save_Match',
    },
    Save_search: {
      id: 'Command.Save_search',
    },
    Search_activities: {
      id: 'Command.Search_activities',
    },
    Search: {
      id: 'Command.Search',
    },
    Search_criteria_name: {
      id: 'Command.Search_criteria_name',
    },
    Select: {
      id: 'Command.Select',
    },
    Select_Activity: {
      id: 'Command.Select_Activity',
    },
    Select_brick: {
      id: 'Command.Select_brick',
    },
    Select_country: {
      id: 'Command.Select_country',
    },
    Select_county: {
      id: 'Command.Select_county',
    },
    Select_Education: {
      id: 'Command.Select_Education',
    },
    Select_Entity_Type: {
      id: 'Command.Select_Entity_Type',
    },
    Select_External_System: {
      id: 'Command.Select_External_System',
    },
    Select_Market_Access: {
      id: 'Command.Select_Market_Access',
    },
    Select_date: {
      id: 'Command.Select_date',
    },
    Select_month: {
      id: 'Command.Select_month',
    },
    Select_users: {
      id: 'Command.Select_users',
    },
    Select_DB_Source: {
      id: 'Command.Select_DB_Source',
    },
    Select_Start_Date: {
      id: 'Command.Select_start_date',
    },
    Select_End_Date: {
      id: 'Command.Select_end_date',
    },
    Select_Role: {
      id: 'Command.Select_Role',
    },
    Select_gender: {
      id: 'Command.Select_gender',
    },
    Select_Working_Specialty: {
      id: 'Command.Select_Working_Specialty',
    },
    Select_Educational_Specialty: {
      id: 'Command.Select_Educational_Specialty',
    },
    Select_Site_Specialty: {
      id: 'Command.Select_Site_Specialty',
    },
    Select_A_Site: {
      id: 'Command.Select_A_Site',
    },
    Select_Site_As_Parent: {
      id: 'Command.Select_Site_As_Parent',
    },
    Clear_Parent_Site: {
      id: 'Command.Clear_Parent_Site',
    },
    Select_HCP: {
      id: 'Command.Select_HCP',
    },
    Unselect_HCP: {
      id: 'Command.Unselect_HCP',
    },
    Select_HCO: {
      id: 'Command.Select_HCO',
    },
    Unselect_HCO: {
      id: 'Command.Unselect_HCO',
    },
    Select_language: {
      id: 'Command.Select_language',
    },
    Select_location: {
      id: 'Command.Select_location',
    },
    Select_list: {
      id: 'Command.Select_List',
    },
    Select_list_format: {
      id: 'Command.Select_list_format',
    },
    Select_List_Type: {
      id: 'Command.Select_List_Type',
    },
    Select_person: {
      id: 'Command.Select_person',
    },
    Select_person_type: {
      id: 'Command.Select_person_type',
    },
    Select_position: {
      id: 'Command.Select_position',
    },
    Select_Product: {
      id: 'Command.Select_Product',
    },
    Select_Product_Brand: {
      id: 'Command.Select_Product_Brand',
    },
    Select_Project_Title: {
      id: 'Command.Select_Project_Title',
    },
    Select_user: {
      id: 'Command.Select_user',
    },
    Select_user_types: {
      id: 'Command.Select_user_types'
    },
    Select_responsible_User: {
      id: 'Command.Select_responsible_User'
    },
    Select_segment_code: {
      id: 'Command.Select_segment_code',
    },
    Select_site_sector_code: {
      id: 'Command.Select_site_sector_code',
    },
    Select_ownership_type: {
      id: 'Command.Select_ownership_type',
    },
    Select_Site: {
      id: 'Command.Select_Site',
    },
    Select_Site_Type: {
      id: 'Command.Select_Site_Type',
    },
    Select_specialty: {
      id: 'Command.Select_specialty',
    },
    Select_Static_List: {
      id: 'Command.Select_Static_List',
    },
    Select_template: {
      id: 'Command.Select_template',
    },
    Select_Tag_Type: {
      id: 'Command.Select_Tag_Type',
    },
    Select_type: {
      id: 'Command.Select_type',
    },
    Select_Year: {
      id: 'Command.Select_Year',
    },
    Send_DCR: {
      id: 'Command.Send_',
    },
    Show: {
      id: 'Command.Show',
    },
    Show_all: {
      id: 'Command.Show_all',
    },
    Sort_Favorite_Lists: {
      id: 'Command.Sort_Favorite_Lists',
    },
    Start: {
      id: 'Command.Start',
    },
    Start_Processing: {
      id: 'Command.Start_Processing',
    },
    Submit: {
      id: 'Command.Submit',
    },
    Submit_DCR: {
      id: 'Command.Submit_DCR',
    },
    Undo: {
      id: 'Command.Undo',
    },
    Upload: {
      id: 'Command.Upload',
    },
    Update: {
      id: 'Command.Update',
    },
    Add_workplace: {
      id: 'Command.Add_workplace',
    },
    Remove_workplace: {
      id: 'Command.Remove_workplace',
    },
    Remove_site: {
      id: 'Command.Remove_site',
    },
    Select_Chain: {
      id: 'Command.Select_Chain',
    },
    Only_cherry_picked: {
      id: 'Command.Only_cherry_picked',
    }
  },
  Text: {
    Search_saved_as_default: {
      id: 'Text.Search_saved_as_default'
    },
    Build_advance_search_builder: {
      id: 'Text.Build_advance_search_builder'
    },
    Advance_search_description: {
      id: 'Text.Advance_search_description'
    },
    Merge_request_person_link_primary_description: {
      id: 'Text.Merge_request_person_link_primary_description',
    },
    Merge_request_link_working_specialities_mismatch_description: {
      id: 'Text.Merge_request_link_working_specialities_mismatch_description',
    },
    Merge_request_site_mismatch_links_link_merged_popover_description: {
      id: 'Text.Merge_request_site_mismatch_links_link_merged_popover_description',
    },
    Merge_request_site_mismatch_links_link_merge_popover_description: {
      id: 'Text.Merge_request_site_mismatch_links_link_merge_popover_description',
    },
    Merge_request_site_links_mismatch_result_description: {
      id: 'Text.Merge_request_site_links_mismatch_result_description',
    },
    Merge_request_site_links_mismatch_description: {
      id: 'Text.Merge_request_site_links_mismatch_description',
    },
    Merge_request_address_transfer_mismatch_description: {
      id: 'Text.Merge_request_address_transfer_mismatch_description',
    },
    Merge_request_contact_value_transfer_mismatch_description: {
      id: 'Text.Merge_request_contact_value_transfer_mismatch_description',
    },
    Merge_request_webpage_mismatch_description: {
      id: 'Text.Merge_request_webpage_mismatch_description',
    },
    Generate_apurebase_id_right_away_description: {
      id: 'Text.Generate_apurebase_id_right_away_description',
    },
    Merge_successful_message: {
      id: 'Text.Merge_successful_message',
    },
    Merge_successful: {
      id: 'Text.Merge_successful',
    },
    Merge_request_person_link_description: {
      id: 'Text.Merge_request_person_link_description',
    },
    This_is_current_primary_link: {
      id: 'Text.This_is_current_primary_link',
    },
    Merge_request_link_primary_mismatch_description: {
      id: 'Text.Merge_request_link_primary_mismatch_description',
    },
    Merge_request_link_position_mismatch_description: {
      id: 'Text.Merge_request_link_position_mismatch_description',
    },
    Merge_request_link_position_description: {
      id: 'Text.Merge_request_link_position_description',
    },
    Select_duration: {
      id: 'Text.Select_duration'
    },
    Within_the_last: {
      id: 'Text.Within_the_last'
    },
    More_than: {
      id: 'Text.More_than'
    },
    Allow_duplicate_values: {
      id: 'Text.Allow_duplicate_values'
    },
    Duplicate_Rule_across_countries: {
      id: 'Text.Duplicate_Rule_across_countries'
    },
    Excluded_countries: {
      id: 'Text.Excluded_countries'
    },
    Merge_request_mailing_name_prefix_mismatch_description: {
      id: 'Text.Merge_request_mailing_name_prefix_mismatch_description',
    },
    Merge_request_is_unplaced_mismatch_description: {
      id: 'Text.Merge_request_is_unplaced_mismatch_description',
    },
    Merge_request_segmentation_code_mismatch_description: {
      id: 'Text.Merge_request_segmentation_code_mismatch_description',
    },
    Merge_request_name_mismatch_description: {
      id: 'Text.Merge_request_name_mismatch_description',
    },
    Merge_request_site_parent_mismatch_description: {
      id: 'Text.Merge_request_site_parent_mismatch_description',
    },
    Merge_request_site_type_mismatch_description: {
      id: 'Text.Merge_request_site_type_mismatch_description'
    },
    Merge_request_person_therapeutic_focus_description: {
      id: 'Text.Merge_request_person_therapeutic_focus_description',
    },
    External_id_already_exists: {
      id: 'Text.External_id_already_exist'
    },
    Merge_request_mail_to_private_mismatch_description: {
      id: 'Text.Merge_request_mail_to_private_mismatch_description',
    },
    Exact_official_id_match_description: {
      id: 'Text.Exact_official_id_match_description',
    },
    Official_ID_match: {
      id: 'Text.Official_ID_match',
    },
    ONE_primary_link_must_point_to_one_active_site: {
      id: 'Text.ONE_primary_link_must_point_to_one_active_site'
    },
    Warning_person_opted_out_from_apb: {
      id: 'Text.Warning_person_opted_out_from_apb'
    },
    Merge_request_educations_description: {
      id: 'Text.Merge_request_educations_description',
    },
    Merge_request_person_types_description: {
      id: 'Text.Merge_request_person_types_description',
    },
    Merge_request_person_type_mismatch_description: {
      id: 'Text.Merge_request_person_type_mismatch_description',
    },
    Merge_request_birth_mismatch_description: {
      id: 'Text.Merge_request_birth_mismatch_description',
    },
    Merge_request_person_tags_mismatch_description: {
      id: 'Text.Merge_request_person_tags_mismatch_description',
    },
    Merge_request_person_tags_description: {
      id: 'Text.Merge_request_person_tags_description',
    },
    Merge_request_person_segmentation_code_conflict_mismatch_description: {
      id: 'Text.Merge_request_person_segmentation_code_conflict_mismatch_description',
    },
    Merge_GDPR_set_to_true_description: {
      id: 'Text.Merge_GDPR_set_to_true_description',
    },
    Merge_responsible_area_description: {
      id: 'Text.Merge_responsible_area_description',
    },
    Save_favorite_search: {
      id: 'Text.Save_favorite_search'
    },
    Drag_drop_placeholder_simple_search: {
      id: 'Text.Drag_drop_placeholder_simple_search'
    },
    Opted_out_for_all_clients: {
      id: 'Text.Opted_out_for_all_clients',
    },
    Merge_request_person_inactivated_description: {
      id: 'Text.Merge_request_person_inactivated_description',
    },
    Merge_request_person_activated_description: {
      id: 'Text.Merge_request_person_activated_description',
    },
    Merge_request_person_product_segmentation_code_description: {
      id: 'Text.Merge_request_person_product_segmentation_code_description',
    },
    Merge_request_person_product_segmentation_code_conflict_mismatch_description: {
      id: 'Text.Merge_request_person_product_segmentation_code_conflict_mismatch_description',
    },
    Select_customer_for_cherry_picking_description: {
      id: 'Text.Select_customer_for_cherry_picking_description',
    },
    Cherry_pick_for_customer_description: {
      id: 'Text.Cherry_pick_for_customer_description',
    },
    External_ID_Type_permission_control_description: {
      id: 'Text.External_ID_Type_permission_control_description',
    },
    Successfully_activated_record: {
      id: 'Text.Successfully_activated_record',
    },
    Successfully_inactivated_record: {
      id: 'Text.Successfully_inactivated_record',
    },
    Inactivate_with_links_description: {
      id: 'Text.Inactivate_with_links_description',
    },
    Merge_request_notes_move_description: {
      id: 'Text.Merge_request_notes_move_description',
    },
    Merge_request_lists_move_description: {
      id: 'Text.Merge_request_lists_move_description',
    },
    Merge_request_lists_move_modal: {
      id: 'Text.Merge_request_lists_move_modal',
    },
    Merge_request_external_id_transfer_description: {
      id: 'Text.Merge_request_external_id_transfer_description',
    },
    Merge_request_external_id_mismatch_description: {
      id: 'Text.Merge_request_external_id_mismatch_description',
    },
    Merge_request_custom_field_conflict_modal_description: {
      id: 'Text.Merge_request_custom_field_conflict_modal_description',
    },
    Merge_request_contact_value_transfer_description: {
      id: 'Text.Merge_request_contact_value_transfer_description',
    },
    Merge_request_address_transfer_description: {
      id: 'Text.Merge_request_address_transfer_description',
    },
    Merge_request_consent_value_transfer_description: {
      id: 'Text.Merge_request_consent_value_transfer_description',
    },
    Merge_request_consent_value_mismatch_description: {
      id: 'Text.Merge_request_consent_value_mismatch_description',
    },
    Merge_request_missing_handling_conflicts_message: {
      id: 'Text.Merge_request_missing_handling_conflicts_message',
    },
    Merge_request_activities_move_modal: {
      id: 'Text.Merge_request_activities_move_modal',
    },
    Merge_request_activities_move_description: {
      id: 'Text.Merge_request_activities_move_description',
    },
    Data_transfer: {
      id: 'Text.Data_transfer',
    },
    Merge_request_responsible_areas_mismatch_description: {
      id: 'Text.Merge_request_responsible_areas_mismatch_description',
    },
    Merge_request_official_id_mismatch_description: {
      id: 'Text.Merge_request_official_id_mismatch_description',
    },
    Merge_request_exam_years_mismatch_description: {
      id: 'Text.Merge_request_exam_years_mismatch_description',
    },
    Merge_request_countries_mismatch_description: {
      id: 'Text.Merge_request_countries_mismatch_description',
    },
    Merge_request_gender_mismatch_description: {
      id: 'Text.Merge_request_gender_mismatch_description',
    },
    Merge_request_title_mismatch_description: {
      id: 'Text.Merge_request_title_mismatch_description',
    },
    Will_be_value: {
      id: 'Text.Will_be_value',
    },
    Data_conflict: {
      id: 'Text.Data_conflict',
    },
    Merge_request_first_names_mismatch_description: {
      id: 'Text.Merge_request_first_names_mismatch_description',
    },
    Merge_request_last_names_mismatch_description: {
      id: 'Text.Merge_request_last_names_mismatch_description',
    },
    Merge_request_conflict_write_in_value: {
      id: 'Text.Merge_request_conflict_write_in_value',
    },
    No_external_id_types_warning: {
      id: 'Text.No_external_id_types_warning',
    },
    Do_you_want_to_submit_dcr: {
      id: 'Text.Do_you_want_to_submit_dcr',
    },
    Remove_primary_dcr_validation: {
      id: 'Text.Remove_primary_dcr_validation',
    },
    Set_as_primary_dcr_validation: {
      id: 'Text.Set_as_primary_dcr_validation',
    },
    Remove_workplace_dcr_validation: {
      id: 'Text.Remove_workplace_dcr_validation',
    },
    Has_standard_title: {
      id: 'Text.Has_standard_title',
    },
    Code_already_in_use: {
      id: 'Text.Code_already_in_use',
    },
    Spaces_not_allowed_in_code: {
      id: 'Text.Spaces_not_allowed_in_code',
    },
    Configure_field_days_description: {
      id: 'Text.Configure_field_days_description',
    },
    Add_criteria_to_default: {
      id: 'Text.Add_criteria_to_default',
    },
    Configure_default_criterias_description: {
      id: 'Text.Configure_default_criterias_description',
    },
    Person_not_found: {
      id: 'Text.Person_not_found',
    },
    Could_not_find_person_by_id: {
      id: 'Text.Could_not_find_person_by_id',
    },
    Entity_grid_layout_right_description_none_selected: {
      id: 'Text.Entity_grid_layout_right_description_none_selected',
    },
    No_fields_added_to_grid_configuration: {
      id: 'Text.No_fields_added_to_grid_configuration',
    },
    You_are_using_the_system_defaults: {
      id: 'Text.You_are_using_the_system_defaults',
    },
    Select_user_shared_calendar_tooltip: {
      id: 'Text.Select_user_shared_calendar_tooltip',
    },
    You_are_impersonating_user: {
      id: 'Text.You_are_impersonating_user',
    },
    Back_to_my_user: {
      id: 'Text.Back_to_my_user',
    },
    Configure_user_table_columns_description: {
      id: 'Text.Configure_user_table_columns_description',
    },
    Configure_default_table_columns_description: {
      id: 'Text.Configure_default_table_columns_description',
    },
    Cycle_create_new: {
      id: 'Text.Cycle_create_new',
    },
    Call_frequency_report_daily_target_configuration: {
      id: 'Text.Call_frequency_report_daily_target_configuration',
    },
    Half_day_based: {
      id: 'Text.Half_day_based',
    },
    Call_frequency_report_half_day_description: {
      id: 'Text.Call_frequency_report_half_day_description',
    },
    Report_based_on_field_days_configuration: {
      id: 'Text.Report_based_on_field_days_configuration',
    },
    KPI_long_description: {
      id: 'Text.KPI_long_description',
    },
    Mutation_permission_denied: {
      id: 'Text.Mutation_permission_denied',
    },
    DCR_request_type_info: {
      id: 'Text.DCR_request_type_info',
    },
    Record_has_merge_request: {
      id: 'Text.Record_has_merge_request',
    },
    Activity_types_in_reports_description: {
      id: 'Text.Activity_types_in_reports_description',
    },
    Error_enter_number_between: {
      id: 'Text.Error_enter_number_between',
    },
    No_of_calls_cycle_per_hcp_segment_group: {
      id: 'Text.No_of_calls_cycle_per_hcp_segment_group',
    },
    No_of_targeting_cycle_in_year: {
      id: 'Text.No_of_targeting_cycle_in_year',
    },
    Field_Permission_Access_Denied: {
      id: 'Text.Field_Permission_Access_Denied',
    },
    Activity_add_persons_max_exceeded: {
      id: 'Text.Activity_add_persons_max_exceeded',
    },
    Activity_add_site_hcp_no_result: {
      id: 'Text.Activity_add_site_hcp_no_result',
    },
    Activity_add_site_to_activity: {
      id: 'Text.Activity_add_site_to_activity',
    },
    Activity_End_Start_Date: {
      id: 'Text.Activity_End_Start_Date'
    },
    Activity_add_site_to_activity_placeholder: {
      id: 'Text.Activity_add_site_to_activity_placeholder',
    },
    Activity_add_site_hcp_results: {
      id: 'Text.Activity_add_site_hcp_results',
    },
    Global_additional_count_info_tooltip: {
      id: 'Text.Global_additional_count_info_tooltip',
    },
    This_criteria_not_supported_in_global_search: {
      id: 'Text.This_criteria_not_supported_in_global_search',
    },
    Contact_has_consent: {
      id: 'Text.Contact_has_consent',
    },
    Add_selected_field_to_group: {
      id: 'Text.Add_selected_field_to_group',
    },
    Local_criterias_added_to_global_search: {
      id: 'Text.Local_criterias_added_to_global_search',
    },
    Did_you_know: {
      id: 'Text.Did_you_know',
    },
    Add_suggested_field_to_layout_description: {
      id: 'Text.Add_suggested_field_to_layout_description',
    },
    Previously_created_fields: {
      id: 'Text.Previously_created_fields',
    },
    aPureBase_Suggested_fields: {
      id: 'Text.aPureBase_Suggested_fields',
    },
    English_lov_as_default_tooltip: {
      id: 'Text.English_lov_as_default_tooltip',
    },
    Show_English_by_default: {
      id: 'Text.Show_English_by_default',
    },
    Dcr_calculated_heading_new_workplace: {
      id: 'Text.Dcr_calculated_heading_new_workplace',
    },
    Dcr_calculated_heading_update_workplace: {
      id: 'Text.Dcr_calculated_heading_update_workplace',
    },
    Dcr_calculated_heading_remove_workplace: {
      id: 'Text.Dcr_calculated_heading_remove_workplace',
    },
    Dcr_calculated_heading_create_person: {
      id: 'Text.Dcr_calculated_heading_create_person',
    },
    Dcr_calculated_heading_update_person: {
      id: 'Text.Dcr_calculated_heading_update_person',
    },
    Dcr_calculated_heading_remove_person: {
      id: 'Text.Dcr_calculated_heading_remove_person',
    },
    Dcr_calculated_heading_add_site: {
      id: 'Text.Dcr_calculated_heading_add_site',
    },
    Dcr_calculated_heading_update_site: {
      id: 'Text.Dcr_calculated_heading_update_site',
    },
    Dcr_calculated_heading_remove_site: {
      id: 'Text.Dcr_calculated_heading_remove_site',
    },
    Dcr_calculated_heading_unknown_type: {
      id: 'Text.Dcr_calculated_heading_unknown_type',
    },
    Custom_field_permission_control_description: {
      id: 'Text.Custom_field_permission_control_description',
    },
    Record_is_inactive: {
      id: 'Text.Record_is_inactive',
    },
    Value_being_maintained_by: {
      id: 'Text.Value_being_maintained_by',
    },
    Confirm_remove_contact_entry: {
      id: 'Text.Confirm_remove_contact_entry',
    },
    Successfully_added_person_to_site: {
      id: 'Text.Successfully_added_person_to_site',
    },
    Group_successfully_removed: {
      id: 'Text.Group_successfully_removed',
    },
    Group_grid_item_successfully_removed: {
      id: 'Text.Group_grid_item_successfully_removed',
    },
    Group_successfully_created: {
      id: 'Text.Group_successfully_created',
    },
    Pending_reject: {
      id: 'Text.Pending_reject',
    },
    Pending_DCR: {
      id: 'Text.Pending_DCR',
    },
    Create_dcr_verification: {
      id: 'Text.Create_dcr_verification',
    },
    Consent_email_checkbox_tooltip: {
      id: 'Text.Consent_email_checkbox_tooltip',
    },
    Reset_sandbox_database: {
      id: 'Text.Reset_sandbox_database',
    },
    Cannot_establish_fuzzy_search_connection: {
      id: 'Text.Cannot_establish_fuzzy_search_connection',
    },
    You_can_retry_in_amount_of_time: {
      id: 'Text.You_can_retry_in_amount_of_time',
    },
    Press_to_enter_address_information: {
      id: 'Text.Press_to_enter_address_information',
    },
    You_need_atleast_one_country_select: {
      id: 'Text.You_need_atleast_one_country_select',
    },
    Select_countries_to_search_in: {
      id: 'Text.Select_countries_to_search_in',
    },
    Select_Country: {
      id: 'Text.Select_Country',
    },
    Unknown_flag: {
      id: 'Text.Unknown_flag',
    },
    Are_you_sure_to_remove_option: {
      id: 'Text.Are_you_sure_to_remove_option',
    },
    You_need_to_provide_options: {
      id: 'Text.You_need_to_provide_options',
    },
    Options_cant_be_blank: {
      id: 'Text.Options_cant_be_blank',
    },
    characters_8_15: {
      id: 'Text.characters_8_15',
    },
    HCP_Person_Types: {
      id: 'Text.HCP_Person_Types',
    },
    Create_new_custom_field: {
      id: 'Text.Create_new_custom_field',
    },
    Update_custom_field: {
      id: 'Text.Update_custom_field',
    },
    HCP_Assigned_User: {
      id: 'Text.HCP_Assigned_User',
    },
    Dashboard_information_configuration_tooltip: {
      id: 'Text.dashboard.information.configuration.tooltip',
    },
    Dashboard_configure_dashboards: {
      id: 'Text.configure.dashboards',
    },
    Dashboard_item_needs_configuration: {
      id: 'Text.Dashboard_item_needs_configuration',
    },
    Configure_Dashboard_Chart: {
      id: 'Text.Configure_Dashboard_Chart',
    },
    Delete_Dashboard_Are_You_Sure: {
      id: 'Text.Delete_Dashboard_Are_You_Sure',
    },
    Create_Dashboard_New_Dashboard: {
      id: 'Text.Create_Dashboard_New_Dashboard',
    },
    Enter_First_Name: {
      id: 'Text.enter_first_name',
    },
    Enter_Last_Name: {
      id: 'Text.enter_last_name',
    },
    Enter_Location: {
      id: 'Text.Enter_Location',
    },
    Enter_Title: {
      id: 'Text.enter_title_name',
    },
    Name_required_for_new_saved_search: {
      id: 'Text.Name_required_for_new_saved_search',
    },
    Name_required: {
      id: 'Text.Name_required',
    },
    Code_required: {
      id: 'Text.Code_required',
    },
    Create_new_custom_field_information: {
      id: 'Text.Create_new_custom_field_information',
    },
    Actions_to_take: {
      id: 'Text.Actions_to_take',
    },
    Actions_take: {
      id: 'Text.Actions_take',
    },
    Action: {
      id: 'Text.action'
    },
    Input_value: {
      id: 'Text.Input_value'
    },
    Actions: {
      id: 'Text.Actions',
    },
    Dcr_inactivate_record_message: {
      id: 'Text.Dcr_inactivate_record_message',
    },
    Action_was_successful: {
      id: 'Text.Action_was_successful',
    },
    Retain_dcr_records: {
      id: 'Text.Retain_dcr_records',
    },
    Inactivate_dcr_records: {
      id: 'Text.Inactivate_dcr_records',
    },
    Handle_inactivate_dcr_record_tooltip: {
      id: 'Text.Handle_inactivate_dcr_record_tooltip',
    },
    Handle_inactivate_dcr_record: {
      id: 'Text.Handle_inactivate_dcr_record',
    },
    Activity_requires_exactly_one_responsible_user: {
      id: 'Text.Activity_requires_exactly_one_responsible_user',
    },
    Activity_successfully_recovered: {
      id: 'Text.Activity_successfully_recovered',
    },
    Activity_successfully_completed: {
      id: 'Text.Activity_successfully_completed',
    },
    Activity_successfully_deleted: {
      id: 'Text.Activity_successfully_deleted',
    },
    Activity_type_required: {
      id: 'Text.Activity_type_required',
    },
    Add_attendees_to_project: {
      id: 'Text.Add_attendees_to_project',
    },
    additional_count: {
      id: 'Text.additional_count',
    },
    additional_records: {
      id: 'Text.additional_records',
    },
    Add_selected_persons_to_new_Activity: {
      id: 'Text.Add_selected_persons_to_new_Activity',
    },
    Update_Time_Success: {
      id: 'Text.Update.Time.Success',
    },
    Update_Grid_Success: {
      id: 'Text.Update.Grid.Success',
    },
    Allow_Multiple_Values_ToolTip: {
      id: 'Text.Allow_Multiple_Values_ToolTip',
    },
    Select_Color_Badge_ToolTip: {
      id: 'Text.Select_Color_Badge_ToolTip',
    },
    Allow_Users_ToAdd_Values_ToolTip: {
      id: 'Text.Allow_Users_ToAdd_Values_ToolTip',
    },
    Custom_Field_Status_ToolTip: {
      id: 'Text.Custom_Field_Status_ToolTip',
    },
    Custom_Field_Tag_color_ToolTip: {
      id: 'Text.Custom_Field_Tag_color_ToolTip',
    },
    Custom_Field_Tag_color: {
      id: 'Text.Custom_Field_Tag_color',
    },
    Add_selected_persons_to_new_Project: {
      id: 'Text.Add_selected_persons_to_new_Project',
    },
    Add_selected_person_to_activity: {
      id: 'Text.Add_selected_person_to_activity',
    },
    Add_selected_persons_to_new_static_list: {
      id: 'Text.Add_selected_persons_to_new_static_list',
    },
    Add_selected_records_to_existing_list: {
      id: 'Text.Add_selected_records_to_existing_list',
    },
    Add_selected_records_to_new_list: {
      id: 'Text.Add_selected_records_to_new_list',
    },
    Add_to_existing_list: {
      id: 'Text.Add_to_existing_list',
    },
    Add_to_new_Project: {
      id: 'Text.Add_to_new_Project',
    },
    Add_to_new_Activity: {
      id: 'Text.Add_to_new_Activity',
    },
    Add_description_text_here: {
      id: 'Text.Add_description_text_here',
    },
    Add_people_to_activity: {
      id: 'Text.Add_people_to_activity',
    },
    Add_product_to_activity: {
      id: 'Text.Add_product_to_activity',
    },
    Add_project_to_activity: {
      id: 'Text.Add_project_to_activity',
    },
    Add_product_to_project: {
      id: 'Text.Add_product_to_project',
    },
    Add_product_with_Segmentation_Code: {
      id: 'Text.Add_product_with_Segmentation_Code',
    },
    Add_segmentation_code_here: {
      id: 'Text.Add_segmentation_code_here',
    },
    Add_tags_from_all_different_codes: {
      id: 'Text.Add_tags_from_all_different_codes',
    },
    Allow_multiple: {
      id: 'Text.Allow_multiple',
    },
    Allow_user_create: {
      id: 'Text.Allow_user_create',
    },
    Entity_Pie_Chart: {
      id: 'Text.Entity_Pie_Chart',
    },
    Entity_Pie_Chart_Description: {
      id: 'Text.Entity_Pie_Chart_Description',
    },
    email_has_been_sent: {
      id: 'Text.email_has_been_sent',
    },
    Archive_lists: {
      id: 'Text.Archive_lists',
    },
    Are_you_sure_to_delete_this_affiliation: {
      id: 'Text.Are_you_sure_to_delete_this_affiliation',
    },
    Are_you_sure_to_delete_this_task: {
      id: 'Text.Are_you_sure_to_delete_this_task',
    },
    Are_you_sure_to_delete_saved_search: {
      id: 'Text.Are_you_sure_to_delete_saved_search',
    },
    Are_you_sure_to_recover_this_item: {
      id: 'Text.Are_you_sure_to_recover_this_item',
    },
    Are_you_sure_to_skip_this_person: {
      id: 'Text.Are_you_sure_to_skip_this_person',
    },
    Are_you_sure_to_remove_persons_from_activity: {
      id: 'Text.Are_you_sure_to_remove_persons_from_activity',
    },
    Are_you_sure_to_delete: {
      id: 'Text.Are_you_sure_to_delete',
    },
    At_least_one_number: {
      id: 'Text.At_least_one_number',
    },
    At_least_one_person_required: {
      id: 'Text.At_least_one_person_required',
    },
    At_least_one_special_character: {
      id: 'Text.At_least_one_special_character',
    },
    At_least_one_user_required: {
      id: 'Text.At_least_one_user_required',
    },
    Attach_person_to_activity: {
      id: 'Text.Attach_person_to_activity',
    },
    Back_to_DCR: {
      id: 'Text.Back_to_DCR',
    },
    Back_to_default: {
      id: 'Text.Back_to_default',
    },
    Back_to_login: {
      id: 'Text.Back_to_login',
    },
    Cant_save_list: {
      id: 'Text.Cant_save_list',
    },
    Cherry_picked_person: {
      id: 'Text.Cherry_picked_person_and_linked_externalId',
    },
    Cherry_picking_multiple_records_not_supported: {
      id: 'Text.Cherry_picking_multiple_records_not_supported',
    },
    Cherry_pick_selected_records: {
      id: 'Text.Cherry_pick_selected_records',
    },
    Clears_search_back_to_default: {
      id: 'Text.Clears_search_back_to_default',
    },
    Click_here_to_add_a_person_to_the_activity: {
      id: 'Text.Click_here_to_add_a_person_to_the_activity',
    },
    Click_here_to_add_a_project_to_the_activity: {
      id: 'Text.Click_here_to_add_a_project_to_the_activity',
    },
    Click_here_to_select_a_product_to_add_to_the_activity: {
      id: 'Text.Click_here_to_select_a_product_to_add_to_the_activity',
    },
    Click_to_drag_a_file_to_this_area_to_upload: {
      id: 'Text.Click_to_drag_a_file_to_this_area_to_upload',
    },
    Couldnt_load_cached_criteria: {
      id: 'Text.Couldnt_load_cached_criteria',
    },
    Country_is_required: {
      id: 'Text.Country_is_required',
    },
    Create_a_Data_Change_Request: {
      id: 'Text.Create_a_Data_Change_Request',
    },
    Created_new_person: {
      id: 'Text.Created_new_person',
    },
    Create_a_new_activity: {
      id: 'Text.Create_a_new_activity',
    },
    Create_a_new_project: {
      id: 'Text.Create_a_new_project',
    },
    Create_a_new_static_list: {
      id: 'Text.Create_a_new_static_list',
    },
    Created_by_urlUser: {
      id: 'Text.Created_by_urlUser',
    },
    Creating_DCRs_not_supported: {
      id: 'Creating_DCRs_not_supported',
    },
    Criteria_object_not_have_any_type: {
      id: 'Criteria_object_not_have_any_type',
    },
    Data_updated: {
      id: 'Text.Data_updated',
    },
    Dates_has_been_updated: {
      id: 'Text.Dates_has_been_updated',
    },
    Dashboard_has_been_deleted: {
      id: 'Text.Dashboard_has_been_deleted',
    },
    DCR_successfully_created: {
      id: 'Text.DCR_successfully_created',
    },
    Delete_saved_search: {
      id: 'Text.Delete_saved_search',
    },
    Deleting_this_item_will_archive_it_in_the_system: {
      id: 'Text.Deleting_this_item_will_archive_it_in_the_system',
    },
    Deleting_this_list_will_permanently_remove_the_list_from_the_system: {
      id: 'Text.Deleting_this_list_will_permanently_remove_the_list_from_the_system',
    },
    Displayed_on_navigation_bar: {
      id: 'Text.Displayed_on_navigation_bar',
    },
    Displaying_customfield_with_type: {
      id: 'Text.Displaying_customfield_with_type',
    },
    Download_failed: {
      id: 'Text.Download_failed',
    },
    Do_you_want_to_selected_HCPs: {
      id: 'Text.Do_you_want_to_selected_HCPs',
    },
    Drag_a_field_here: {
      id: 'Text.Drag_a_field_here',
    },
    drag_fields: {
      id: 'Text.drag_fields',
    },
    Dcr_Team_Respons: {
      id: 'Text.Dcr.Team.Respons',
    },
    Dcr_upsell_text: {
      id: 'Text.Dcr_upsell_text',
    },
    Dcr_upsell_text_info: {
      id: 'Text.Dcr_upsell_text_info',
    },
    DCR_related_data_take_action_text_warning: {
      id: 'Text.DCR_related_data_take_action_text_warning',
    },
    Avilable_fields_text: {
      id: 'Text.Avilable_fields_text',
    },
    Entity_search_placeholder_text_Person: {
      id: 'Text.Entity_search_placeholder_text_Person',
    },
    Entity_search_placeholder_text_Site: {
      id: 'Text.Entity_search_placeholder_text_Site',
    },
    Entity_search_placeholder_text_Activity: {
      id: 'Text.Entity_search_placeholder_text_Activity',
    },
    Entity_search_placeholder_text_Dcr: {
      id: 'Text.Entity_search_placeholder_text_Dcr',
    },
    Entity_search_placeholder_text_Lists: {
      id: 'Text.Entity_search_placeholder_text_Lists',
    },
    Drop_the_search_back_here_if_you_changed_your_mind_on_using_this_search: {
      id: 'Text.Drop_the_search_back_here_if_you_changed_your_mind_on_using_this_search',
    },
    Edit_this_record_will_send_a_data_change_request_to_the_aPureBase_Data_Managers: {
      id: 'Text.Edit_this_record_will_send_a_data_change_request_to_the_aPureBase_Data_Managers',
    },
    External_ID_has_been_deleted: {
      id: 'Text.External_ID_has_been_deleted',
    },
    Data_Management: {
      id: 'Text.Data_Management',
    },
    Entering_heading_required: {
      id: 'Text.Entering_heading_required',
    },
    Enter_name_for_new_saved_search: {
      id: 'Text.Enter_name_for_new_saved_search',
    },
    Failed_to_create_new_saved_search: {
      id: 'Text.Failed_to_create_new_saved_search',
    },
    Failed_to_delete_saved_search: {
      id: 'Text.Failed_to_delete_saved_search',
    },
    Failed_to_update_saved_search: {
      id: 'Text.Failed_to_update_saved_search',
    },
    File_uploaded_successfully: {
      id: 'Text.File_uploaded_successfully',
    },
    File_upload_failed: {
      id: 'Text.File_upload_failed',
    },
    Forgot_password: {
      id: 'Text.Forgot_password',
    },
    Forgotten_your_password: {
      id: 'Text.Forgotten_your_password',
    },
    fullname_Responsible_users: {
      id: 'Text.fullname_Responsible_users',
    },
    GDPR_information_letter_sent: {
      id: 'Text.GDPR_information_letter_sent',
    },
    GDPR_Informed: {
      id: 'Text.GDPR_Informed',
    },
    Client_maintain: {
      id: 'Text.Client_maintain',
    },
    Unplaced: {
      id: 'Text.Unplaced',
    },
    Unplaced_toolTip: {
      id: 'Text.Unplaced_toolTip',
    },
    Validation_Date_Found: {
      id: 'Text.Validation_Date_Found',
    },
    Validation_By_User: {
      id: 'Text.Validation_By_User',
    },
    Validation_Logs: {
      id: 'Text.Validation_Logs',
    },
    Validation_Date_Not_Found: {
      id: 'Text.Validation_Date_Not_Found',
    },
    Validation_Mass_Assign_Label: {
      id: 'Text.Validation_Mass_Assign_Label',
    },
    Validation_Mass_assign_title_confirm: {
      id: 'Text.Validation_Mass_assign_title_confirm',
    },
    Validation_Mass_assign_no_title_confirm: {
      id: 'Text.Validation_Mass_assign_no_title_confirm',
    },
    Cherry_picked: {
      id: 'Text.Cherry_picked',
    },
    Generation_failed: {
      id: 'Text.Generation_failed',
    },
    Dcr_remove_affiliation_text: {
      id: 'Text.Dcr_remove_affiliation_text',
    },
    Dcr_remove_hco_affiliation_text: {
      id: 'Text.Dcr_remove_hco_affiliation_text',
    },
    Dcr_remove_affiliation_heading: {
      id: 'Text.Dcr_remove_affiliation_heading',
    },
    Dcr_remove_hco_affiliation_heading: {
      id: 'Text.Dcr_remove_hco_affiliation_heading',
    },
    Dcr_remove_affiliation: {
      id: 'Text.Dcr_remove_affiliation',
    },
    Dcr_remove_affiliation_persons: {
      id: 'Text.Dcr_remove_affiliation_persons',
    },
    Dcr_remove_affiliation_persons_text: {
      id: 'Text.Dcr_remove_affiliation_persons_text',
    },
    Dcr_remove_affiliation_dcr_confirm: {
      id: 'Text.Dcr_remove_affiliation_dcr_confirm',
    },
    Are_you_sure: {
      id: 'Text.Are_you_sure',
    },
    Dcr_remove_succesion_text: {
      id: 'Text.Dcr_remove_succesion_text',
    },
    Grid_Field_Configuration: {
      id: 'Text.Grid_Field_Configuration',
      description: 'Page header for the configure entity page layout',
    },
    Grid_Field_Configuration_Add_New_Group: {
      id: 'Text.Grid_Field_Configuration_Add_New_Group',
      description: 'This is the text shown on the modal for adding to groups to an entity layout via admin settings',
    },
    HCO_needs_to_be_added: {
      id: 'Text.HCO_needs_to_be_added',
    },
    Has_been_added_to_list: {
      id: 'Text.Has_been_removed_from_list',
    },
    Has_been_removed_from_list: {
      id: 'Text.Has_been_removed_from_list',
    },
    Remove_from_list_tooltip: {
      id: 'Text.Remove_from_list_tooltip',
    },
    Has_not_received_GDPR_letter: {
      id: 'Text.Has_not_received_GDPR_letter',
    },
    Has_received_GDPR_letter: {
      id: 'Text.Has_received_GDPR_letter',
    },
    Opt_out: {
      id: 'Text.Opt_out',
    },
    Remove_affiliation_toltip: {
      id: 'Text.Remove_affiliation_toltip',
    },
    Remove_affiliation_hco_text: {
      id: 'Text.Remove_affiliation_hco_text',
    },
    Deceased: {
      id: 'Text.Deceased',
    },
    Retired: {
      id: 'Text.Retired',
    },
    Other: {
      id: 'Text.Other',
    },
    Working_abroad: {
      id: 'Text.Working_abroad',
    },
    No_work_here: {
      id: 'Text.No_work_here',
    },
    Temp_no_work_here: {
      id: 'Text.Temp_no_work_here',
    },
    HCO_is_closed_down: {
      id: 'Text.HCO_is_closed_down',
    },
    HCO_is_unpopulated: {
      id: 'Text.HCO_is_unpopulated',
    },
    HCO_remove_site_affiliated_data: {
      id: 'Text.HCO_remove_site_affiliated_data',
    },
    HCO_remove_site_description_text: {
      id: 'Text.HCO_remove_site_description_text',
    },

    HCP_needs_to_be_added: {
      id: 'Text.HCP_needs_to_be_added',
    },

    Heading_must_be_beneath_40_characters: {
      id: 'Text.Heading_must_be_beneath_40_characters',
    },
    Heading_must_be_beneath_20_characters: {
      id: 'Text.Heading_must_be_beneath_20_characters',
    },
    Hide_completed_activities: {
      id: 'Text.Hide_completed_activities',
    },
    If_GDPR_information_letter_is_sent: {
      id: 'Text.If_GDPR_information_letter_is_sent',
    },
    Import_project: {
      id: 'Text.Import_project',
    },
    Include_completed_activities: {
      id: 'Text.Include_completed_activities',
    },
    In_days: {
      id: 'Text.In_days',
    },
    Input_file_preview: {
      id: 'Text.Input_file_preview',
    },
    Insert_HCP_Information: {
      id: 'Text.Insert_HCP_Information',
    },
    Insert_HCO_Information: {
      id: 'Text.Insert_HCO_Information',
    },
    Manual_match_not_supported: {
      id: 'Text.Manual_match_not_supported',
    },
    Linked_externalId_to_person: {
      id: 'Text.Linked_externalId_to_person',
    },
    Linked_externalId_to_user: {
      id: 'Text.Linked_externalId_to_user',
    },
    Loading: {
      id: 'Text.Loading',
    },
    Location_has_been_updated: {
      id: 'Text.Location_has_been_updated',
    },
    Locking_with_miltiple_input_values: {
      id: 'Text.Locking_with_miltiple_input_values',
    },
    Mass_assign_to_activity: {
      id: 'Text.Mass_assign_to_activity',
    },
    Mass_assign_to_project: {
      id: 'Text.Mass_assign_to_project',
    },
    Mass_assign_description_text: {
      id: 'Text.Mass_assign_description_text',
    },
    Mass_assign_description_selectionType: {
      id: 'Text.Mass_assign_description_selectionType',
    },
    Mass_assign_description_input_type: {
      id: 'Text.Mass_assign_description_input_type',
    },
    Mass_assign_categories_and_names: {
      id: 'Text.Mass_assign_categories_and_names',
    },
    Mass_assign_field_types: {
      id: 'Text.Mass_assign_field_types',
    },
    Mass_assign_action_set_value_to_selected: {
      id: 'Text.Mass_assign_action_set_value_to_selected',
    },
    Mass_assign_action_set_blank_out: {
      id: 'Text.Mass_assign_action_set_blank_out',
    },
    Mass_assign_action_replace_text_in_field: {
      id: 'Text.Mass_assign_action_replace_text_in_field',
    },
    Mass_assign_action_prepend_text: {
      id: 'Text.Mass_assign_action_prepend_text',
    },
    Mass_assign_action_append_text: {
      id: 'Text.Mass_assign_action_append_text',
    },
    Match_for: {
      id: 'Text.Match_for',
    },
    Name_too_short: {
      id: 'Text.Name_too_short',
    },
    Network_error_occured: {
      id: 'Text.Network_error_occured',
    },
    No_activities_in_timespan: {
      id: 'Text.No_activities_in_timespan',
    },
    No_activity_registered: {
      id: 'Text.No_activity_registered',
    },
    No_email_provided: {
      id: 'Text.No_email_provided',
    },
    No_external_IDs: {
      id: 'Text.No_external_IDs',
    },
    No_HCP_added: {
      id: 'Text.No_HCP_added',
    },
    No_location_added: {
      id: 'Text.No_location_added',
    },
    No_note_added: {
      id: 'Text.No_note_added',
    },
    Not_being_used: {
      id: 'Text.Not_being_used',
    },
    Not_found: {
      id: 'Text.Not_found',
    },
    Not_supported_yet: {
      id: 'Text.Not_supported_yet',
    },
    Note_successfully_updated: {
      id: 'Text.Note_successfully_updated',
    },
    Note_successfully_created: {
      id: 'Text.Note_successfully_created',
    },
    No_site_found: {
      id: 'Text.No_site_found',
    },
    Note_successfully_deleted: {
      id: 'Text.Note_successfully_deleted',
    },
    DashboardItem_successfully_deleted: {
      id: 'Text.DashboardItem_successfully_deleted',
    },
    Nothing_found: {
      id: 'Text.Nothing_found',
    },
    Password_reset_email_sent_successfully: {
      id: 'Text.Password_reset_email_sent_successfully',
    },
    Passwords_do_not_match: {
      id: 'Text.Passwords_do_not_match',
    },
    Permission_to_view_list_denied: {
      id: 'Text.Permission_to_view_list_denied',
    },
    people: {
      id: 'Text.people',
    },
    people_added_to_project: {
      id: 'Text.people_added_to_project',
    },
    Please_enter_heading: {
      id: 'Text.Please_enter_heading',
    },
    Please_enter_description: {
      id: 'Text.Please_enter_description',
    },
    Please_select_which_system_the_Ids_within_this_file_are_coming_from: {
      id: 'Text.Please_select_which_system_the_Ids_within_this_file_are_coming_from',
    },
    Populate_these_inputs_to_generate_the_selected_report: {
      id: 'Text.Populate_these_inputs_to_generate_the_selected_report',
    },
    Position_needs_to_be_added: {
      id: 'General.Position_needs_to_be_added',
    },
    Positions_on_site: {
      id: 'Text.Positions_on_site',
    },
    Press_to_mark_list_as_favorite: {
      id: 'Text.Press_to_mark_list_as_favorite',
    },
    Press_to_mark_list_as_pinned: {
      id: 'Text.Press_to_mark_list_as_pinned',
    },
    Press_to_remove_list_from_favorites: {
      id: 'Text.Press_to_remove_list_from_favorites',
    },
    Press_to_unpin_list: {
      id: 'Text.Press_to_unpin_list',
    },
    Product_is_required: {
      id: 'Text.Product_is_required',
    },
    Person_is_required: {
      id: 'Text.Person_is_required',
    },
    Provide_both_passwords: {
      id: 'Text.Provide_both_passwords',
    },
    Provide_users_roles_as_responsible_users_for_this_HCP: {
      id: 'Text.Provide_users_roles_as_responsible_users_for_this_HCP',
    },
    Providing_month_is_required: {
      id: 'Text.Providing_month_is_required',
    },
    Providing_year_is_required: {
      id: 'Text.Providing_year_is_required',
    },
    Remove_person_from_list: {
      id: 'Text.Remove_person_from_list',
    },
    Remove_rule: {
      id: 'Text.Remove_rule',
    },
    Repeat_password: {
      id: 'Text.Repeat_password',
    },
    reset_password_here: {
      id: 'Text.reset_password_here',
    },
    Responsible_user: {
      id: 'Text.Responsible_user',
    },
    Responsible_user_is_required: {
      id: 'Text.Responsible_user_is_required',
    },
    Responsible_users_have_been_updated: {
      id: 'Text.Responsible_users_have_been_updated',
    },
    Save_as_new_search: {
      id: 'Text.Save_as_new_search',
    },
    Build_search: {
      id: 'Text.Build_search'
    },
    Save_search: {
      id: 'Text.Save_search'
    },
    Save: {
      id: 'Text.Save',
    },
    Select_value: {
      id: 'Text.Select_value',
    },
    Select_value_placeholder: {
      id: 'Text.Select_value_placeholder',
    },
    Add_to_grid: {
      id: 'Text.Add_dashboard_items',
    },
    Update_grid: {
      id: 'Text.Update_grid',
    },
    Saved_results: {
      id: 'Text.Saved_results',
    },
    Save_search_as_dynamic_list: {
      id: 'Text.Save_search_as_dynamic_list',
    },
    Search_for_saved_searched: {
      id: 'Text.Search_for_saved_searched',
    },
    Search_avilable_fields: {
      id: 'Text.Search_avilable_fields',
    },
    search_results: {
      id: 'Text.search_results',
    },
    Segmentation_code_has_been_successfully_updated: {
      id: 'Text.Segmentation_code_has_been_successfully_updated',
    },
    Segmentation_code_has_been_successfully_removed: {
      id: 'Text.Segmentation_code_has_been_successfully_removed',
    },
    Modify_dashboard_item_tooltip: {
      id: 'Text.dashboard_item_tooltip',
    },
    Modify_dashboard_item_tooltip_rescriction: {
      id: 'Text.dashboard_item_tooltip_rescriction',
    },
    Select_other_users_dashboard: {
      id: 'Text.Select_other_users_dashboard',
    },
    Select_responsible_users: {
      id: 'Text.Select_responsible_users',
    },
    Select_Status: {
      id: 'Text.Select_Status',
    },
    Selected_records_have_been_added_to_list: {
      id: 'Text.Selected_records_have_been_added_to_list',
    },
    Send_Data_Change_Request: {
      id: 'Text.Send_Data_Change_Request',
    },
    Show_all_activities: {
      id: 'Text.Show_all_activities',
    },
    Show_all_title_for_all: {
      id: 'Text.Show_all_title_for_all',
    },
    Site_not_found: {
      id: 'Text.Site_not_found',
    },
    Skipping_this_person: {
      id: 'Text.Skipping_this_person',
    },
    Something_went_wrong: {
      id: 'Text.Something_went_wrong',
    },
    No_activity_found: {
      id: 'Text.No_activity_found',
    },
    Status_has_been_updated: {
      id: 'Text.Status_has_been_updated',
    },
    Strictly_prohibited_from_uploading_company_data_or_other: {
      id: 'Text.Strictly_prohibited_from_uploading_company_data_or_other',
    },
    Successfully_added_product_brand_to_project: {
      id: 'Text.Successfully_added_product_brand_to_project',
    },
    Successfully_added_to_project: {
      id: 'Text.Successfully_added_to_project',
    },
    Successfully_added_hcps_to_activity: {
      id: 'Text.Successfully_added_hcps_to_activity',
    },
    Successfully_cherry_picked: {
      id: 'Text.Successfully_cherry_picked',
    },
    Successfully_created_delete_DCR: {
      id: 'Text.Successfully_created_delete_DCR',
    },
    Successfully_created_new_password: {
      id: 'Text.Successfully_created_new_password',
    },
    Successfully_created_new_saved_search: {
      id: 'Text.Successfully_created_new_saved_search',
    },
    Successfully_delete_saved_search: {
      id: 'Text.Successfully_delete_saved_search',
    },
    Successfully_imported_file: {
      id: 'Text.Successfully_imported_file',
    },
    Successfully_removed_activities: {
      id: 'Text.Successfully_removed_activities',
    },
    Successfully_removed_product_brand: {
      id: 'Text.Successfully_removed_product_brand',
    },
    Successfully_reverted_activity_title: {
      id: 'Text.Successfully_reverted_activity_title',
    },
    Successfully_updated_activity_title: {
      id: 'Text.Successfully_updated_activity_title',
    },
    Successfully_updated_saved_search: {
      id: 'Text.Successfully_updated_saved_search',
    },
    Successfully_updated_time: {
      id: 'Text.Successfully_updated_time',
    },
    Support_for_single_csv_file: {
      id: 'Text.Support_for_single_csv_file',
    },
    The_input_is_not_valid_email: {
      id: 'Text.The_input_is_not_valid_email',
    },
    Therapeutic_focus: {
      id: 'Text.Therapeutic_focus',
    },
    Link_Therapeutic_focus: {
      id: 'Text.Link_Therapeutic_focus',
    },
    There_are_cherrypicks_left: {
      id: 'Text.There_are_cherrypicks_left',
    },
    This_HCP_is_not_in_local_data: {
      id: 'Text.This_HCP_is_not_in_local_data',
    },
    This_is_not_a_valid_password: {
      id: 'Text.This_is_not_a_valid_password',
    },
    This_list_is_pinned_globally_for_all_users: {
      id: 'Text.This_list_is_pinned_globally_for_all_users',
    },
    This_person_is_already_in_this_project: {
      id: 'Text.This_person_is_already_in_this_project',
    },
    This_view_is_in_early_stages: {
      id: 'Text.This_view_is_in_early_stages',
    },
    Time_span_is_required: {
      id: 'Text.Time_span_is_required',
    },
    Token_is_not_valid: {
      id: 'Text.Token_is_not_valid',
    },
    Type_updated_to_new_type: {
      id: 'Text.Type_updated_to_new_type',
    },
    type_required: {
      id: 'Text.type_required',
    },
    Updated_responsible_users: {
      id: 'Text.Updated_responsible_users',
    },
    Uppercase_and_lowercase_letters: {
      id: 'Text.Uppercase_and_lowercase_letters',
    },
    Year_is_required: {
      id: 'Text.Year_is_required',
    },
    You_are_not_allowed_to_have_more_than_one_type_to_activity: {
      id: 'Text.You_are_not_allowed_to_have_more_than_one_type_to_activity',
    },
    You_are_trying_to_cherrypick: {
      id: 'Text.You_are_trying_to_cherrypick',
    },
    You_have_cherrypicks_left: {
      id: 'Text.You_have_cherrypicks_left',
    },
    You_need_to_specify_a_heading: {
      id: 'Text.You_need_to_specify_a_heading',
    },
    You_need_to_specify_a_range: {
      id: 'Text.You_need_to_specify_a_range',
    },
    You_need_to_specify_a_title: {
      id: 'Text.You_need_to_specify_a_title',
    },
    You_need_to_specify_a_type: {
      id: 'Text.You_need_to_specify_a_type',
    },
    Warning_Text: {
      id: 'Text.Warning_Text',
    },
    Dashboard_Newly_Created_Records_Name: {
      id: 'Text.Dashboard_Newly_Created_Records_Name',
    },
    Dashboard_Newly_Created_Records_Description: {
      id: 'Text.Dashboard_Newly_Created_Records_Description',
    },
    Data_Management_Dashboard_Description: {
      id: 'Text.Data_Management_Dashboard_Description',
    },
    DCR_Status: {
      id: 'Text.DCR_Status',
    },
    DCR_Hcp_activity_related_data: {
      id: 'Text.DCR_Hcp_activity_related_data',
    },
    DCR_has_been_created: {
      id: 'Text.DCR_has_been_created',
    },
    DCR_Hco_activity_related_data: {
      id: 'Text.DCR_Hco_activity_related_data',
    },
    DCR_history_create: {
      id: 'Text.DCR_history_create',
    },
    DCR_history_unhandled: {
      id: 'Text.DCR_history_unhandled',
    },
    DCR_history_reject: {
      id: 'Text.DCR_history_reject',
    },
    DCR_history_reject_by_user: {
      id: 'Text.DCR_history_reject_by_user',
    },
    DCR_history_reject_by_user_kept_as_local: {
      id: 'Text.DCR_history_reject_by_user_kept_as_local',
    },
    DCR_history_pending_reject: {
      id: 'Text.DCR_history_pending_reject',
    },
    DCR_history_queue: {
      id: 'Text.DCR_history_queue',
    },
    DCR_history_accept: {
      id: 'Text.DCR_history_accept',
    },
    DCR_Hcp_dynamic_list_message_by_type: {
      id: 'Text.DCR_Hcp_dynamic_list_message_by_type',
    },
    DCR_Hcp_dynamic_list_message_count: {
      id: 'Text.DCR_Hcp_dynamic_list_message_count',
    },
    DCR_Hcp_static_list_message_count: {
      id: 'Text.DCR_Hcp_static_list_message_count',
    },
    DCR_Hcp_private_list_message_count: {
      id: 'Text.DCR_Hcp_private_list_message_count',
    },
    DCR_no_Hcp_activity_related_data: {
      id: 'Text.DCR_no_Hcp_activity_related_data',
    },
    DCR_Hco_no_activity_related_data: {
      id: 'Text.DCR_Hco_no_activity_related_data',
    },
    DCR_status_comment: {
      id: 'Text.DCR_status_comment',
    },
    DCR_comment: {
      id: 'Text.DCR_comment',
    },
    DCR_related_data_overview_text: {
      id: 'Text.DCR_related_data_overview_text',
    },
    DCR_related_data_take_action_text: {
      id: 'Text.DCR_related_data_take_action_text',
    },
    DCR_related_data_confirm_rejection_text: {
      id: 'Text.DCR_related_data_confirm_rejection_text',
    },
    DCR_related_data_display_below: {
      id: 'Text.DCR_related_data_display_below',
    },
    DCR_related_data_display_below_no_mdm: {
      id: 'Text.DCR_related_data_display_below_no_mdm',
    },
    Cherry_Pick_Usage: {
      id: 'Text.Cherry_Pick_Usage',
    },
    People_are_selected: {
      id: 'Text.People_are_selected',
    },
    Successfully_updated_type_value: {
      id: 'Text.Successfully_updated_type_value',
    },
    No_image_provided: {
      id: 'Text.No_image_provided',
    },
    No_position_provided: {
      id: 'Text.No_position_provided',
    },
    Missing_permission_to_create_site: {
      id: 'Text.Missing_permission_to_create_site',
    },
    User_is_locked_information: {
      id: 'Text.User_is_locked_information',
    },
    User_is_hidden_information: {
      id: 'Text.User_is_hidden_information',
    },
  },
};
