import React from 'react';
import { Form, Row, Input, Col } from 'antd';
import { gql, useQuery } from '@apollo/client';
import MainView from '../../components/View/MainView';
import { EntitiesSearch } from '../../components/entitiesSearch';
import { usePageTitle } from '../../hooks/usePageTitle';
import { Locale } from '../../../localization/LocalizationKeys';
import { usePersonTableQuickActions } from './hooks/usePersonTableQuickActions';
import { EntityTypeEnum, searchHasMdmAccessQuery } from '../../../gql/typings';
import EstherEasterEgg from '../../components/EasterEggs/EstherEasterEgg';
import { useUser } from '../../util/useUser';
import { useLocalization } from '../../util/useLocalization';
import { useSystemPermission } from '../../permission/useSystemPermission';
import SearchTabs from '../../components/entitiesSearch/SearchTabs';
import EntitiesSearchAdvance from '../../components/entitiesSearch/entitiesSearchAdvance';
import { SearchTabsId } from '../../util/entity';

const PersonView = () => {
  const localization = useLocalization();
  const headerStr = localization.formatMessage(Locale.General.Persons_HCP);
  usePageTitle(headerStr);
  const [form] = Form.useForm();
  const { entitiesSearchProps, tableSelection, HiddenRender } = usePersonTableQuickActions(form, { maintainPageState: true });
  const { data: mdmAccess } = useQuery<searchHasMdmAccessQuery>(HasMdmAccess);

  const [advanceForm] = Form.useForm();
  const { entitiesSearchProps: entitiesAdvanceSearchProps,
    tableSelection: advanceTableSelection,
    HiddenRender: AdvanceHiddenRender } = usePersonTableQuickActions(advanceForm);
  const { username } = useUser();
  const hasGlobalSearchPermission = useSystemPermission('globalSearch');

  return (
    <MainView className="person-view-container" boundingBox={false}>
      {username.slice(-14) === '@apurebase.com' && (
        <EstherEasterEgg
          text={['Launching', 'PureAdvance MDM', '2023 Hooray!!']}
        />
      )}
      {
        mdmAccess?.hasAccessToModule ? (
          <SearchTabs
            defaultActiveKey="1"
            entityType={EntityTypeEnum.PERSON}
            items={[
              {
                key: SearchTabsId.Simple,
                label: 'Simple Search',
                children: <>
                  <EntitiesSearch
                    entityType={EntityTypeEnum.PERSON}
                    form={form}
                    globalSupport
                    globalSearch={hasGlobalSearchPermission}
                    urlSearchEnabled
                    tableSelection={tableSelection}
                    customSearchFilterCriteria={<>

                      <Input.Group
                        className="criteria-input-container contrast-label"
                        style={{ display: 'grid', maxWidth: '220px' }}
                      >
                        <span className="label">
                          {localization.formatMessage(Locale.Attribute.aPureBase_ID_list)}
                        </span>
                        <Row gutter={[4, 4]}>
                          <Col span={24}>
                            <Form.Item name='aPureBaseIds'>
                              <Input
                                id="aPureBaseIds"
                                size='large'
                                placeholder={localization.formatMessage(Locale.Command.Enter_aPureBase_IDs)}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Input.Group>

                    </>}
                    {...entitiesSearchProps}
                  />

                </>
              },
              {
                key: SearchTabsId.Advance,
                label: 'Advance Search',
                children: <>
                  <EntitiesSearchAdvance
                    entityType={EntityTypeEnum.PERSON}
                    form={advanceForm}
                    globalSupport
                    globalSearch={hasGlobalSearchPermission}
                    urlSearchEnabled
                    tableSelection={advanceTableSelection}
                    {...entitiesAdvanceSearchProps}
                  />
                </>
              }
            ]}
          />
        ): (
          <EntitiesSearch
            entityType={EntityTypeEnum.PERSON}
            form={form}
            globalSupport
            globalSearch={hasGlobalSearchPermission}
            urlSearchEnabled
            tableSelection={tableSelection}
            customSearchFilterCriteria={<>

              <Input.Group className="criteria-input-container contrast-label" style={{ display: 'grid', maxWidth: '220px' }}>
                <span className="label">
                  {localization.formatMessage(Locale.Attribute.aPureBase_ID_list)}
                </span>
                <Row gutter={[4, 4]}>
                  <Col span={24}>
                    <Form.Item name='aPureBaseIds'>
                      <Input
                        id="aPureBaseIds"
                        size='large'
                        placeholder={localization.formatMessage(Locale.Command.Enter_aPureBase_IDs)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Input.Group>

            </>}
            {...entitiesSearchProps}
          />
        )
      }
      <HiddenRender />
      <AdvanceHiddenRender />
    </MainView>
  );
};

export default PersonView;


const HasMdmAccess = gql`
  query searchHasMdmAccess {
    hasAccessToModule(module: MDM)
  }
`;
