import React from 'react';
import { Tag } from 'antd';
import { Locale } from '../../../../localization/LocalizationKeys';
import { personFieldConfig } from './utils/personFieldsUtils';


export const personCherryPickedFieldConfig = personFieldConfig(
  'cherryPicked',
  [
    'id',
    'isCherryPicked',
  ],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Cherry_picked),
    additionalTableConfig: {
      width: 100
    },
    render: (record) => !record.isCherryPicked ? <></> : (<Tag color="#85B0D6">
      {localization.formatMessage(Locale.Text.Cherry_picked)}
    </Tag>
    ),
  })
);
