import React, { useContext } from 'react';
import { Locale } from '../../../../localization/LocalizationKeys';
import AdvancedSearchBuilder from './AdvancedSearchBuilder';
import LightCollapse from '../../LightCollapse/LightCollapse';
import EntitiesSearchContext from '../EntitiesSearchContext';
import AdvanceSavedSearches from '../top/drawer/AdvanceSavedSearches';
import { useLocalization } from '../../../util/useLocalization';
import { StateArray } from '../../../util/StateArrayType';


type AdvanceSearchType = {
  drawerState: StateArray<boolean>;
};
const AdvanceSearch: React.FC<AdvanceSearchType> = ({
  drawerState
}) => {
  // const localization = useLocalization();
  const { formatMessage } = useLocalization();
  const {
    entityType
  } = useContext(EntitiesSearchContext);
  const subMenus = [
    {
      key: 1,
      heading: formatMessage(Locale.Attribute.Saved_Searches),
      content: <AdvanceSavedSearches drawerState={drawerState} />,
    },
    {
      key: 2,
      heading: formatMessage(Locale.Text.Build_advance_search_builder),
      content: <AdvancedSearchBuilder entityType={entityType} />
    }
  ];
  return (
    <LightCollapse
      accordion
      defaultActiveKey={2}
      className="entities-simple-search-container"
    >

      {subMenus.map(group => (
        <LightCollapse.Panel key={`${group.key}`} header={group.heading}>
          {group.content}
        </LightCollapse.Panel>
      ))}
    </LightCollapse>
  );
};

export default AdvanceSearch;
