import AdvanceCriteriaTextInput from './types/AdvanceCriteriaTextInput';
import AdvanceCriteriaSelectInput from './types/AdvanceCriteriaSelectInput';
import AdvanceCriteriaDateOptions from './types/AdvanceCriteriaDateOptions';
import AdvanceCriteriaSwitchInput from './types/AdvanceCriteriaSwitchInput';

export default {
  Text: AdvanceCriteriaTextInput,
  Select: AdvanceCriteriaSelectInput,
  MultiSelect: AdvanceCriteriaSelectInput,
  Date: AdvanceCriteriaDateOptions,
  Switch: AdvanceCriteriaSwitchInput
};
