import React from 'react';
import { gql } from '@apollo/client';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import AdminMergeItemBasicField from '../../components/basicField/AdminMergeItemBasicField';
import { MergeItemAffiliationTherapeuticFocusFragment } from '../../../../../../../../../gql/typings';
import { reactJoin } from '../../../../../../../../util/reactJoin';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';


const AdminMergeItemAffiliationTherapeuticFocus: React.FC<AdminEntityMergeRequestItemProps> = (props) => {
  const info = props.item.type as MergeItemAffiliationTherapeuticFocusFragment;
  const localization = useLocalization();

  if (!props.item.hasConflict) return (
    <AdminMergeItemBasicField {...props}>
      {localization.pluralMessage(Locale.Text.Merge_request_person_therapeutic_focus_description, info.toBeMoved.length, {
        focuses: reactJoin(info.toBeMoved.map(e => <strong key={e.code}>{e.label}</strong>)),
      })}
    </AdminMergeItemBasicField>
  );

  return <span>Something went wrong. Please contact support at support@pureadvance.com</span>;
};

gql`
  fragment MergeItemAffiliationTherapeuticFocus  on AffiliationTherapeuticFocus_EntityDataToBeTransferredType {
    code
    entityType
    toBeMoved {
      code
      type
      label
    }
  }
`;

export default AdminMergeItemAffiliationTherapeuticFocus;
