import React, { CSSProperties, useEffect, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import moment, { Moment } from 'moment';
import { Radio } from 'antd';
import { DashboardItemProps } from '../DashboardItemTypes';
import { Locale } from '../../../../localization/LocalizationKeys';
import DashboardCard from '../DashboardCard';
import { useLocalization } from '../../../util/useLocalization';
import { ColumnSeriesChart } from '../../Charts';
import { DashboardMasterDataOverviewQueryQuery, DashboardMasterDataOverviewQueryQueryVariables } from '../../../../gql/typings';

type Grouping = 'week' | 'month' | 'startOfYear' | 'year' | '3year' | 'allTime';

const groupings: Record<Grouping, () => Moment | null> = {
  week: () => moment().subtract(1, 'week'),
  month: () => moment().subtract(1, 'month'),
  startOfYear: () => moment().startOf('year'),
  year: () => moment().subtract(1, 'year'),
  '3year': () => moment().subtract(3, 'year'),
  allTime: () => null,
};

const radioStyle: CSSProperties = {
  display: 'flex',
  height: '30px',
  alignItems: 'center'
};

const DashboardMasterDataOverviewItem: React.FC<DashboardItemProps> = (props) => {
  const localization = useLocalization();
  const [grouping, setGrouping] = useState<Grouping>('3year');
  const [
    load,
    { data, called, refetch },
  ] = useLazyQuery<DashboardMasterDataOverviewQueryQuery, DashboardMasterDataOverviewQueryQueryVariables>(DATA_QUERY);

  useEffect(() => {
    const variables = { from: groupings[grouping]() };
    if (!called) load({ variables });
    else refetch?.(variables);
  }, [called, grouping, load, refetch]);

  // TODO: convert enums to text resources!
  const dataItems = data?.dashboardMasterDataOverview.map(i => ({
    label: i.state,
    seriesLabel: i.entity,
    value: i.value,
  })) ?? [];

  return (
    <DashboardCard
      editMode={props.editStatus}
      id={props.dashboardItemId}
      title={localization.formatMessage(Locale.Text.Data_Management)}
    >
      <div style={{ display: 'flex' }}>
        <ColumnSeriesChart
          width={props.size.width * 0.7}
          height={props.size.height * 0.7}
          data={dataItems}
          style={{ flex: 1 }}
        />

        <Radio.Group
          name="radiogroup"
          defaultValue={grouping}
          onChange={e => setGrouping(e.target.value)}
        >
          <Radio value="week" style={radioStyle}>
            {localization.formatMessage(Locale.Attribute.Week)}
          </Radio>
          <Radio value="month" style={radioStyle}>
            {localization.formatMessage(Locale.Attribute.Month)}
          </Radio>
          <Radio value="startOfYear" style={radioStyle}>
            This {localization.formatMessage(Locale.Attribute.Year)}
          </Radio>
          <Radio value="year" style={radioStyle}>
            MAT
          </Radio>
          <Radio value="3year" style={radioStyle}>
            {localization.pluralMessage(Locale.Attribute.Years, 3)}
          </Radio>
          <Radio value="allTime" style={radioStyle}>
            {localization.formatMessage(Locale.Attribute.All_Time)}
          </Radio>
        </Radio.Group>
      </div>
    </DashboardCard>
  );
};

const DATA_QUERY = gql`
  query DashboardMasterDataOverviewQuery($from: DateTime!) {
    dashboardMasterDataOverview(from: $from) {
      entity
      state
      value
    }
  }
`;

export default DashboardMasterDataOverviewItem;
