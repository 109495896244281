/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Form, Switch } from 'antd';
import { AdvanceCriteriaInputProps } from '../AdvanceCriteriaInputTools';

const AdvanceCriteriaSwitchInput: React.FC<AdvanceCriteriaInputProps> = React.memo(({

  criteria,
}) => (
  <Form.Item name={criteria.code} valuePropName="checked">
    <Switch id={criteria.code} />
  </Form.Item>
));

export default AdvanceCriteriaSwitchInput;
