import React from 'react';
import { Form, Input, Select } from 'antd';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client/core';
import {
  EntityTypeEnum, ExternalIdFormQuery, ExternalIdFormQueryVariables,
} from '../../../../gql/typings';
import { useLocalization } from '../../../util/useLocalization';

type ExternalIdFormInputProps = {
  entityType: EntityTypeEnum;
  placeholderText?: string;
  namePath: string[];
};

const ExternalIdFormInput: React.FC<ExternalIdFormInputProps> = ({
  entityType,
  namePath,
  placeholderText
}) => {
  const localization = useLocalization();
  const {
    data,
    loading,
  } = useQuery<ExternalIdFormQuery, ExternalIdFormQueryVariables>(DATA_QUERY, { variables: { entityType } });

  /*  useEffect(() => {
    if (data?.externalIdTypes.nodes) {
      const toUseByDefault = data.externalIdTypes.nodes.filter(n => n.code === 'APB_OFFICIAL')[0]
        ?? data.externalIdTypes.nodes[0]!;
      if (!form.getFieldValue([...namePath, 'externalIdTypeCode'])) form.setFields([{
        name: [...namePath, 'externalIdTypeCode'],
        value: toUseByDefault.code,
      }]);
    }
  }, [data, form, namePath]); */

  return (
    <Input.Group compact>
      <Form.Item noStyle name={[...namePath, 'externalIdTypeCode']}>
        <Select
          style={{ minWidth: 120 }}
          loading={loading}
        >
          {data?.externalIdTypes.nodes.map(type => (
            <Select.Option key={type.code} value={type.code}>
              {type.isHeadingKey
                ? localization.formatMessageByStr(type.heading)
                : type.heading}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        style={{ marginBottom: 0 }}
        name={[...namePath, 'externalIdValue']}
      >
        <Input
          autoComplete="newpassword"
          placeholder={placeholderText || ''}
        />
      </Form.Item>
    </Input.Group>
  );
};

const DATA_QUERY = gql`
  query ExternalIdForm($entityType: EntityTypeEnum) {
    externalIdTypes(criteria: { entityType: $entityType }) {
      hash
      nodes {
        code
        isHeadingKey
        heading
      }
    }
  }
`;

export default ExternalIdFormInput;
