import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { Button, Cascader, CascaderProps, Divider, Form, Skeleton, Switch, Typography } from 'antd';
import { gql, useLazyQuery } from '@apollo/client';
import { has, isEmpty } from 'lodash';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Rule } from 'rc-field-form/lib/interface';
import {
  CriteriaTypeEnum,
  CustomMapping,
  EntityPropertyDataQueryQuery,
  EntityPropertyDataQueryQueryVariables,
  EntityTypeEnum,
  IsMappingJoinBasicEnum, MappingJoinSupport,
  TableMapper,
  VirtualColumnPath
} from '../../../gql/typings';
import { SearchInputKeyName } from '../entitiesSearch/advanced/SearchFormItem';
import { AdvanceSearchContext } from '../entitiesSearch/advanced/AdvancedSearchBuilder';
import { useLocalization } from '../../util/useLocalization';
import { Locale } from '../../../localization/LocalizationKeys';


type EntityCascaderType = {
  inputPath: SearchInputKeyName[];
  rules?: Rule[];
  showAttributes?: boolean;
  code?: string;
  onChangeFn?: () => void;
};

export interface Option {
  key?: string | number;
  value?: string | number | null;
  label: React.ReactNode | string;
  children?: Option[];
  isLeaf?: boolean;
  alias?: string;
  disabled?: boolean;
}

const defaultOptions: Option[] = [
  {
    value: undefined,
    label: <Skeleton paragraph={false} />,
    disabled: true
  }
];
const EntityCascader: React.FC<EntityCascaderType> = ({
  inputPath,
  showAttributes = true,
  rules=[],
  code,
  onChangeFn = () => {}
}) => {
  const [optionList, setOptionList] = useState<Option[]>(defaultOptions);
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const [currentSelected, setCurrentSelected] = useState<Option>();
  const [affiliationToggle, setAffiliationToggle] = useState(IsMappingJoinBasicEnum.BASIC);
  const { formatMessage } = useLocalization();
  const { entityType } = useContext(AdvanceSearchContext);
  const [getPropertyData, { data, loading }] = useLazyQuery<EntityPropertyDataQueryQuery
  , EntityPropertyDataQueryQueryVariables>(ENTITY_PROPERTY_DATA_QUERY, {
    fetchPolicy: 'no-cache'
  });

  const sortAttributes = (attr1: Option, attr2: Option): number => {
    const labelOfAttr1 = attr1.label as string;
    const labelOfAttr2 = attr2.label as string;
    return labelOfAttr1.localeCompare(labelOfAttr2);
  };

  useEffect(() => {
    getPropertyData({
      variables: {
        entityType
      }
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data && !currentSelected && !initialLoad) {
      const optionListData = onJoinRecursion(data?.tableMapper as EntityPropertyDataQueryQuery['tableMapper']);
      setOptionList(optionListData);
      setInitialLoad(true);
      return;
    }
    if (data && currentSelected) {
      const parsed = JSON.parse(currentSelected.value as string);
      const removePathFromValue = JSON.stringify({ ...parsed, paths: [] });
      currentSelected.children = [
        ...(parsed.service ? [{
          label: <Button
            style={{ width: '100%' }}
            size="small"
            // onClick={e => e.stopPropagation()}
          >{currentSelected.label}</Button>,
          value: removePathFromValue
        }] : []),
        ...onJoinRecursion(data?.tableMapper as EntityPropertyDataQueryQuery['tableMapper'], parsed.entityType)
      ];
      setOptionList([...optionList]);
      setCurrentSelected(undefined);
    }
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [data]);


  const onJoinRecursion = (
    mapper: TableMapper | EntityPropertyDataQueryQuery['tableMapper'],
    entityType?: EntityTypeEnum,
    paths?: Array<VirtualColumnPath>
  ) => {
    const arrJoinSupport = mapper!.joinSupport as Array<MappingJoinSupport>;
    const { jsWithDataValue, jsWithoutDataValue } = arrJoinSupport.reduce(
      (acc, obj) => {
        if (obj.dataValue) {
          acc.jsWithDataValue.push(obj);
        } else {
          acc.jsWithoutDataValue.push(obj);
        }
        return acc;
      }, {
        jsWithDataValue: [] as Array<MappingJoinSupport>, jsWithoutDataValue: [] as Array<MappingJoinSupport>
      }
    );
    const joinSupportEntities = jsWithoutDataValue.map(
      prop => ({
        label: formatMessage({ id: prop.headingKey }),
        value: `${JSON.stringify({
          code: prop.code,
          entityType: prop.entityType,
          inputType: prop.supportedCriteriaTypes[0],
          inputHeadingMeta: entityType,
          parentEntityType: entityType,
          heading: prop.headingKey,
          service: prop.service,
          paths: prop.paths.map(it => ({
            id: it.id,
            mapper: it.mapper,
            mapperTo: it.mapperTo,
            mapperFrom: it.mapperFrom,
            operator: it.operatorOnMapperTo }))
        })}`,
        isLeaf: !showAttributes
      })
    ).filter(obj => !isEmpty(obj)) as Option [];
    const joinSupportEntitiesWithDV = jsWithDataValue.map(
      prop => {
        const lastPath = prop.paths[prop.paths.length - 1]!;
        const newPath = {
          id: new Date().getTime(),
          mapper: lastPath.mapper,
          mapperFrom: lastPath.mapperTo,
          mapperTo: null,
          operatorOnMapperTo: null
        };
        const concatPath = [...prop.paths, newPath]; // need new path to fix "where" clause not being added in the query
        return ({
          label: formatMessage({ id: prop.headingKey }),
          value: `${JSON.stringify({
            code: prop.code,
            entityType: prop.entityType,
            heading: prop.headingKey,
            inputHeadingMeta: entityType,
            parentEntityType: entityType,
            inputType: prop.supportedCriteriaTypes[0],
            serviceName: prop.service,
            paths: concatPath.map(it => ({
              id: it.id,
              mapper: it.mapper,
              mapperTo: it.mapperTo,
              mapperFrom: it.mapperFrom,
              operator: it.operatorOnMapperTo }))
          })}`,
          isLeaf: true
        });
      }
    ).filter(obj => !isEmpty(obj)) as Option [];
    const tableCols = showAttributes ? mapper.tableColumns.filter(col => col.heading).map(col => ({
      label: col.isHeadingKey ? formatMessage({ id: col.heading! }) : col.columnName,
      value: `${JSON.stringify({
        code: code || new Date().getTime() * Math.random(),
        heading: col.heading ? col.heading : col.columnName,
        inputType: col.supportedCriteriaTypes[0],
        inputHeadingMeta: entityType,
        parentEntityType: entityType,
        dataType: col.dataType,
        isHeadingKey: col.isHeadingKey,
        serviceName: col.service,
        isLeaf: true,
        paths: paths ? getAttributePath(paths, col) : getAttributePath([{
          id: Math.random().toString(),
          mapper: mapper.mappingName,
          mapperFrom: ''
        }], col)
      })}`,
    })) : [];
    return [...joinSupportEntities.sort(sortAttributes),
      ...tableCols.sort(sortAttributes),
      ...joinSupportEntitiesWithDV.sort(sortAttributes)];
  };

  const getAttributePath = (paths: Array<Omit<VirtualColumnPath, '__typename'>>, column: CustomMapping) => {
    const lastPath = paths[paths.length - 1];
    const newAttributePath = {
      id: Math.random().toString(),
      mapper: lastPath!.mapper,
      mapperFrom: column.columnName,
      mapperTo: null,
      ...(column.supportedCriteriaTypes[0] === CriteriaTypeEnum.Date
        ? { operator: column.supportedCriteriaTypes[0] } : {}),
    };
    return [newAttributePath];
  };

  const loadData = useCallback((selectedOptions: Option[]) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    if (targetOption && targetOption.value) {
      const valueParse = JSON.parse(targetOption.value.toString());
      if (has(valueParse, 'entityType') && !targetOption.isLeaf) {
        const selectedEntityType = valueParse.entityType as EntityTypeEnum;
        setCurrentSelected(targetOption);
        getPropertyData({
          variables: {
            entityType: selectedEntityType
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onModeToggle = (checked: boolean) => {
    if (checked) {
      setAffiliationToggle(IsMappingJoinBasicEnum.BOTH);
    } else {
      setAffiliationToggle(IsMappingJoinBasicEnum.BASIC);
    }
  };
  const dropdownRender = useCallback((menus: React.ReactNode) => (
    <div>
      {menus}
      <Divider style={{ margin: 0 }} />
      <div className='flex-center-space-between entity-cascader-footer'>
        <div className='flex-center-inline'>
          <Switch defaultChecked={affiliationToggle === IsMappingJoinBasicEnum.BOTH} onChange={onModeToggle} />
          <Typography.Text>{formatMessage(Locale.Command.Show_affiliations)}</Typography.Text>
        </div>
      </div>
    </div>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [optionList]);

  const displayRender: CascaderProps<Option>['displayRender'] = (labels, selectedOptions = []) => labels.map((label, i) => {
    const option = selectedOptions[i]!;
    let pLabel;
    if (typeof label === 'object') {
      const labelElement = label as ReactElement;
      pLabel = labelElement.props.children;
    } else {
      pLabel = label;
    }
    if (i === labels.length - 1) {
      // when entity is selected via button
      if (labels[i-1] === pLabel) {
        return <></>;
      }
      return (
        <span key={option?.value || ''}>
          {i == 0 ? '' : ' / '} {pLabel}
        </span>
      );
    }
    return <span key={option?.value}>{i == 0 ? '' : ' / '}{pLabel}</span>;
  });

  return (<div className='entity-cascader-container'>
    <Form.Item name={[...inputPath]} rules={rules}>
      <Cascader
        popupClassName='entity-cascader-menu-container'
        loadData={loadData}
        options={optionList}
        loading={loading}
        dropdownRender={dropdownRender}
        changeOnSelect={!showAttributes}
        placeholder={formatMessage(Locale.General.Select)}
        displayRender={displayRender}
        size='small'
        style={{ minWidth: '100px' }}
        onChange={onChangeFn}
      />
    </Form.Item>
  </div>);
};
export default EntityCascader;


const TableMapper_FRAGMENT = gql`
  fragment TableMapperFragment on TableMapper {
    joinSupport {
      entityType
      id: headingKey
      headingKey
      code
      mode
      service
      dataValue
      supportedCriteriaTypes
      paths {
        id
        mapper
        mapperFrom
        mapperTo
        operatorOnMapperTo
      }
    }
  }
`;

export const ENTITY_PROPERTY_DATA_QUERY = gql`
  query EntityPropertyDataQuery($entityType: EntityTypeEnum!) {
   tableMapper(entityType: $entityType ) {
     mappingName
     id: entityType
     tableColumns {
       id: columnName
       dataType 
       isHeadingKey
       heading
       columnName
       dataType
       supportedCriteriaTypes
       service
     }
     tableName
       ...TableMapperFragment
  }
  }
  ${TableMapper_FRAGMENT}
 `;

