import React from 'react';
import { Form } from 'antd';
import { FormItemProps } from 'antd/es/form/FormItem';
import {
  SearchConditionInput,
  SearchConditionInputConfigurationInput,
  SearchGroupInput,
  SearchInput, SearchPathInput
} from '../../../../gql/typings';

export type SearchInputKeyName = keyof SearchInput
| keyof SearchGroupInput
| keyof SearchConditionInput
| keyof SearchConditionInputConfigurationInput
| keyof SearchPathInput
| number;

interface SearchFormItemProps extends FormItemProps {
  name: SearchInputKeyName|SearchInputKeyName[];
}
/* interface SearchFormListProps extends FormListProps {
  name: SearchInputKeyName;
} */

export const SearchFormItem: React.FC<SearchFormItemProps> = ({ children, ...rest }) => (
  <Form.Item {...rest}>{children}</Form.Item>
);

/* export const SearchFormList: React.FC<SearchFormListProps> = ({ children, ...rest }) => (
  <Form.List {...rest}>{children}</Form.List>);


export const useSearchFormEffect = (
  form: FormInstance,
  namePath: SearchInputKeyName|SearchInputKeyName[],
  // eslint-disable-next-line
  block: (value: any) => void,
): void => {
  const value = Form.useWatch(namePath, form);
  useUpdateEffect(() => { block(value); }, [value]);
}; */
