import React from 'react';
import { Col, Input, Row, Tooltip } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { WarningOutlined } from '@ant-design/icons';
import CriteriaInputTypes from './CriteriaInputTypes';
import { CriteriaInputProps } from './CriteriaInputTools';
import { Optional } from '../../util/StateArrayType';
import { useLocalization } from '../../util/useLocalization';
import { CriteriaInputFragmentFragment } from '../../../gql/typings';

/**
 * When retrieving values from somewhere, and they have the key prefixed with this value,
 * validates that it is generated via the [criteriaInputKeyGenerator].
 * @type {string}
 * @link criteriaInputKeyGenerator
 */
export const criteriaInputKey = 'ci';

/**
 *
 * @param {{id: number}} criteria
 * @param {{id: number}} inputPath
 * @return {string}
 */
export const criteriaInputKeyGenerator = (criteria: { id: number }, inputPath: { id: number }) => (
  `${criteriaInputKey}${criteria.id}-${inputPath.id}`
);

type CriteriaInputFactoryProps = {
  criteria: CriteriaInputFragmentFragment;
  form: FormInstance;
  countryCodes?: Optional<string[]>;
  options?: {
    hasMaxWidth?: boolean;
    disabled?: boolean;
    tooltipError?: string;
    icon?: React.ReactNode;
    hideTitle?: true;
    transparentBackground?: boolean;
  };
  initialValueOptionsLoad?: boolean;
};

export const CriteriaInputFactory: React.FC<CriteriaInputFactoryProps> = ({
  criteria,
  form,
  countryCodes,
  options: {
    hasMaxWidth = true,
    disabled = false,
    icon = null,
    hideTitle,
    tooltipError,
    transparentBackground
  } = {},
  initialValueOptionsLoad
}) => {
  const localization = useLocalization();

  const label = localization.formatMessageByStr(criteria.heading);


  const inputs = criteria.inputPaths.nodes.map(inputPath => {
    const props: CriteriaInputProps = {
      form,
      criteria,
      inputPath,
      disabled: !!disabled,
      countryCodes,
      disableMaxCount: !hasMaxWidth,
      isDisallowed: !!tooltipError,
      initialValueOptionsLoad
    };
    const groupCode = criteria.criteriaGroupCode;
    // @ts-ignore
    const Elm = CriteriaInputTypes[inputPath.type!.type];
    return (Elm ? <Col
      span={24}
      key={inputPath.id}
      className={`input ${groupCode}`}
      data-criteria-type={inputPath.type!.type}
    ><Elm {...props} key={inputPath.id} /></Col>
      : <span key={inputPath.id}>'{inputPath.type!.type}' isn't supported!</span>
    );
  });

  const style: { display: string; maxWidth?: number } = { display: 'grid' };
  if (hasMaxWidth) style.maxWidth = 220;

  return (
    <Input.Group className={`criteria-input-container ${!transparentBackground ? 'contrast-label' : ''}`} style={style}>
      {!hideTitle && <span className="label">
        {label}
        {tooltipError && (
          <Tooltip title={tooltipError}>
            &nbsp;<WarningOutlined style={{ color: 'red' }} />
          </Tooltip>
        )}
      </span>}
      <Row gutter={[4, 4]}>
        <Col span={icon ? 22 : 24}>
          {inputs}
        </Col>
        {icon && <Col style={{ display: 'flex', alignItems: 'center' }}>{icon}</Col>}
      </Row>
    </Input.Group>
  );
};

