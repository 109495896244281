import { Form, Select, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import React, { ReactNode } from 'react';
import SearchConditionGroup from './SearchConditionGroup';
import { SearchGroupInput } from '../../../../gql/typings';
import { SearchInputKeyName } from './SearchFormItem';
import { searchElementEntityOptions } from './options';
import { useLocalization } from '../../../util/useLocalization';
import { Locale } from '../../../../localization/LocalizationKeys';

type SearchConditionGroupInputType = {
  children?: ReactNode;
  inputPath: SearchInputKeyName[];
  valuePath: SearchInputKeyName[];
  nest?: boolean;
};
const SearchConditionGroupInput:React.FC<SearchConditionGroupInputType> = ({
  inputPath,
  valuePath,
  nest = false,
  children = <></>
}) => {
  const { formatMessage } = useLocalization();
  return (
    <div className="search-condition-builder-container">
      <Form.Item name={[...inputPath, 'entityType']} hidden>
        <Select
          placeholder={formatMessage(Locale.Command.Select_Entity_Type)}
          options={searchElementEntityOptions}
          size='small'
        />
      </Form.Item>
      <Form.List name={[...inputPath, 'groups']}>
        {(groups, { add, remove }) => (
          <>
            {
              groups.map(({ key, name, ...restField }, index) => <SearchConditionGroup
                key={key}
                inputPaths={[name]}
                valuePath={[...valuePath, 'groups', name]}
                {...restField}
                hasNextOperator={index !== 0 && groups.length !== index-1}
                removeGroup={() => remove(index)}
                nest={nest}
              />)
            }
            <div
              className="new-group-add-button-container"
              onClick={() => add(
                {
                  conditions: [{}],
                  nextOperation: null,
                } as SearchGroupInput
              )}
            >
              <div className="add-button">
                <PlusOutlined />
              </div>
              <Typography>New Group</Typography>
            </div>
          </>
        )}
      </Form.List>
      {children}
    </div>
  );
};
export default SearchConditionGroupInput;
