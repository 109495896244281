import React from 'react';
import DownloadBadge from '../Components/DownloadBadge/DownloadBadge';
import { personFieldConfig } from './utils/personFieldsUtils';
import { Locale } from '../../../../localization/LocalizationKeys';

export const personCherryPickFieldConfig = personFieldConfig(
  'cherryPick',
  [
    'id',
    'countryCode',
    'fullname',
    'isLocal',
  ],
  ({ cherryPickSupport, localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Cherry_pick),
    additionalTableConfig: {
      width: 100
    },
    render: (record) => !cherryPickSupport ? <></> : (
      <DownloadBadge
        type="PERSON"
        entity={record}
        name={record.fullname}
        visible={!record.isLocal}
      />
    ),
  })
);

