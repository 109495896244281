import { useMemo } from 'react';
import { isUndefined } from 'lodash';
import criteriaBuilder from '../../browse/search_old/types/criteriaBuilder';
import { ActiveEnum, SaveSearchInput } from '../../../gql/typings';
import { isCountrySearchSupportedForEntity } from './top/EntitiesSearchTitleAndCountries';
import { SortingStorage } from './results/useTableColumns';
import { EntitiesSearchProps } from './index';
import { StateArray } from '../../util/StateArrayType';
import { FlagCountry } from '../Flag/Flag';


type Args = {
  searchInput?: SaveSearchInput;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formValues: any;
  ordering: SortingStorage[];
  pageState: StateArray<number>;
  perPage: number;
  selectedCountriesState: StateArray<FlagCountry[]|undefined>;
  globalState: EntitiesSearchProps['globalState'];
  includeInactiveRecords: EntitiesSearchProps['includeInactiveRecords'];
  includeUnplacedRecords: EntitiesSearchProps['includeUnplacedRecords'];
  includeOptedOutRecords: EntitiesSearchProps['includeOptedOutRecords'];
  entityType: EntitiesSearchProps['entityType'];
  criteriaFilter: EntitiesSearchProps['criteriaFilter'];
  onlyCherryPicked: EntitiesSearchProps['onlyCherryPicked'];
};

export const useCriteria = ({
  searchInput,
  formValues,
  ordering,
  pageState,
  perPage,
  globalState,
  includeInactiveRecords,
  includeUnplacedRecords,
  includeOptedOutRecords,
  selectedCountriesState,
  entityType,
  criteriaFilter,
  onlyCherryPicked,
}: Args) => {
  const [page] = pageState;
  const [isGlobalSearch] = globalState || [false];
  const [isIncludeInactivateRecords] = includeInactiveRecords || [undefined];
  const [isIncludeUnplacedRecords] = includeUnplacedRecords || [undefined];
  const [isIncludeOptedOutRecords] = includeOptedOutRecords || [undefined];
  const [selectedCountries] = selectedCountriesState || [undefined];
  const [onlyCherryPickedRecords] = onlyCherryPicked || [undefined];

  return useMemo(() => criteriaBuilder({
    searchInput,
    searchFilter: formValues,
    orderingNew: ordering.map(e => e.currentEnum),
    page,
    perPage,
    searchGlobal: isGlobalSearch,
    isActive: (!isUndefined(isIncludeInactivateRecords)
      ? (isIncludeInactivateRecords ? ActiveEnum.BOTH : ActiveEnum.ACTIVE)
      : undefined),
    includeUnplaced: !isUndefined(isIncludeUnplacedRecords) ? isIncludeUnplacedRecords : undefined,
    includeOptedOut: !isUndefined(isIncludeOptedOutRecords) ? isIncludeOptedOutRecords : undefined,
    hiddenFilter: criteriaFilter,
    countries: isCountrySearchSupportedForEntity(entityType) ? selectedCountries : undefined,
    onlyCherryPicked: !isUndefined(onlyCherryPickedRecords) ? onlyCherryPickedRecords : undefined,
  }), [
    formValues,
    ordering,
    page,
    perPage,
    isGlobalSearch,
    isIncludeInactivateRecords,
    isIncludeUnplacedRecords,
    isIncludeOptedOutRecords,
    criteriaFilter,
    entityType,
    selectedCountries,
    searchInput,
    onlyCherryPickedRecords
  ]);
};
