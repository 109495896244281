import { has } from 'lodash';
import { SearchGroupInput, SearchInput, SearchPathNode } from '../../../../gql/typings';

export const formatGroupCondition = (groups: SearchGroupInput[]) => groups.map(grp => ({ ...grp,
  conditions: grp.conditions.map(cond => {
    let formattedCond = { ...cond };
    if (has(cond, 'nest') && cond.nest) {
      formattedCond = { ...formattedCond, nest: formatAdvanceSearch(cond.nest) };
    }
    if (has(cond, 'paths') && cond.paths && cond.paths.length > 0) {
      const pathArrayMap = cond.paths.map(cPath => {
        const pathAsString = JSON.stringify(cPath);
        const parsedObj = JSON.parse(JSON.parse(pathAsString));
        return parsedObj.paths.map((path: SearchPathNode) => ({
          ...(path.mapper ? { mapper: path.mapper }:{}),
          ...(path.mapperFrom ? { mapperFrom: path.mapperFrom }:{}),
          ...(path.mapperTo ? { mapperTo: path.mapperTo }:{}),
          ...(path.operator ? { operator: path.operator }:{})
        }));
      }).flat(Infinity);
      formattedCond = { ...formattedCond,
        /* inputConfiguration: { ...formattedCond.inputConfiguration, dataType: parsedObj.dataType } */
        paths: pathArrayMap };
    }
    return formattedCond;
  }) }));

export const formatAdvanceSearch = (formValues: SearchInput): SearchInput => {
  const formattedGroups = formatGroupCondition(formValues.groups);
  return { ...formValues, groups: formattedGroups };
};
