import React, { useState } from 'react';
import { DocumentNode } from '@apollo/client';
import { InputNumber, Spin } from 'antd';
import { get } from 'lodash';
import { Optional } from '../../util/StateArrayType';
import { usePersonFieldMutation } from '../../browse/person/Components/attributeFields/AttributeFields/usePersonFieldMutation';
import { TableFieldUpdateViewProps } from '../../browse/search_old/types';
import { ObjectToDotted } from './fieldConfigTypes';

interface RecordType {
  id: number;
  controlSetting?: Optional<{ id: number; createDcr: boolean }>;
}

type NumberUpdateFieldArgs<T extends RecordType, V> = {
  mutation: DocumentNode;
  valuePath: ObjectToDotted<T, number>;
  buildVariables: (value: Optional<number>, record: T) => V;
};

export function buildNumberUpdateFieldConfig<T extends RecordType, V>(
  args: NumberUpdateFieldArgs<T, V>,
) {
  return (props: TableFieldUpdateViewProps<T>) => {
    const [number, setNumber] = useState(get(props.record, args.valuePath) as number|null);
    const { Renderer, submit, blocking } = usePersonFieldMutation<unknown, V>({
      mutation: args.mutation,
      controlSetting: props.record.controlSetting,
      endEditing: props.endEditing
    });

    const onEndEditing = () => !blocking && props.endEditing();
    const onSaveChange = () => !blocking && submit(args.buildVariables(number, props.record)).then(onEndEditing);

    return (
      <Renderer>
        <div style={{ position: 'relative' }}>
          <InputNumber
            value={number}
            onPressEnter={onSaveChange}
            onBlur={onSaveChange}
            autoFocus
            style={{ width: '100%' }}
            onChange={setNumber}
          />
          {blocking && <Spin size='small' style={{ position: 'absolute', top: '8px', right: '5px' }} />}
        </div>
      </Renderer>
    );
  };
}
