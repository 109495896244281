import React from 'react';
import { gql } from '@apollo/client';
import AdminMergeItemBasicConflictField from '../../components/basicConflictField/AdminMergeItemBasicConflictField';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import {
  MergeItemSiteParentFragment,
  useUpdateSiteParentMutationMutation
} from '../../../../../../../../../gql/typings';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';


const AdminMergeItemSiteParent: React.FC<AdminEntityMergeRequestItemProps> = (props) => {
  const info = props.item.type as MergeItemSiteParentFragment;
  const localization = useLocalization();
  const [updateParent] = useUpdateSiteParentMutationMutation();

  return (
    <AdminMergeItemBasicConflictField
      {...props}
      fromValue={info.fromParentSite?.name}
      toValue={info.toParentSite?.name}
      updateMutation={newValue => updateParent({
        variables: {
          siteId: props.into.id,
          parentId: +newValue,
        },
      })}
    >
      {localization.formatMessage(Locale.Text.Merge_request_site_parent_mismatch_description, {
        siteParent1: <strong>{info.fromParentSite?.name}</strong>,
        siteParent2: <strong>{info.toParentSite?.name}</strong>,
      })}
    </AdminMergeItemBasicConflictField>
  );
};

gql`
  mutation UpdateSiteParent($siteId: Int!, $newValue: Int!) {
    updateSiteParentId(siteId: $siteId, parentId: $newValue) {
      id
      parentId
      parentSite {
         id
        name
      }
    }
  }
`;

gql`
  fragment MergeItemSiteParent on SiteParent_EntityDataToBeTransferredType {
    code
    fromParentSite {
      id
      name
    }
    toParentSite {
      id
      name
    }
  }
`;

export default AdminMergeItemSiteParent;
