import { Button, Form, Input, InputNumber, Popover, Space, Tabs, Typography } from 'antd';
import React, { useState } from 'react';
import { useForm } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { Locale } from '../../../localization/LocalizationKeys';
import { useLocalization } from '../../util/useLocalization';
import {
  generateDisplayString,
  getInitialValue, keyToOperationMapping,
  operationMapping,
  RangePickerForm,
  RangePickerInputProps,
  TabKey
} from './helper';


const RangePicker: React.FC<RangePickerInputProps> = ({
  disabled,
  valuePath,
  name: inputNamePath
}) => {
  const mainForm = useFormInstance();
  const selectedKey = mainForm.getFieldValue([...valuePath, inputNamePath[0]]) || '';
  const initialValue = getInitialValue(mainForm, valuePath, inputNamePath);
  const mappedKey = keyToOperationMapping[selectedKey as keyof typeof keyToOperationMapping] as TabKey;
  const [activeKey, setActiveKey] = useState<TabKey>(mappedKey || 'between');
  const [clicked, setClicked] = useState<boolean>(false);
  const [selectedRangePicker, setSelectedRangePicker] = useState<string | undefined>(
    generateDisplayString(initialValue, activeKey)
  );
  const [form] = useForm<RangePickerForm>();
  const localization = useLocalization();

  const onSaveRangePicker = () => {
    const formVal = form.getFieldsValue(true);
    let rangeValue;
    if (activeKey === 'between') {
      rangeValue = `${formVal.betweenFrom}..${formVal.betweenTo}`;
    } else {
      rangeValue = formVal[activeKey];
    }
    mainForm.setFieldValue([...valuePath!, 'operation'], operationMapping[activeKey]);
    mainForm.setFieldValue([...valuePath!, 'value'], rangeValue);
    setSelectedRangePicker(generateDisplayString(form.getFieldsValue(true), activeKey));
    setClicked(false);
  };

  const tabOptions = [
    {
      key: 'moreThan',
      label: 'More Than',
      children: (
        <div>
          <Form.Item name='moreThan'>
            <InputNumber id='moreThan' size='small' style={{ width: '100%' }} />
          </Form.Item>
        </div>
      )
    },
    {
      key: 'lessThan',
      label: 'Less Than',
      children: (
        <div>
          <Form.Item name='lessThan'>
            <InputNumber id='lessThan' size='small' style={{ width: '100%' }} />
          </Form.Item>
        </div>
      )
    },
    {
      key: 'between',
      label: 'Between',
      children: (
        <div className='flex-center-space-between'>
          <div className='flex-center-inline'>
            <Typography.Text>From:</Typography.Text>
            <Form.Item name='betweenFrom'>
              <InputNumber id='from' size='small' />
            </Form.Item>
          </div>
          <div className='flex-center-inline'>
            <Typography.Text>To:</Typography.Text>
            <Form.Item name='betweenTo'>
              <InputNumber id='to' size='small' />
            </Form.Item>
          </div>
        </div>
      )
    },
    {
      key: 'exclude',
      label: 'Exclude',
      children: (
        <div>
          <Form.Item name='exclude'>
            <InputNumber id='dd' size='small' style={{ width: '100%' }} />
          </Form.Item>
        </div>
      )
    }
  ];

  return (
    <>
      <Form form={form} initialValues={initialValue}>
        <Popover
          trigger="click"
          placement='bottomLeft'
          overlayClassName='range-picker-popover'
          arrow={false}
          open={clicked && !disabled}
          onOpenChange={setClicked}
          content={
            <>
              <Tabs
                activeKey={activeKey}
                centered
                size='small'
                items={tabOptions}
                onChange={value => setActiveKey(value as TabKey)}
              />
              <Space style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                <Button type="primary" onClick={onSaveRangePicker}>
                  {localization.formatMessage(Locale.General.Ok)}
                </Button>
              </Space>
            </>
          }
        >
          <Form.Item style={{ maxWidth: '200px' }} shouldUpdate={() => true}>
            {() => <Input
              size='small'
              value={selectedRangePicker}
              onClick={() => !disabled && setClicked(true)}
              placeholder='Select Range'
              disabled={disabled}
            />}
          </Form.Item>
        </Popover>
      </Form>
    </>
  );
};
export default RangePicker;
