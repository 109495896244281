import { useQuery, gql } from '@apollo/client';
// eslint-disable graphql/template-strings
import React from 'react';
import { useIntl } from 'react-intl';
import { Collapse, Timeline, Result } from 'antd';
import { NavLink } from 'react-router-dom';
import { Dictionary } from 'lodash';
import * as _ from 'lodash/fp';
import Markdown from 'react-markdown';
import { Locale } from '../../../../localization/LocalizationKeys';
import {
  EntityTypeEnum,
  FinishedImportProjectDetailsQueryQuery,
  FinishedImportProjectDetailsQueryQueryVariables, ProcessLineEffectActionEnum
} from '../../../../gql/typings';

type EffectRecord = NonNullable<FinishedImportProjectDetailsQueryQuery['importProject']>['effects']['nodes']['0'];
type EffectTypedRecord<
  KEY_TYPE extends NonNullable<EffectRecord['affiliationEntity']>['__typename']
> = Extract<NonNullable<EffectRecord['affiliationEntity']>, { ['__typename']: KEY_TYPE }>;

type ModelDetailProps = {
  record: EffectRecord;
};

const ModelDetail: React.FC<ModelDetailProps> = ({ record }) => {
  const { entityType, affiliationEntity: entity } = record;

  const link = (url: string, label: string, note = record.note) => (
    <div>
      <NavLink to={url}>{label}</NavLink>
      {note && <Markdown>{note}</Markdown>}
    </div>
  );

  if (entity === null) return <span />;


  switch (entityType) {
    case EntityTypeEnum.BRAND:
      return link(
        '#',
        (entity as EffectTypedRecord<'Brand'>).name,
      );
    case EntityTypeEnum.PRODUCT:
      return link(
        '#',
        // @ts-ignore
        entity.name,
      );
    case EntityTypeEnum.PROJECT:
      return link(
        `/project/${entity!.id}`,
        (entity as EffectTypedRecord<'Project'>).title,
      );
    case EntityTypeEnum.USER:
      return link(
        `/dashboard/${entity!.id}`,
        (entity as EffectTypedRecord<'User'>).fullname,
      );
    case EntityTypeEnum.PERSON:
      return link(
        `/hcp/${entity!.id}`,
        (entity as EffectTypedRecord<'Person'>).fullname,
      );
    case EntityTypeEnum.SITE:
      return link(
        `/hco/${entity!.id}`,
        (entity as EffectTypedRecord<'Site'>).name,
      );
    case EntityTypeEnum.IMPORT_PROCESS_LINE:
      return link(
        '#',
        `Line Id: ${entity!.id}`,
      );
    case EntityTypeEnum.ACTIVITY:
      return link(
        `/activity/${entity!.id}`,
        (entity as EffectTypedRecord<'Activity'>).title,
      );
    // case EntityTypeEnum.ACTIVITY_PRODUCT_BRAND:
    //   return link(
    //     `/activity/${entity.activity.id}`,
    //     entity.activity.title,
    //     `Linked [${entity.product.heading}](/product/${entity.productBrand.id}) to activity.`,
    //   );
    case EntityTypeEnum.ACTIVITY_PRODUCT:
    type AP = EffectTypedRecord<'ActivityProduct'>;
      return link(
        `/activity/${(entity as AP).activity.id}`,
        (entity as AP).activity.title,
        `Linked [${(entity as AP).product?.heading}](/product/${(entity as AP).product?.id}) to activity`,
      );
    case EntityTypeEnum.CUSTOM_VALUE:
      return <span>{record.note ?? ''}</span>;
    default:
      return <span>no ui defined for entityType {entityType}</span>;
  }
};

const FinishedDetails: React.FC<{ projectId: number }> = ({
  projectId,
}) => {
  const intl = useIntl();
  const {
    data: importProject,
    loading,
  } = useQuery<FinishedImportProjectDetailsQueryQuery, FinishedImportProjectDetailsQueryQueryVariables>(
    FinishedImportProjectDetails_QUERY,
    { variables: { projectId } },
  );
  if (loading) return <span>Loading...</span>;


  const data: Dictionary<EffectRecord[]> = _.flow(
    _.uniqBy((d: EffectRecord) => (
      `${d?.affiliationEntity?.id ?? ''}${d?.actionType ?? ''}`
    )),
    _.groupBy(d => d.actionType),
  )(importProject?.importProject?.effects.nodes);

  const panels = Object.values(ProcessLineEffectActionEnum).map(group => data[group] && {
    group: ProcessLineEffectActionEnum[group]!,
    data: data[group]!,
  }).filter(a => a) as { group: ProcessLineEffectActionEnum; data: EffectRecord[] }[];

  return (
    <div>
      <Result
        status="success"
        title={intl.formatMessage(Locale.Attribute.Import_complete)}
        subTitle={intl.formatMessage(Locale.Text.Import_project, { value: importProject?.importProject?.id })}
      />
      <h2>Changes</h2>
      {!panels.length ? <p>No changes made</p>
        : <Collapse className="import-completed">
          {panels.map(panel => (
            <Collapse.Panel key={panel.group} header={`${panel.group} (${panel.data.length})`}>
              <Timeline items={panel.data.map(record => (
                {
                  key: record.id,
                  children: <ModelDetail record={record} />
                }
              ))}
              />
            </Collapse.Panel>
          ))}
        </Collapse>}
    </div>
  );
};

const FinishedImportProjectDetails_QUERY = gql`
  query FinishedImportProjectDetailsQuery($projectId: Int!) {
    importProject(id: $projectId) {
      id
      effects {
        hash
        nodes {
          id
          actionType
          entityType
          note
          affiliationEntity {
            id

            ... on ActivityBrand {
              activity { id, title: heading }
              productBrand { id, name }
            }
            ... on Activity { title: heading }
            ... on Brand { id, name }
            ... on Project { id, title }
            ... on User { id, fullname }
            ... on Person { id, fullname: fullName }
            ... on Site { id, name }
            ... on ActivityProduct {
              id
              activity {
                id
                title: heading
              }
              product {
                id
                heading
              }
            }
            ... on CustomValue {
              id
              value
            }
          }
        }
      }
    }
  }
`;

export default FinishedDetails;
