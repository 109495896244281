/* eslint-disable no-console,react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { keys } from 'lodash';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { gql, useApolloClient, useQuery } from '@apollo/client';
import { criteriaInputKeyGenerator } from '../CriteriaInput/CriteriaInputFactory';
import EntitiesSearchContext from './EntitiesSearchContext';
import { generateCriteriaInputValue } from '../CriteriaInput/CriteriaInputTools';
import { AnyValueType } from '../../util/StateArrayType';
import { DEFAULT_CRITERIAS_DATA_QUERY, EntitiesSearchProps } from './index';
import { Locale } from '../../../localization/LocalizationKeys';
import { FlagCountry } from '../Flag/Flag';
import { isCountrySearchSupportedForEntity } from './top/EntitiesSearchTitleAndCountries';
import { useLocalization } from '../../util/useLocalization';
import { CriteriaInputFactory_FRAGMENT } from '../CriteriaInput/CriteriaInputQueries';
import {
  DefaultCriteriasQueryQuery, DefaultCriteriasQueryQueryVariables,
  LoadCriteriasByCodesQueryQuery,
  LoadCriteriasByCodesQueryQueryVariables, LoadDefaultUserCountriesQuery
} from '../../../gql/typings';


const EntitiesSearchInitialization: React.FC<Pick<
EntitiesSearchProps,
'updateForRefresh' | 'onDataChange' | 'syncChanges'>> = ({
  onDataChange,
  updateForRefresh,
  syncChanges
}) => {
  const { search } = useLocation();
  const apollo = useApolloClient();
  const localization = useLocalization();
  const {
    entityType,
    defaultCriterias,
    form,
    setDisabledCriteriaIds,
    selectedCriterias,
    setSelectedCriterias,
    doSearch,
    urlSearchEnabled,
    globalState,
    data: tableData,
    selectedCountriesState: [selectedCountries, setSelectedCountries],
  } = useContext(EntitiesSearchContext);

  // Criteria Codes that should be displayed, but are not the default ones.
  // Example reasons can be URL search, or a component is using the lockedCriterias functionality.
  const codes = [
    ...(defaultCriterias ?? []).map(lc => lc.criteriaCode),
    ...keys(queryString.parseUrl(search).query).map(k => k.split('*')[0]!),
  ];
  const {
    data,
    networkStatus,
    refetch,
  } = useQuery<LoadCriteriasByCodesQueryQuery, LoadCriteriasByCodesQueryQueryVariables>(LOAD_CRITERIAS_BY_CODE, {
    skip: codes.length === 0,
    notifyOnNetworkStatusChange: true,
    // fetchPolicy: 'network-only',
    variables: {
      entityType,
      codes,
    },
  });

  useEffect(() => {
    if (urlSearchEnabled) {
      // TODO: Should be possible to clean the search by navigating to /{entity}/
      if (entityType && codes && codes.length > 0) refetch({
        entityType,
        codes,
      }).then();
    }
  }, [urlSearchEnabled]);

  useEffect(() => {
    if (urlSearchEnabled && !search) {
      form.resetFields();
      const { defaultCriterias } = apollo.readQuery<DefaultCriteriasQueryQuery, DefaultCriteriasQueryQueryVariables>({
        query: DEFAULT_CRITERIAS_DATA_QUERY,
        variables: { entityType },
      }) ?? { defaultCriterias: null };
      setSelectedCriterias(defaultCriterias?.nodes?.map(dc => dc.criteria) ?? []);
      if (globalState && globalState[1]) globalState[1](false);
      doSearch('EntitiesSearchInitialization:useEffect:search');
    }
  }, [search]);

  useEffect(() => {
    // this should run only after selectedCriterias is set
    if (data && selectedCriterias.length > 0) {
      const toDisable: number[] = [];
      const missingSelectedCriterias: NonNullable<DefaultCriteriasQueryQuery[
        'defaultCriterias']>['nodes']['0']['criteria'][] = [];
      const urlValues = queryString.parseUrl(search).query;

      const lockedCriteriasMap: Record<string, {
        criteriaCode: string;
        value: AnyValueType;
        disabled?: boolean;
      }> = (defaultCriterias ?? []).reduce((acc, curr) => ({
        ...acc,
        [curr.criteriaCode]: curr,
      }), {});

      // Default form values to the ones from URL
      const formValues: Record<string, AnyValueType> = data.criterias.nodes.reduce((acc, node) => ({
        ...acc,
        ...node.inputPaths.nodes.reduce((acc, input, index) => {
          const key = criteriaInputKeyGenerator(node, input);
          if (urlValues[`${node.code}*${index + 1}`]) return {
            ...acc,
            [key]: generateCriteriaInputValue(input, urlValues[`${node.code}*${index + 1}`], localization),
          };
          return {
            ...acc,
            [key]: generateCriteriaInputValue(input, urlValues[node.code!], localization),
          };
        }, {})
      }), {});

      data.criterias.nodes.map(criteria => {
        if (criteria.inputPaths.nodes.length > 1) {
          console.error(localization.formatMessage(Locale.Text.Locking_with_miltiple_input_values));
        }
        const path = criteria.inputPaths.nodes[0]!;

        const lockedCriteria = lockedCriteriasMap[criteria.code!];
        if (lockedCriteria && lockedCriteria.disabled) toDisable.push(criteria.id);

        if (defaultCriterias && criteria.code) {
          formValues[criteriaInputKeyGenerator(criteria, path)] = generateCriteriaInputValue(
            path,
            lockedCriteria?.value,
            localization,
          );
        }
        if (selectedCriterias && !selectedCriterias.find(c => c.id === criteria.id)) {
          missingSelectedCriterias.push(criteria);
        }
      });
      form.setFieldsValue(formValues);
      setDisabledCriteriaIds?.(toDisable);
      setSelectedCriterias([
        ...selectedCriterias,
        ...missingSelectedCriterias,
      ]);

      /**
       * MARK:
       * We're wrapping this inside a Promise,
       * just to be sure that doSearch will be run in the next javascript event loop.
       */
      Promise.resolve(form).then(() => doSearch(
        'EntitiesSearchInitialization:useEffect:[data, networkStatus, defaultCriterias]',
      ));
    }
  }, [data, networkStatus, defaultCriterias, syncChanges]);

  useEffect(() => {
    if (!selectedCountries && isCountrySearchSupportedForEntity(entityType)) apollo.query<LoadDefaultUserCountriesQuery>({
      query: LOAD_DEFAULT_COUNTRIES,
    }).then(res => {
      if ((res.data.viewer?.user.countries.totalCount ?? 0) > 0) {
        setSelectedCountries(res.data.viewer?.user.countries.nodes.map(c => c.code as FlagCountry));
      } else {
        setSelectedCountries(res.data.countries?.nodes.map(c => c.code as FlagCountry));
      }
    });
  }, [selectedCountries]);

  useEffect(() => {
    if (!networkStatus) doSearch('EntitiesSearchInitialization:useEffect:updateForRefresh');
  }, [updateForRefresh]);

  useEffect(() => {
    if (onDataChange) {
      onDataChange(tableData?.data?.connection);
    }
  }, [onDataChange, tableData]);

  return <></>;
};

export const LOAD_DEFAULT_COUNTRIES = gql`
  query LoadDefaultUserCountries {
    viewer {
      id
      user {
        id
        countries {
          hash
          totalCount
          nodes {
            code
          }
        }
      }
    }
    countries {
      hash
      nodes {
        code
      }
    }
  }
`;

const LOAD_CRITERIAS_BY_CODE = gql`
  query LoadCriteriasByCodesQuery($entityType: EntityTypeEnum!, $codes: [String!]!) {
    criterias(criteria: { entityType: $entityType, codes: $codes }) {
      hash
      nodes {
        id
        code
        ...CriteriaInputFragment
      }
    }
  }
  ${CriteriaInputFactory_FRAGMENT}
`;

export default EntitiesSearchInitialization;
