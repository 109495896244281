import React, { CSSProperties, useEffect } from 'react';
import { FormInstance } from 'antd/lib/form/Form';
import { Form, Input, Select } from 'antd';
import { gql, useApolloClient, useLazyQuery, useQuery } from '@apollo/client';
import { usePrevious, useThrottleEffect } from 'ahooks';
import { useLocalization } from '../../util/useLocalization';
import { Locale } from '../../../localization/LocalizationKeys';
import {
  AddressFormDataQueryQuery,
  AddressInput, CommuneCodeQueryQuery, CommuneCodeQueryQueryVariables,
  CountycodeQueryQuery, CountycodeQueryQueryVariables,
  PostcodeQueryQuery,
  PostcodeQueryQueryVariables
} from '../../../gql/typings';
import { Optional } from '../../util/StateArrayType';
import { useSystemCountriesState } from '../../util/useSystemCountriesState';


type AddressFormProps = {
  form: FormInstance<AddressInput>;
  style?: CSSProperties;
  disabled?: boolean;
};

const AddressForm: React.FC<AddressFormProps> = ({ form, style, disabled }) => {
  const localization = useLocalization();
  const [selectedCountries] = useSystemCountriesState();
  const { data, loading } = useQuery<AddressFormDataQueryQuery>(DATA_QUERY);
  const [loadCounties, { data: countyData, loading: countyLoading }] = useLazyQuery<CountycodeQueryQuery,
  CountycodeQueryQueryVariables>(COUNTYCODE_QUERY);
  const [loadCommunes, { data: communeData, loading: communeLoading }] = useLazyQuery<CommuneCodeQueryQuery,
  CommuneCodeQueryQueryVariables>(COMMUNECODE_QUERY);
  const postInput = Form.useWatch('postalCode', { form, preserve: true });
  const postPreviousInput = usePrevious(postInput);
  const countryInput = Form.useWatch('countryCode', { form, preserve: true });
  const countryPreviousInput = usePrevious(countryInput);
  const apolloClient = useApolloClient();

  function getPostcode(postcode: Optional<string>, countryCode: Optional<string>) {
    if (postcode && countryCode) {
      apolloClient.query<PostcodeQueryQuery, PostcodeQueryQueryVariables>({
        query: POSTCODE_QUERY,
        variables: { postcode, country: countryCode }
      }).then(res => {
        form.setFieldValue('city', res.data.postcode?.city);
        form.setFieldValue('countyCode', res.data.postcode?.regionCode);
        form.setFieldValue('communeCode', res.data.postcode?.countyCode);
      });
    }
  }

  function getCountyCodes(countryCode: Optional<string>) {
    if (countryCode) {
      loadCounties({
        variables: { country: [countryCode] }
      });
    }
  }

  function getCommuneCodes(countryCode: Optional<string>) {
    if (countryCode) {
      loadCommunes({
        variables: { country: [countryCode] }
      });
    }
  }

  useEffect(() => {
    if (countryInput) {
      getCountyCodes(countryInput);
      getCommuneCodes(countryInput);
    } else {
      const defaultCountry = selectedCountries && selectedCountries?.length > 0 ? selectedCountries[0] : undefined;
      form.setFieldValue('countryCode', defaultCountry);
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [countryInput, form]);

  useThrottleEffect(
    () => {
      // for pasting events, postPrevInput is undefined but postInput is defined
      if ((postPreviousInput && postPreviousInput !== postInput)) {
        getPostcode(postInput, countryInput);
      }
    },
    [postInput],
    {
      wait: 300
    },
  );


  useEffect(
    () => {
      if (countryPreviousInput && countryPreviousInput != countryInput) {
        form.setFieldValue('postalCode', '');
        form.setFieldValue('city', '');
        form.setFieldValue('countyCode', '');
        form.setFieldValue('communeCode', '');
      }
    },
    [countryInput, form, countryPreviousInput]
  );

  return (
    <Form
      form={form}
      labelAlign="left"
      labelCol={{ span: 5 }}
      wrapperCol={{ span: 19 }}
      style={style}
      disabled={disabled}
    >
      <Form.Item
        name="countryCode"
        label={localization.formatMessage(Locale.Attribute.Country)}
      >
        <Select
          showSearch
          style={{ width: '100%' }}
          loading={loading}
        >
          {data?.countries.nodes.map(it => (
            <Select.Option key={it.code} value={it.code}>
              {it.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name={'street' as keyof AddressInput}
        label={localization.formatMessage(Locale.Attribute.Street)}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={'addressLine2' as keyof AddressInput}
        label={localization.formatMessage(Locale.Attribute.Address_line_2)}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={'postalCode' as keyof AddressInput}
        label={localization.formatMessage(Locale.Attribute.Postal_code)}
      >
        <Input
          onPaste={e => {
            const postInputFromPaste = e.clipboardData.getData('Text');
            getPostcode(postInputFromPaste, countryInput);
          }}
        />
      </Form.Item>

      <Form.Item
        name={'city' as keyof AddressInput}
        label={localization.formatMessage(Locale.Attribute.City)}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={'communeCode' as keyof AddressInput}
        label={localization.formatMessage(Locale.Attribute.Commune)}
      >
        <Select
          showSearch
          allowClear
          filterOption={(input, option) => (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())}
          style={{ width: '100%' }}
          loading={communeLoading}
        >
          {communeData?.communes.nodes.map(it => (
            <Select.Option key={it.code} value={it.code}>
              {it.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name={'countyCode' as keyof AddressInput}
        label={localization.formatMessage(Locale.Attribute.County)}
      >
        <Select
          showSearch
          allowClear
          filterOption={(input, option) => (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())}
          style={{ width: '100%' }}
          loading={countyLoading}
        >
          {countyData?.counties.nodes.map(it => (
            <Select.Option key={it.code} value={it.code}>
              {it.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

    </Form>
  );
};

const DATA_QUERY = gql`
  query AddressFormDataQuery {
    countries {
      hash
      nodes {
        code
        label
      }
    }
  }
`;

export default AddressForm;

const POSTCODE_QUERY = gql`
  query PostcodeQuery($country: CountryCode!, $postcode: String!) {
    postcode(country: $country, postcode: $postcode) {
      postcode
      city
      region
      regionCode
      county
      countyCode
    }
  }
`;

const COUNTYCODE_QUERY = gql`
query CountycodeQuery($country: [CountryCode!]!) {
  counties(criteria: {countries: $country, fetchSize: { limit: 1000 } }) {
    hash
    nodes {
      code
      type
      label
    }
  }
}`;

const COMMUNECODE_QUERY = gql`
query CommuneCodeQuery($country: [CountryCode!]) {
  communes(criteria: {countries: $country, fetchSize: { limit: 1000 } }) {
    hash
    nodes {
      code
      type
      label
    }
  }
}
`;
