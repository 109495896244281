import { IntlShape } from 'react-intl';
import {
  CriteriaTypeEnum,
  EntityTypeEnum,
  SearchConditionEnum,
  SearchConditionOperationEnum,
  SearchGroupOperationEnum
} from '../../../../gql/typings';
import { Locale } from '../../../../localization/LocalizationKeys';

type FormatMessage = IntlShape['formatMessage'];
export const nextOperationOptions = (formatMessage: FormatMessage) => [
  {
    label: formatMessage(Locale.Command.Union),
    value: SearchGroupOperationEnum.UNION
  },
  {
    label: formatMessage(Locale.Command.Intersection),
    value: SearchGroupOperationEnum.INTERSECT
  }
];
export const searchConditionOptions = (formatMessage: FormatMessage) => [
  {
    label: formatMessage(Locale.Command.Include),
    value: SearchConditionEnum.INCLUDE
  },
  {
    label: formatMessage(Locale.Command.Exclude),
    value: SearchConditionEnum.EXCLUDE
  },
  {
    label: formatMessage(Locale.Command.Count),
    value: SearchConditionEnum.COUNT
  },
/*  {
    label: 'Nest',
    value: SearchConditionEnum.NEST
  } */
];
export const searchElementEntityOptions = [
  {
    label: 'Person',
    value: EntityTypeEnum.PERSON
  },
  {
    label: 'Site',
    value: EntityTypeEnum.SITE
  },
  {
    label: 'Activity',
    value: EntityTypeEnum.ACTIVITY
  }
];

export enum OperatorTypeEnum {
  ByMe = 'ByMe',
  CrossMagic = 'CrossMagic',
  Date = 'Date',
  EqualOrNullWhenFalse = 'EqualOrNullWhenFalse',
  EqualOrNullWhenTrue = 'EqualOrNullWhenTrue',
  Equals = 'Equals',
  EqualsOnJoin = 'EqualsOnJoin',
  EqualsRaw = 'EqualsRaw',
  GreaterOrEqualTo = 'GreaterOrEqualTo',
  GreaterThan = 'GreaterThan',
  In = 'In',
  InOnJoin = 'InOnJoin',
  LessOrEqualTo = 'LessOrEqualTo',
  LessThan = 'LessThan',
  Like = 'Like',
  NotEquals = 'NotEquals',
  NotIn = 'NotIn',
  NotLike = 'NotLike'
}

export const operatorOptions = (isString: boolean) => (
  isString ? ([
    { label: 'Equals', value: SearchConditionOperationEnum.EQUAL },
    { label: 'Like', value: SearchConditionOperationEnum.LIKE },
  ]): ([
    { label: 'Equals', value: SearchConditionOperationEnum.EQUAL },
  ])
);


export const defaultInputOptions = (formatMessage: FormatMessage) => [
  {
    label: formatMessage(Locale.Command.User_input),
    value: 'UserInput'
  },
  /* {
    label: formatMessage(Locale.Command.Custom_input),
    value: 'CustomInput'
  } */
];

export const inputOptionsGenerator = (type: CriteriaTypeEnum, formatMessage: FormatMessage) => {
  let options;
  switch (type) {
    case CriteriaTypeEnum.Date:
      options = [
        ...defaultInputOptions(formatMessage),
        { label: formatMessage(Locale.Command.Date), value: CriteriaTypeEnum.Date }];
      break;
    case CriteriaTypeEnum.Select:
    case CriteriaTypeEnum.MultiSelect:
      options = [
        ...defaultInputOptions(formatMessage),
        { label: formatMessage(Locale.Command.Select), value: CriteriaTypeEnum.Select }];
      break;
    case CriteriaTypeEnum.Switch:
    case CriteriaTypeEnum.UserSwitch:
      options = [
        ...defaultInputOptions(formatMessage),
        { label: formatMessage(Locale.General.Switch), value: CriteriaTypeEnum.Switch }];
      break;

    default:
      options=[
        ...defaultInputOptions(formatMessage),
        { label: formatMessage(Locale.Command.Input), value: CriteriaTypeEnum.Text }];
  }
  return options;
  
};
