import React from 'react';
import { Tabs, TabsProps } from 'antd';
import { useLastUsedSearch } from '../../util/useLastUsedSearch';
import { EntityTypeEnum } from '../../../gql/typings';
import { useURLSearch } from '../../util/Util';

export type SearchTabsProps = {
  defaultActiveKey: string;
  items: TabsProps['items'];
  entityType: EntityTypeEnum;
};
const SearchTabs: React.FC<SearchTabsProps> = ({
  defaultActiveKey,
  items,
  entityType
}) => {
  const { setSearch } = useURLSearch();
  const selectedSearchTab = useLastUsedSearch(entityType);
  const onTabChange = (ev: string) => {
    selectedSearchTab[1](+ev);
    setSearch({});
    
  };
  return (
    <Tabs
      type="card"
      defaultActiveKey={selectedSearchTab[0].toString() || defaultActiveKey}
      items={items}
      className="search-tabs"
      onChange={onTabChange}
    />
  );
};
export default SearchTabs;
