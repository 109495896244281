import React, { useState } from 'react';
import { InputNumber, Spin } from 'antd';
import { getVariableField } from '../../../person/fields/utils/personFieldsUtils';
import { usePersonFieldMutation } from '../../../person/Components/attributeFields/AttributeFields/usePersonFieldMutation';
import { TableFieldUpdateViewProps } from '../../../search_old/types';
import { CUSTOM_UPSERT_MUTATION } from './customFieldUtils';

const CustomNumberInput: React.FC<TableFieldUpdateViewProps> = (props) => {
  const values = getVariableField(props!.record, props!.options, 'customFields');
  const selectedValue = !!values.nodes[0]?.value?.value;
  const val = selectedValue && typeof values.nodes[0]?.value?.value === 'string' ? values.nodes[0]?.value?.value : '';
  const [selected, setSelected] = useState<number | null>(selectedValue ? parseInt(val, 10) : 0);

  const { Renderer, submit, blocking } = usePersonFieldMutation({
    mutation: CUSTOM_UPSERT_MUTATION,
    controlSetting: props.record?.controlSetting,
    skipDcrWarning: true,
  });

  const buildVariable = () => ({
    recordId: props.record.id,
    customFieldCode: props.options.selectedOption!.code,
    customValues: [{ newValue: (selected ?? 0).toString() }]
  });

  const onEndEditing = () => !blocking && props.endEditing();

  const onChange = (newSelected: number | null) => {
    setSelected(newSelected);
  };

  const onSaveChange = () => !blocking && submit(buildVariable()).then(onEndEditing);

  return (
    <Renderer>
      <div style={{ position: 'relative' }}>
        <InputNumber
          value={selected}
          onPressEnter={onSaveChange}
          onBlur={onSaveChange}
          min={1}
          max={10}
          keyboard
          onChange={onChange}
          style={{ width: '100%' }}
        />
        {blocking && <Spin size='small' style={{ position: 'absolute', top: '8px', right: '5px' }} />}
      </div>
    </Renderer>
  );
};

export default CustomNumberInput;
