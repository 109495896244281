import React from 'react';
import { Form, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import SearchConditionElement from './SearchConditionElement';
import { SearchFormItem, SearchInputKeyName } from './SearchFormItem';
import { nextOperationOptions } from './options';
import { useLocalization } from '../../../util/useLocalization';

type SearchConditionGroupProps = {
  inputPaths: SearchInputKeyName[];
  valuePath: SearchInputKeyName[];
  hasNextOperator: boolean;
  removeGroup: () => void;
  nest?: boolean;
};

const SearchConditionGroup: React.FC<SearchConditionGroupProps> = ({
  hasNextOperator,
  inputPaths,
  valuePath,
  removeGroup,
  nest = false
}) => {
  const { formatMessage } = useLocalization();
  return (
    <div className={`search-condition-group-container ${nest ? 'nest-group' : ''}`}>
      {
        hasNextOperator && <div className="next-operation-container">
          <SearchFormItem
            name={[...inputPaths, 'nextOperation']}
            rules={[{
              required: true,
              message: 'Please input a value'
            }]}
          >
            <Select
              placeholder='Select next operation'
              style={{ minWidth: '100px', width: '30%', maxWidth: '300px' }}
              options={nextOperationOptions(formatMessage)}
              size='small'
            />
          </SearchFormItem>
        </div>
      }

      <div className="search-condition-criteria-list-container">
        <Form.List name={[...inputPaths, 'conditions']}>
          {
            (fields, { add, remove }) => (
              <>
                <div className="search-condition-criteria-list">
                  {
                    fields.map(({ key, name, ...restField }, index) => (
                      <SearchConditionElement
                        key={key}
                        inputPaths={[name]}
                        valuePath={[...valuePath, 'conditions', name]}
                        {...restField}
                        isEntitySelect={index === 0}
                        removeElement={() => {
                          if (fields.length === 1) {
                            removeGroup();
                          } else {
                            remove(index);
                          }
                        }}
                      />
                    ))
                  }
                </div>
                <div className="add-button" onClick={() => add()}>
                  <PlusOutlined />
                </div>
              </>
            )
          }
        </Form.List>
      </div>

    </div>

  );
};
export default SearchConditionGroup;
