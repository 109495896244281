import { gql, useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'antd';
import { keys, toNumber } from 'lodash';
import Context from '../../../components/entitiesSearch/EntitiesSearchContext';
import EntitiesSearchDrawer from '../../../components/entitiesSearch/top/drawer/EntitiesSearchDrawer';
import EntitiesSearchInitialization from '../../../components/entitiesSearch/EntitiesSearchInitialization';
import { criteriaInputKeyGenerator } from '../../../components/CriteriaInput/CriteriaInputFactory';
import { dynamicRulesMap } from '../components/DynamicRules';
import { AnyValueType, StateArray } from '../../../util/StateArrayType';
import criteriaBuilder from '../../search_old/types/criteriaBuilder';
import { Locale } from '../../../../localization/LocalizationKeys';
import { generateCriteriaInputValue } from '../../../components/CriteriaInput/CriteriaInputTools';
import SimpleSearchOverview from '../../../components/entitiesSearch/top/drawer/overview/SimpleSearchOverview';
import { SupportedEntitySearchTypes } from '../../search_old/types';
import { FlagCountry } from '../../../components/Flag/Flag';
import FlagSelector from '../../../components/Flag/FlagSelector';
import { useLocalization } from '../../../util/useLocalization';
import { LIST_DYNAMIC_RULES_FRAGMENT } from './ListDetailsQueries';
import {
  DefaultCriteriasQueryQuery,
  ListDynamicRulesQueryQuery,
  ListDynamicRulesQueryQueryVariables,
  SetDynamicRulesForListMutation,
  SetDynamicRulesForListMutationVariables,
  UpdateListCountriesMutation,
  UpdateListCountriesMutationVariables
} from '../../../../gql/typings';

const ListDynamicRules: React.FC<{
  listId: number;
  entityType: SupportedEntitySearchTypes;
  visibleState: StateArray<boolean>;
  onUpdate?: () => void;
}> = ({ listId, entityType, visibleState, onUpdate }) => {
  const [form] = Form.useForm();
  const localization = useLocalization();
  const {
    data,
  } = useQuery<ListDynamicRulesQueryQuery, ListDynamicRulesQueryQueryVariables>(DATA_QUERY, { variables: { listId } });
  const [
    selectedCriterias,
    setSelectedCriterias,
  ] = useState<NonNullable<DefaultCriteriasQueryQuery['defaultCriterias']>['nodes']['0']['criteria'][]>([]);
  const [
    setDynamicRules,
    { loading: loading1 },
  ] = useMutation<SetDynamicRulesForListMutation, SetDynamicRulesForListMutationVariables>(UPDATE_DYNAMIC_RULES);
  const [
    setCountries,
    { loading: loading2 }
  ] = useMutation<UpdateListCountriesMutation, UpdateListCountriesMutationVariables>(UPDATE_LIST_COUNTRY_RULES);
  const [selectedCountries, setSelectedCountries] = useState<FlagCountry[]>();

  useEffect(() => {
    if (data?.list?.countries.nodes && !selectedCountries) {
      setSelectedCountries(data?.list.countries.nodes.map(e => e.countryCode as FlagCountry));
    }

    if (data?.list?.criterias) {
      setSelectedCriterias(data.list.criterias.nodes.map(n => n.criteria) ?? []);

      const formValues: Record<string, AnyValueType> = {};
      // TODO: Should not use old [dynamicRulesMap] function
      data.list.criterias.nodes.map(dynamicRulesMap).map(e => {
        const valueNode = data.list!.criterias!.nodes.find(n => n.criteria.id === e.criteria.id);
        if (!valueNode) return;
        // @ts-ignore
        const map: Record<number, AnyValueType> = e.options.initialValue;
        keys(map).map(key => {
          const pathId = toNumber(key);
          const inputPath = valueNode!.criteria.inputPaths.nodes.find(i => i.id === pathId);
          if (!inputPath) return;
          const calc = criteriaInputKeyGenerator(valueNode!.criteria, { id: pathId });
          // @ts-ignore
          formValues[calc] = generateCriteriaInputValue(inputPath, map[pathId], localization);
        });
      });
      form.setFieldsValue(formValues);
    }
  }, [form, data, localization, selectedCountries]);

  const onSave = () => {
    const rules = criteriaBuilder({ searchFilter: form.getFieldsValue() })
      .criteria
      ?.criterias
      ?.filter(r => r.value?.values?.length !== 0 ?? true) // Filter out empty arrays
      ?? [];

    setCountries({
      variables: { listId, countries: selectedCountries },
    }).then(() => setDynamicRules({
      variables: {
        input: { listId, rules },
      },
    }).finally(() => {
      visibleState[1](false);
      setTimeout(() => {
        onUpdate?.();
      }, 1500);
    }));
  };

  const onCancel = () => {
    visibleState[1](false);
    setSelectedCountries((data?.list?.countries.nodes ?? []).map(c => c.countryCode as FlagCountry));
  };

  return (
    <Context.Provider
      // @ts-ignore
      value={{
        entityType,
        form,
        selectedCriterias,
        setSelectedCriterias,
        doSearch: () => {},
        selectedCountriesState: [selectedCountries, () => {}],
      }}
    >
      <EntitiesSearchInitialization />
      <EntitiesSearchDrawer drawerState={visibleState}>
        <SimpleSearchOverview
          drawerState={visibleState}
          header={(
            <div className="list-dynamic-rules-search-overview-container">
              <div className="title-and-actions">
                <div className="left">
                  {localization.formatMessage(Locale.Command.Edit)}
                  {localization.formatMessage(Locale.General.Dynamic_rules)}
                </div>
                <div className="right">
                  <Button.Group>
                    <Button onClick={onCancel}>
                      {localization.formatMessage(Locale.Command.Cancel)}
                    </Button>
                    <Button id="dynamic-rules-edit-save-btn" type="primary" onClick={onSave} loading={loading1 || loading2}>
                      {localization.formatMessage(Locale.Command.Save)}
                    </Button>
                  </Button.Group>
                </div>
              </div>
              <div className="countries">
                {localization.formatMessage(Locale.Attribute.Countries)}
                <FlagSelector
                  selected={selectedCountries}
                  onChange={e => setSelectedCountries(e ?? undefined)}
                />
              </div>
            </div>
          )}
        />
      </EntitiesSearchDrawer>
    </Context.Provider>
  );
};

const UPDATE_LIST_COUNTRY_RULES = gql`
  mutation UpdateListCountries($listId: Int!, $countries: [CountryCode!]) {
    updateListCountries(listId: $listId, countries: $countries) {
      id
      countries {
        hash
        nodes {
          id
          countryCode
        }
      }
    }
  }
`;

const UPDATE_DYNAMIC_RULES = gql`
  mutation SetDynamicRulesForList($input: DynamicRulesInput!) {
    setDynamicRulesToList(input: $input) {
      id
      list {
        id
        ...ListDynamicRulesFragment
        affiliations {
          ... on PersonConnection { hash, totalCount }
          ... on SiteConnection { hash, totalCount }
          ... on ActivityConnection { hash, totalCount }
          ... on SitePersonConnection { hash, totalCount }
        }
      }
    }
  }
  ${LIST_DYNAMIC_RULES_FRAGMENT}
`;

const DATA_QUERY = gql`
  query ListDynamicRulesQuery($listId: Int!) {
    list(id: $listId) {
      id
      ...ListDynamicRulesFragment
      countries {
        hash
        nodes {
          id
          countryCode
        }
      }
    }
  }
  ${LIST_DYNAMIC_RULES_FRAGMENT}
`;

export default ListDynamicRules;
