import React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import AdvanceSearchVennDiagram from './AdvanceSearchVennDiagram';

interface AdvanceSearchPreviewResultProps {
  visible?: boolean;
  closePreview: () => void;
}
const AdvanceSearchPreviewResult: React.FC<AdvanceSearchPreviewResultProps> = ({
  visible = false,
  closePreview
}) => (
  <div className={`advance-search-preview-result-container ${visible ? 'visible' : ''}`}>
    <div style={{ position: 'relative', width: '100%' }}>
      <CloseOutlined
        style={{
          position: 'absolute',
          top: '10px',
          right: '0',
          zIndex: '1',
          color: 'white',
          cursor: 'pointer'
        }}
        onClick={closePreview}
      />
    </div>

    <AdvanceSearchVennDiagram />
  </div>
);
export default AdvanceSearchPreviewResult;
