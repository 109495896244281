
import React from 'react';
import { VennDiagram } from '../../Charts';

const AdvanceSearchVennDiagram = () => {
  const data =[
    { sets: ['A'], size: 12, label: 'A' },
    { sets: ['B'], size: 12, label: 'B' },
    { sets: ['C'], size: 12, label: 'C' },
    { sets: ['A', 'B'], size: 2, label: 'A&B' },
    { sets: ['A', 'C'], size: 2, label: 'A&C' },
    { sets: ['B', 'C'], size: 2, label: 'B&C' },
    { sets: ['A', 'B', 'C'], size: 1 },
  ];
  return (
    <div>
      <VennDiagram data={data} visible />
    </div>
  );
};
export default AdvanceSearchVennDiagram;
