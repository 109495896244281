/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Form, Select, Spin } from 'antd';
import { isArray, isNil } from 'lodash';
import Icon from '../../Icon/Icon';
import { useLocalization } from '../../../util/useLocalization';
import { Locale } from '../../../../localization/LocalizationKeys';
import { AdvanceCriteriaInputProps } from '../AdvanceCriteriaInputTools';
import { PreferredLOVLanguage, usePreferredLOVLanguage } from '../../../util/usePreferredLOVLanguage';
import { useSuggestionDropdownValuesAdvance } from '../useSuggestionsDropdownValuesAdvance';
import { SearchInputKeyName } from '../../entitiesSearch/advanced/SearchFormItem';
import { AnyValueType } from '../../../util/StateArrayType';

const AdvanceCriteriaSelectInput: React.FC<AdvanceCriteriaInputProps> = React.memo(({
  criteria,
  form,
  inputPath,
  valuePath,
  countryCodes,
  size= 'large',
  style= {}
}) => {
  const localization = useLocalization();
  const preferredLanguage = usePreferredLOVLanguage();
  const [search, setSearch] = useState('');
  const key = inputPath || criteria.code;
  const keyValue = valuePath || criteria.code;
  const selectedKey = Form.useWatch<string|undefined>(key, form);

  const dropdown = useSuggestionDropdownValuesAdvance({
    service: criteria.service!,
    criteriaPathId: Math.random(),
    countryCodes,
    criteria: {
      id: Math.random(),
      entityType: criteria.parentEntityType!
    },
    search,
    selectedKeys: selectedKey ? [selectedKey] : [],
  });

  const [sortTop, setSortTop] = useState<string>();
  const criteriaArrayToValue = (criteriaValue: Array<AnyValueType>) => criteriaValue.map(cv => (cv));

  useEffect(() => {
    form.validateFields([key]);
    setSortTop(form.getFieldValue(key));
    if (!isNil(criteria.value)) {
      const toSetValue =criteria.value ? criteriaArrayToValue(criteria.value as Array<AnyValueType>) : [];
      if (isArray(key)) {

        form.setFields([
          { name: [...keyValue! as SearchInputKeyName[]], value: toSetValue }
        ]);
      } else {
        form.setFieldsValue({ [keyValue! as SearchInputKeyName]: toSetValue });
      }

      if (dropdown.records.length === 0) {
        dropdown.triggerLoading();
      }
    }
  }, [JSON.stringify(key), criteria.value, form]);


  const filterSort = (optionA: { key: string; raw: string }, optionB: { key:string; raw: string }): number => {
    let diff = optionA.raw.toLowerCase().localeCompare(optionB.raw.toLowerCase());
    if (sortTop === optionA.key) diff -= 5000;
    if (sortTop === optionB.key) diff += 5000;
    return diff;
  };

  const options = dropdown.records.filter(d => !d.isGlobalOnly).map(item => {
    const raw = preferredLanguage === PreferredLOVLanguage.ENGLISH && item.englishHeading
      ? item.englishHeading
      : localization.formatMessageByStr(item.heading);

    return ({
      key: item.key,
      value: item.key,
      // count: item.count,
      raw,
      label: (
        <span
          className="option-value"
          data-value-is-heading-key={item.isHeadingKey}
          data-localization-key={preferredLanguage === PreferredLOVLanguage.ENGLISH
            ? item.englishHeading || item.englishHeading
            : item.heading}
        >
          <span>{raw}</span>
        </span>
      ),
    });
  }).sort(filterSort);

  return (
    <Form.Item name={key}>
      <Select
        mode="multiple"
        id={criteria.code}
        // @ts-ignore
        autoComplete="newpassword"
        allowClear
        suffixIcon={<Icon iconType="PERSON" style={{ opacity: 0.5 }} />}
        showSearch
        onSearch={setSearch}
        loading={dropdown.loading}
        placeholder={criteria.placeholder
          ? localization.formatMessage({ id: criteria.placeholder, defaultMessage: criteria.placeholder })
          : ''}
        size={size}
        notFoundContent={dropdown.loading
          ? <Spin size="small" />
          : <span>{localization.formatMessage(Locale.Text.Nothing_found)}</span>}
        popupClassName="select-dropdown-container-scrollbar"
        style={{ width: '100%', ...style }}
        popupMatchSelectWidth={false}
        onDropdownVisibleChange={visible => {
          dropdown.triggerLoading();
          if (!visible) {
            setSortTop(selectedKey);
          }
        }}
        filterOptions={false}
        options={options || []}
        onBlur={() => setSearch('')}
      />
    </Form.Item>
  );
});


export default AdvanceCriteriaSelectInput;
