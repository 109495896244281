import React, { useContext, useEffect } from 'react';
import { Space, Switch } from 'antd';
import { get } from 'lodash';
import { gql, useQuery } from '@apollo/client';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import EntitiesSearchContext from '../EntitiesSearchContext';
import Icon from '../../Icon/Icon';
import { Locale } from '../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../util/useLocalization';
import { usePermission } from '../../../permission/usePermission';
import { CheckMdmModuleEnabledQuery, EntityTypeEnum } from '../../../../gql/typings';


const EntitiesSearchActions: React.FC<{ onSaveSearch?: () => void; isAdvanceSearch?: boolean }> = ({
  onSaveSearch = () => {},
  isAdvanceSearch = false,
  children,
}) => {
  const localization = useLocalization();
  const { read: canSearchInactive } = usePermission('SEARCH_INACTIVE');
  const { read: canSearchUnplaced } = usePermission('SEARCH_UNPLACED');
  const { read: canSearchOptedOut } = usePermission('HCP_OPT_OUT');

  const { data: mdmData } = useQuery<CheckMdmModuleEnabledQuery>(
    HAS_MODULE_ENABLED_DATA_QUERY
  );
  

  const {
    doClear,
    doSearch,
    globalState,
    includeInactiveRecords,
    includeUnplacedRecords,
    includeOptedOutRecords,
    setSelectedSaved,
    entityType,
    onlyCherryPicked,
    data: { data },
  } = useContext(EntitiesSearchContext);
  const renderUnplaced = entityType === EntityTypeEnum.PERSON || entityType === EntityTypeEnum.SITE;
  const renderOptedOut = entityType === EntityTypeEnum.PERSON;
  const renderOnlyCherryPicked= entityType === EntityTypeEnum.PERSON;
  const onClear = () => {
    doClear();
    doSearch('EntitiesSearchActions:onClear');
    setSelectedSaved(null);
  };


  useEffect(() => {
    if (mdmData?.hasAccessToModule) {
      if (includeUnplacedRecords && canSearchUnplaced && renderUnplaced) {
        includeUnplacedRecords[1](true);
      }
      if (includeOptedOutRecords && canSearchOptedOut && renderOptedOut) {
        includeOptedOutRecords[1](true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mdmData, canSearchUnplaced, canSearchOptedOut]);

  return (
    <div className="entity-search-action-container">
      <Space size="middle">
        {
          !isAdvanceSearch && (
            <>
              <div className="search-save">
                <Icon
                  onClick={onSaveSearch}
                  style={{ color: '#4E89FF' }}
                  iconType="SAVE_WHITE"
                  text={localization.formatMessage(Locale.Command.Save_search)}
                />
              </div>
              <div className="search-clear">
                <Icon
                  onClick={onClear}
                  iconType="DELETE_WHITE"
                  text={localization.formatMessage(Locale.Command.Clear_search)}
                />
              </div></>
          )
        }
        {(includeInactiveRecords && canSearchInactive && entityType != EntityTypeEnum.DCR_GROUP) && (
          <div className="search-inactive">
            <Space>
              <Switch
                checkedChildren={<CheckOutlined />}
                checked={includeInactiveRecords[0]}
                onChange={() => includeInactiveRecords[1](!includeInactiveRecords[0])}
                unCheckedChildren={<CloseOutlined />}
              />
              {localization.formatMessage(Locale.Command.Include_inactive)}
            </Space>
          </div>
        )}

        {includeUnplacedRecords && renderUnplaced && canSearchUnplaced && (
          <div className="search-unplaced">
            <Space>
              <Switch
                checkedChildren={<CheckOutlined />}
                checked={includeUnplacedRecords[0]}
                onChange={() => includeUnplacedRecords[1](!includeUnplacedRecords[0])}
                unCheckedChildren={<CloseOutlined />}
              />
              {localization.formatMessage(Locale.Command.Include_unplaced)}
            </Space>
          </div>
        )}

        {includeOptedOutRecords && renderOptedOut && canSearchOptedOut && (
          <div className="search-opted-out">
            <Space>
              <Switch
                checkedChildren={<CheckOutlined />}
                checked={includeOptedOutRecords[0]}
                onChange={() => includeOptedOutRecords[1](!includeOptedOutRecords[0])}
                unCheckedChildren={<CloseOutlined />}
              />
              {localization.formatMessage(Locale.Command.Include_opted_out)}
            </Space>
          </div>
        )}
        {onlyCherryPicked && renderOnlyCherryPicked && (
          <div className="search-unplaced">
            <Space>
              <Switch
                checkedChildren={<CheckOutlined style={{ fontSize: '12px' }} />}
                unCheckedChildren={<CloseOutlined style={{ fontSize: '12px' }} />}
                checked={onlyCherryPicked[0]}
                onChange={() => onlyCherryPicked[1](!onlyCherryPicked[0])}
                size="default"
              />
              {localization.formatMessage(Locale.Command.Only_cherry_picked)}
            </Space>
          </div>)}
        {globalState && <div className="switch-global-data">
          <Switch
            className="global-switch"
            checkedChildren={<CheckOutlined style={{ fontSize: '12px' }} />}
            unCheckedChildren={<CloseOutlined style={{ fontSize: '12px' }} />}
            checked={globalState[0]}
            onChange={() => globalState[1](!globalState[0])}
            size="default"
          />
        </div>}

      </Space>
      {globalState && <div className="switch-global-data">
        <span className="extra-global-label">
          <div className="text">
            {localization.formatMessage(Locale.Command.Global_search)}
          </div>
          <div className="count">
            {localization.pluralMessage(
              Locale.Text.additional_count,
              get(data, 'connection.globalAdditionalCount', 0),
            )}
          </div>
        </span>
      </div>}
      {children && (
        <div className="extra-menu">{children}</div>
      )}
    </div>
  );
};

const HAS_MODULE_ENABLED_DATA_QUERY = gql`
  query CheckMdmModuleEnabled {
    hasAccessToModule(module:MDM)
  }
`;


export default EntitiesSearchActions;

