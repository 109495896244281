import dayjs, { Dayjs } from 'dayjs';
import { Locale } from '../../../../localization/LocalizationKeys';
import { LocalizationShape } from '../../../util/useLocalization';
import { DATE_FORMAT } from '../../../util/format';

type DurationType = 'D' | 'W' | 'Y' | 'M';

const DurationTypeToWordObj = {
  D: Locale.General.Days_plural,
  W: Locale.General.Weeks_plural,
  M: Locale.General.Months_plural,
  Y: Locale.General.Years_plural
};

export type TimeSearch = {
  from?: {
    timestamp?: Dayjs;
    relativePoint?: string;
  };
  to?: {
    timestamp?: Dayjs;
    relativePoint?: string;
  };
};
export type TimeSearchStringValue = {
  relativePoint?: string;
  timestamp?: string;
};
export type TimeSearchString = {
  from: TimeSearchStringValue;
  to: TimeSearchStringValue;
};
export const SerialiseDate = (input: TimeSearch): string => {
  const b: TimeSearchString = { from: {}, to: {} };

  if (input.from?.relativePoint) b.from.relativePoint = input.from.relativePoint;
  if (input.from?.timestamp) b.from.timestamp = input.from.timestamp.toISOString();
  if (input.to?.relativePoint) b.to.relativePoint = input.to.relativePoint;
  if (input.to?.timestamp) b.to.timestamp = input.to.timestamp.toISOString();
  return JSON.stringify(b);
};

export const DeserializeDate = (input: string): TimeSearch => {
  const unpacked: TimeSearchString = input ? JSON.parse(input) : { from: {}, to: {} };
  return {
    from: {
      timestamp: unpacked.from.timestamp ? dayjs(unpacked.from.timestamp) : undefined,
      relativePoint: unpacked.from.relativePoint,
    },
    to: {
      timestamp: unpacked.to.timestamp ? dayjs(unpacked.to.timestamp) : undefined,
      relativePoint: unpacked.to.relativePoint,
    },
  };
};

export const DateToWords = (input: TimeSearch, pluralMessage: LocalizationShape['pluralMessage']): string => {
  const durationData = input?.to?.relativePoint ?? input?.from?.relativePoint ?? '';
  if (durationData) {
    const numeralWildCard = '%%';
    const numeralWordWildCard = '**';
    const wordString = input.to?.relativePoint
      ? `Older than ${numeralWildCard} ${numeralWordWildCard} ago`
      : `Newer than ${numeralWildCard} ${numeralWordWildCard}`;
    const toSetDuration = durationData.match(/\d+/g);
    let numericalDuration = '0';
    if (toSetDuration && toSetDuration?.length > 0) {
      numericalDuration = toSetDuration[0]!;
    }
    const toSetDurationType = durationData.replace(/P-\d+/g, '') as DurationType;
    const localeDurationType = pluralMessage(DurationTypeToWordObj[toSetDurationType], +numericalDuration ?? 1);
    return wordString
      .replace(numeralWildCard, numericalDuration)
      .replace(numeralWordWildCard, localeDurationType!.toString());
  }
  const hasTimeStampData = input.to?.timestamp ?? input.from?.timestamp ?? '';
  if (!hasTimeStampData) return '';
  const startDate = input.from?.timestamp;
  const endDate = input.to?.timestamp;
  if (!dayjs(startDate).isSame(endDate)) {
    return dayjs(startDate).format(DATE_FORMAT);
  }
  return `${dayjs(startDate).format(DATE_FORMAT)} to ${dayjs(endDate).format(DATE_FORMAT)}`;
};

