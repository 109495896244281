import React from 'react';
import { Col, Form, Input, Row } from 'antd';
import { useIntl } from 'react-intl';
import MainView from '../../components/View/MainView';
import { EntitiesSearch } from '../../components/entitiesSearch';
import { usePageTitle } from '../../hooks/usePageTitle';
import { Locale } from '../../../localization/LocalizationKeys';
import { useSiteTableQuickActions } from './hooks/useSiteTableQuickActions';
import { EntityTypeEnum } from '../../../gql/typings';
import { useSystemPermission } from '../../permission/useSystemPermission';
import { useLocalization } from '../../util/useLocalization';

const SiteView = () => {
  const localization = useLocalization();
  const { formatMessage } = useIntl();
  usePageTitle(formatMessage(Locale.Attribute.Site_HCO));
  const [form] = Form.useForm();

  const { entitiesSearchProps, tableSelection, HiddenRender } = useSiteTableQuickActions(form, { maintainPageState: true });

  const hasGlobalSearchPermission = useSystemPermission('globalSearch');


  return (
    <MainView className="site-view-container" boundingBox={false}>
      <EntitiesSearch
        form={form}
        entityType={EntityTypeEnum.SITE}
        tableSelection={tableSelection}
        {...entitiesSearchProps}
        globalSupport
        globalSearch={hasGlobalSearchPermission}
        urlSearchEnabled
        customSearchFilterCriteria={<>

          <Input.Group className="criteria-input-container contrast-label" style={{ display: 'grid', maxWidth: '220px' }}>
            <span className="label">
              {localization.formatMessage(Locale.Attribute.aPureBase_ID_list)}
            </span>
            <Row gutter={[4, 4]}>
              <Col span={24}>
                <Form.Item name='aPureBaseIds'>
                  <Input
                    id="aPureBaseIds"
                    size='large'
                    placeholder={localization.formatMessage(Locale.Command.Enter_aPureBase_IDs)}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Input.Group>

        </>}
      />
      <HiddenRender />
    </MainView>
  );
};

export default SiteView;
