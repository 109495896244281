import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { Drawer, Tabs } from 'antd';
import EntitiesSimpleSearch from './EntitiesSimpleSearch';
import { StateArray } from '../../../../util/StateArrayType';
import { Locale } from '../../../../../localization/LocalizationKeys';
import EntitiesSearchContext from '../../EntitiesSearchContext';
import AdvanceSearch from '../../advanced/AdvanceSearch';

const DEFAULT_DRAWER_WIDTH = 465;


const EntitiesSearchDrawer: React.FC<{
  drawerState: StateArray<boolean>;
  drawerWidth?: number;
}> = ({
  drawerState,
  drawerWidth = DEFAULT_DRAWER_WIDTH,
  children,
}) => {
  const { formatMessage } = useIntl();
  const { searchType } = useContext(EntitiesSearchContext);


  return (
    <>
      <div id='preview-portal' />
      <Drawer
        title={<></>}
        open={drawerState[0]}
        onClose={() => drawerState[1](false)}
        width={drawerWidth}
        closable
        className="entities-search-drawer-container"
      >
        {children}
        <Tabs
          className="tabs"
          centered
          defaultActiveKey={searchType === 'SIMPLE' ? '1001' : '1003'}
          items={[
            {
              key: '1001',
              label: formatMessage(Locale.General.Simple_search),
              children: <EntitiesSimpleSearch />
            },
            {
              key: '1003',
              label: 'Advanced Search',
              children: <AdvanceSearch drawerState={drawerState} />
            }
          ]}
        />
      </Drawer>
    </>
  );
};


export default EntitiesSearchDrawer;
