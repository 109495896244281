import React, { useEffect, useState } from 'react';
import { Form, DatePicker, Button, Input, InputNumber, Select, Tabs, Popover, Space } from 'antd';
import { isNil } from 'lodash';
import dayjs, { Dayjs } from 'dayjs';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import {
  DateToWords,
  DeserializeDate,
  SerialiseDate,
  TimeSearch
} from '../CriteriaInput/types/CriteriaDateUtils';
import { DATE_FORMAT } from '../../util/format';
import { Locale } from '../../../localization/LocalizationKeys';
import { LocalizationShape, useLocalization } from '../../util/useLocalization';
import { AnyValueType } from '../../util/StateArrayType';
import { SearchInputKeyName } from '../entitiesSearch/advanced/SearchFormItem';


const { RangePicker } = DatePicker;
type TabKey = 'newerThan'|'olderThan'|'exactDate'|'range';

interface AdvanceDateRangePickerProps {
  namePath: string[] | SearchInputKeyName[];
  initialValue?: AnyValueType;
  disabled?: boolean;
  size?: SizeType;
}

type DateFormInput = {
  duration?: number;
  durationType?: string;
  exactDate?: Dayjs;
  range?: [Dayjs, Dayjs];
};

// TODO: This display string should be way better!
const generateDisplayString = (
  activeKey: TabKey,
  formValues: string,
  pluralMessage: LocalizationShape['pluralMessage']
): string => {
  const args = DeserializeDate(formValues);
  switch (activeKey) {
    case 'newerThan':
    case 'olderThan':
      return DateToWords(args, pluralMessage);
    case 'exactDate':
      return args.to?.timestamp ? dayjs(args.to?.timestamp)?.format(DATE_FORMAT) : '';
    case 'range':
      if (!args.from?.timestamp && !args.to?.timestamp) return '';
      // eslint-disable-next-line max-len
      return `${dayjs(args.from?.timestamp)?.format(DATE_FORMAT) ?? ''} to ${dayjs(args.to?.timestamp)?.format(DATE_FORMAT) ?? ''}`;
    default:
      return '';
  }
};

const AdvanceDateRangePicker: React.FC<AdvanceDateRangePickerProps> = ({
  namePath,
  initialValue,
  disabled,
  size= 'middle',
}) => {
  const localization = useLocalization();
  const form = Form.useFormInstance();
  const [activeKey, setActiveKey] = useState<TabKey>('exactDate');
  const [selectedDate, setSelectedDate] = useState<string>(generateDisplayString(
    activeKey,
    form.getFieldValue(namePath) || initialValue,
    localization.pluralMessage
  ));
  const [clicked, setClicked] = useState(false);

  const formValue = form.getFieldValue(namePath);

  const [dateForm] = Form.useForm<DateFormInput>();
  const { duration } = Form.useWatch([], dateForm) || {};

  const dateDurationOptions = [
    /*  { label: 'Minutes', value: 'T%M' },
      { label: 'Hours', value: 'T%H' }, */
    { label: localization.pluralMessage(Locale.General.Days_plural, duration ?? 1), value: 'P%D' },
    { label: localization.pluralMessage(Locale.General.Weeks_plural, duration ?? 1), value: 'P%W' },
    { label: localization.pluralMessage(Locale.General.Months_plural, duration ?? 1), value: 'P%M' },
    { label: localization.pluralMessage(Locale.General.Years_plural, duration ?? 1), value: 'P%Y' }];


  const tabOptions = [
    {
      key: 'newerThan',
      label: localization.formatMessage(Locale.General.Newer_than),
      children: (
        <div style={{ display: 'flex', columnGap: 5 }}>
          <Form.Item label={localization.formatMessage(Locale.General.Duration)} name='duration'>
            <InputNumber id='duration' size={size} style={{ width: '60px' }} />
          </Form.Item>
          <Form.Item name='durationType' style={{ flexGrow: '1' }}>
            <Select
              id='durationType'
              size={size}
              placeholder={localization.formatMessage(Locale.Text.Select_duration)}
              options={dateDurationOptions}
            />
          </Form.Item>
          <span style={{ alignSelf: 'center', marginBottom: 24 }}>
            {localization.formatMessage(Locale.General.lowercase_ago)}
          </span>
        </div>
      ),
    },
    {
      key: 'olderThan',
      label: localization.formatMessage(Locale.General.Older_than),
      children: (
        <div style={{ display: 'flex', columnGap: 5 }}>
          <Form.Item label={localization.formatMessage(Locale.General.Duration)} name='duration'>
            <InputNumber id='duration' size={size} style={{ width: '60px' }} />
          </Form.Item>
          <Form.Item name='durationType' style={{ flexGrow: '1' }}>
            <Select
              id='durationType'
              size={size}
              placeholder={localization.formatMessage(Locale.Text.Select_duration)}
              options={dateDurationOptions}
            />
          </Form.Item>
          <span style={{ alignSelf: 'center', marginBottom: 24 }}>
            {localization.formatMessage(Locale.General.lowercase_ago)}
          </span>
        </div>
      ),
    },
    {
      key: 'exactDate',
      label: localization.formatMessage(Locale.General.Exact_date),
      children: (
        <Form.Item label={localization.formatMessage(Locale.General.Exact_date)} name='exactDate'>
          <DatePicker
            id='exactDate'
            showTime={false}
            size={size}
            format={DATE_FORMAT}
            style={{ width: '200px' }}
            placeholder={disabled ? '' : localization.formatMessage(Locale.Command.Select_date)}
            disabled={disabled}
          />
        </Form.Item>
      )
    },
    {
      key: 'range',
      label: localization.formatMessage(Locale.General.Range),
      children: (
        <Form.Item label={localization.formatMessage(Locale.General.Range)} name='range'>
          <RangePicker
            id='range'
            size={size}
            format={DATE_FORMAT}
            disabled={disabled}
          />
        </Form.Item>
      )
    }
  ];

  useEffect(() => {
    if (!isNil(initialValue)) {
      form.setFieldValue([...namePath], initialValue);
    }
  }, [form, namePath, initialValue]);

  useEffect(() => {
    if (formValue) {
      const args = DeserializeDate(formValue);
      if (!args.from?.timestamp) {
        args.from?.relativePoint
          ? setActiveKey('newerThan') : setActiveKey('olderThan');
      } else {
        dayjs(args.from?.timestamp).isSame(args.to?.timestamp, 'day')
          ? setActiveKey('exactDate') : setActiveKey('range');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [namePath, formValue]);

  useEffect(() => {
    // to update the input only when prefilled
    if (!clicked) {
      setSelectedDate(generateDisplayString(
        activeKey,
        formValue || initialValue,
        localization.pluralMessage
      ));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKey]);

  function getInitialFormValues() {
    if (!form.getFieldValue(namePath) && !initialValue) {
      return {
        duration: 1,
        durationType: 'P%D',
      };
    }
    const dateString = form.getFieldValue(namePath) || initialValue || '';
    const formData = DeserializeDate(dateString);
    if (activeKey === 'newerThan' || activeKey === 'olderThan') {
      const durationData = formData?.to?.relativePoint ?? formData?.from?.relativePoint ?? '';
      const toSetDuration = durationData.match(/\d+/g);
      const toSetDurationType = durationData.replace(/-\d+/g, '%');
      return {
        duration: toSetDuration,
        durationType: toSetDurationType
      };
    }
    if (activeKey === 'exactDate') {
      return {
        exactDate: formData?.to?.timestamp ?? ''
      };
    }
    return {
      range: [formData?.to?.timestamp, formData?.from?.timestamp]
    };
  }

  const onSave = () => {
    const formValues = dateForm.getFieldsValue(true);
    let toBeSet: TimeSearch = { from: {}, to: {} };

    if (activeKey === 'newerThan') {
      toBeSet.from!.relativePoint = formValues.durationType!.replace('%', (formValues.duration! * -1).toString());
    } else if (activeKey === 'olderThan') {
      toBeSet.to!.relativePoint = formValues.durationType!.replace('%', (formValues.duration! * -1).toString());
    } else if (activeKey === 'exactDate') {
      if (formValues.exactDate) {
        toBeSet = {
          from: {
            timestamp: dayjs(formValues.exactDate).startOf('day')
          },
          to: {
            timestamp: dayjs(formValues.exactDate).endOf('day')
          }
        };
      }
    } else if (activeKey === 'range') {
      if (formValues.range) {
        toBeSet = {
          from: {
            timestamp: formValues.range[0].startOf('day')
          },
          to: {
            timestamp: formValues.range[1].endOf('day')
          }
        };
      }
    }
    form.setFieldValue([...namePath], SerialiseDate(toBeSet));
    setSelectedDate(generateDisplayString(activeKey, form.getFieldValue(namePath), localization.pluralMessage));
    setClicked(false);
  };

  const initialDateFormValue = getInitialFormValues();
  const onClear = () => {
    dateForm.resetFields();
    form.setFieldValue([...namePath], '');
    setClicked(false);
  };

  return (
    <>
      <Form.Item hidden style={{ maxWidth: '0px' }} name={namePath}>
        <Input size={size} />
      </Form.Item>
      <Form form={dateForm} initialValues={initialDateFormValue}>
        <Popover
          trigger="click"
          open={clicked && !disabled}
          content={
            <>
              <Tabs
                activeKey={activeKey}
                centered
                size={size}
                items={tabOptions}
                onChange={value => setActiveKey(value as TabKey)}
              />
              <Space style={{ display: 'flex', justifyContent: 'center' }}>
                <Button type="default" size={size} onClick={onClear}>
                  { localization.formatMessage(Locale.Command.Clear)}
                </Button>
                <Button type="primary" size={size} onClick={onSave}>
                  {localization.formatMessage(Locale.General.Ok)}
                </Button>
              </Space>
            </>
          }
        >
          <Form.Item style={{ maxWidth: '200px' }} shouldUpdate={() => true}>
            {() => <Input
              id={namePath.toString()}
              size={size === 'small' ? 'small' : 'large'}
              value={selectedDate}
              onClick={() => !disabled && setClicked(true)}
              placeholder={localization.formatMessage(Locale.Command.Select_date)}
              disabled={disabled}
            />}
          </Form.Item>
        </Popover>
      </Form>
    </>
  );
};

export default AdvanceDateRangePicker;
