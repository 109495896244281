/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Form, Select, Spin } from 'antd';
import { isNil } from 'lodash';
import { CriteriaInputProps } from '../CriteriaInputTools';
import { criteriaInputKeyGenerator } from '../CriteriaInputFactory';
import Icon from '../../Icon/Icon';
import { useLocalization } from '../../../util/useLocalization';
import { Locale } from '../../../../localization/LocalizationKeys';
import { PreferredLOVLanguage, usePreferredLOVLanguage } from '../../../util/usePreferredLOVLanguage';
import { useSuggestionDropdownValues } from '../useSuggestionDropdownValues';


const CriteriaSelectInput: React.FC<CriteriaInputProps> = React.memo(({
  criteria,
  inputPath,
  disabled,
  form,
  initialValue,
  countryCodes,
}) => {
  const localization = useLocalization();
  const preferredLanguage = usePreferredLOVLanguage();
  const [search, setSearch] = useState('');
  const key = criteriaInputKeyGenerator(criteria, inputPath);
  const selectedKey = Form.useWatch<string|undefined>(key, form);

  const dropdown = useSuggestionDropdownValues({
    service: inputPath.service!,
    criteriaPathId: inputPath.id,
    countryCodes,
    criteria,
    search,
    selectedKeys: selectedKey ? [selectedKey] : [],
  });

  const [sortTop, setSortTop] = useState<string>();

  useEffect(() => {
    form.validateFields([key]);
    setSortTop(form.getFieldValue(key));
    if (!isNil(initialValue)) {
      form.setFieldsValue({ [key]: initialValue });
    }
  }, [key, initialValue]);


  const filterSort = (optionA: { key: string; raw: string }, optionB: { key:string; raw: string }): number => {
    let diff = optionA.raw.toLowerCase().localeCompare(optionB.raw.toLowerCase());
    if (sortTop === optionA.key) diff -= 5000;
    if (sortTop === optionB.key) diff += 5000;
    return diff;
  };

  const options = dropdown.records.filter(d => !d.isGlobalOnly).map(item => {
    const raw = preferredLanguage === PreferredLOVLanguage.ENGLISH && item.valueGb
      ? item.valueGb
      : localization.formatMessageByStr(item.value);

    return ({
      key: item.key,
      value: item.key,
      // count: item.count,
      raw,
      label: (
        <span
          className="option-value"
          data-value-is-heading-key={item.isValueHeadingKey}
          data-localization-key={preferredLanguage === PreferredLOVLanguage.ENGLISH
            ? item.valueGb || item.value
            : item.value}
        >
          <span>{raw}</span>
          {/* <span className="option-badge"> */}
          {/*  {isActualNumber(item.count) */}
          {/*    ? <FormattedNumber value={item.count} /> */}
          {/*    : <LoadingOutlined style={{ fontSize: 10 }} spin />} */}
          {/* </span> */}
        </span>
      ),
    });
  }).sort(filterSort);

  return (
    <Form.Item name={key}>
      <Select
        id={key}
        // @ts-ignore
        autoComplete="newpassword"
        allowClear
        suffixIcon={<Icon iconType="PERSON" style={{ opacity: 0.5 }} />}
        showSearch
        onSearch={setSearch}
        loading={dropdown.loading}
        placeholder={inputPath.placeholderHeading && !disabled
          ? localization.formatMessage({ id: inputPath.placeholderHeading, defaultMessage: inputPath.placeholderHeading })
          : ''}
        size="large"
        disabled={disabled}
        notFoundContent={dropdown.loading
          ? <Spin size="small" />
          : <span>{localization.formatMessage(Locale.Text.Nothing_found)}</span>}
        popupClassName="select-dropdown-container-scrollbar"
        style={{ width: '100%' }}
        popupMatchSelectWidth={false}
        onDropdownVisibleChange={visible => {
          dropdown.triggerLoading();
          if (!visible) {
            setSortTop(selectedKey);
          }
        }}
        filterOptions={false}
        options={options}
        onBlur={() => setSearch('')}
      />
    </Form.Item>
  );
});


export default CriteriaSelectInput;
