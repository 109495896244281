import React, { useState } from 'react';
import { Typography, Input, Checkbox, CheckboxOptionType, Form } from 'antd';
import { CheckOutlined, EditOutlined } from '@ant-design/icons';
import { useLocalization } from '../../../util/useLocalization';
import { Locale } from '../../../../localization/LocalizationKeys';
import { SearchInputKeyName } from './SearchFormItem';

type CustomInputBuilderProps = {
  inputPaths: SearchInputKeyName[];
  valuePaths: SearchInputKeyName[];
};

export enum CustomInputValueOptions {
  Allow_Multi = 'allow_multi',
  Allow_No = 'allow_no',
  'No_Pre' = 'no_predefined'
}

const SearchConditionCustomInputBuilder: React.FC<CustomInputBuilderProps> = ({ 
  inputPaths,
  valuePaths }) => {
  const [checkboxState, setCheckboxState] = useState<CustomInputValueOptions[]>();
  const [editMode, setEditMode] = useState<boolean>(false);
  const { formatMessage } = useLocalization();
  const formInstance = Form.useFormInstance();

  const inputValueOptions: CheckboxOptionType[] = [
    { label: formatMessage(Locale.Command.Allow_selecting_multiple), value: CustomInputValueOptions.Allow_Multi },
    { label: formatMessage(Locale.Command.Allow_no_value), value: CustomInputValueOptions.Allow_No },
    { label: formatMessage(Locale.Command.No_predefined), value: CustomInputValueOptions.No_Pre, disabled: false },
  ];

  const onSave = () => {
    // onChange?.({ ...value, ...form.getFieldsValue() });
    const inputOptions = checkboxState || [];

    formInstance.setFields(
      [
        {
          name: [...valuePaths, 'allowMultiple'],
          value: inputOptions.indexOf(CustomInputValueOptions.Allow_Multi) > -1
        },
        {
          name: [...valuePaths, 'allowNoValue'],
          value: inputOptions.indexOf(CustomInputValueOptions.Allow_No) > -1
        }]
    );
    setEditMode(false);
  };

  return (
    <div className='search-condition-custom-input-builder'>
      <div className="input-builder-header">
        <Typography.Title level={5} style={{ marginBottom: 0 }}>Input Builder</Typography.Title>
        { editMode
          ? <CheckOutlined style={{ cursor: 'pointer' }} onClick={onSave} />
          : <EditOutlined style={{ cursor: 'pointer' }} onClick={() => setEditMode(true)} /> }
      </div>
      <div className="input-builder-element-container">
        <div className="input-builder-element-row">
          <div className="element-label">
            <Typography>{formatMessage(Locale.Attribute.Input_heading)}:</Typography>
          </div>
          <div className="element-item">
            <Form.Item name={[...inputPaths, 'inputHeading']}>
              <Input disabled={!editMode} size='small' />
            </Form.Item>
          </div>
        </div>
        <div className="input-builder-element-row">
          <div className="element-label">
            <Typography>{formatMessage(Locale.Attribute.Placeholder_text)}:</Typography>
          </div>
          <div className="element-item">
            <Form.Item initialValue={formatMessage(Locale.General.Select)} name={[...inputPaths, 'inputPlaceholder']}>
              <Input disabled={!editMode} size='small' />
            </Form.Item>
          </div>
        </div>
        <div className="input-builder-element-row">
          <div className="element-label">
            <Typography>{formatMessage(Locale.Attribute.Predefined_value)}:</Typography>
          </div>
          <div className="element-item">
            <Form.Item name={[...inputPaths, 'inputPredefinedValue']}>
              <Input disabled={!editMode} size='small' />
            </Form.Item>
          </div>
        </div>
        <div className="element-value-options">
          <Form.Item>
            <Checkbox.Group
              onChange={val => {
                setCheckboxState(val as CustomInputValueOptions[]);
              }}
              options={inputValueOptions}
              disabled={!editMode}
            />
          </Form.Item>
        </div>
      </div>
    </div>
  );
};

export default SearchConditionCustomInputBuilder;
