import React, { useState } from 'react';
import { Collapse } from 'antd';

export const LightCollapseContext = React.createContext({
  onSetActive: (key) => {}
});
const LightCollapse = ({
  children,
  defaultActiveKey,
  accordion = false,
  className,
}) => {
  const [active, setActive] = useState(defaultActiveKey);

  return (
    <div className={`light-collapse-container ${className}`}>
      <LightCollapseContext.Provider value={{
        onSetActive: (activeKey) => setActive(activeKey)
      }}
      >
        <Collapse
          activeKey={active}
          onChange={setActive}
          accordion={accordion}
        >
          {children}
        </Collapse>
      </LightCollapseContext.Provider>
    </div>
  );
};

LightCollapse.Panel = Collapse.Panel;

export default LightCollapse;
