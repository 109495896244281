import React from 'react';
import { gql } from '@apollo/client';
import AdminMergeItemBasicConflictField from '../../components/basicConflictField/AdminMergeItemBasicConflictField';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import {
  MergeItemSiteTypeFragment,
  useUpdateSiteTypeMutation
} from '../../../../../../../../../gql/typings';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';


const AdminMergeItemSiteType: React.FC<AdminEntityMergeRequestItemProps> = (props) => {
  const info = props.item.type as MergeItemSiteTypeFragment;
  const localization = useLocalization();
  const [updateType] = useUpdateSiteTypeMutation();

  return (
    <AdminMergeItemBasicConflictField
      {...props}
      fromValue={info.fromCode}
      toValue={info.toCode}
      updateMutation={newValue => updateType({
        variables: {
          siteId: props.into.id,
          newValue,
        },
      })}
    >
      {localization.formatMessage(Locale.Text.Merge_request_site_type_mismatch_description, {
        siteParent1: <strong>{info.fromCode}</strong>,
        siteParent2: <strong>{info.toCode}</strong>,
      })}
    </AdminMergeItemBasicConflictField>
  );
};

gql`
  mutation UpdateSiteType($siteId: Int!, $newValue: String!) {
    updateSiteTypeCode(siteId: $siteId, typeCode: $newValue) {
      id
      siteTypeCode
    }
  }
`;

gql`
  fragment MergeItemSiteType on SiteType_EntityDataToBeTransferredType {
    code
    fromCode
    toCode
    entityType
    dataType
  }
`;

export default AdminMergeItemSiteType;
