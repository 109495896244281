/* eslint-disable prefer-destructuring */
import React, { BaseSyntheticEvent, memo, useEffect, useMemo, useState } from 'react';
import { Alert, Button, Empty, Space, Table, TableProps, Typography } from 'antd';
import { isBoolean, isEqual, isEmpty } from 'lodash';
import { ColumnsType, ColumnType } from 'antd/lib/table/interface';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useMap } from 'ahooks';
import { EntitySearchColumnParamTypeWithState, SupportedEntitySearchTypes } from '../../../browse/search_old/types';
import { useLocalization } from '../../../util/useLocalization';
import ApbTableHeader from '../../Table/ApbTableHeader';
import EntitiesSearchQuickActions, { EntitiesSearchQuickActionsProps } from '../top/EntitiesSearchQuickActions';
import { Locale } from '../../../../localization/LocalizationKeys';
import { useColors } from '../../../util/useColor';
import { useTableColumns } from './useTableColumns';
import { EntitiesSearchProps } from '../index';
import { TableRowSelectionReturnProps } from '../../Table/useTableRowSelection';
import ApbPagination from '../../Table/ApbPagination';
import { defaultPageNumber, defaultPerPage } from '../../Table/utils';
import { TableActionItem } from '../../Table/Actions';
import EntityTableRightActions from './EntityTableRightActions';
import { EntityTypeEnum } from '../../../../gql/typings';
import { useQueryParams } from '../../../util/useQueryParams';
import { useBroadcastStorage } from '../../../util/useBroadcastStorage';
import { useLastVisitState } from '../../../util/useLastVisitState';
import { useTableRowSize } from '../../../util/useTableRowSize';

export type EntityTableProps = {
  entityType: SupportedEntitySearchTypes;
  tableSelection?: TableRowSelectionReturnProps;
  openLinksInNewTab?: boolean;
  loading?: boolean;
  data: {
    loading: boolean;
    totalCount: number;
    globalSearchCount?: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error?: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    nodes: any[];
  };
  showTableDescription?: true;
  quickActions?: EntitiesSearchQuickActionsProps['quickActions'];
  tableProps?: Omit<TableProps<unknown>,
  'loading' | 'dataSource' | 'columns' | 'locale' | 'pagination' | 'rowKey' | 'style'
  | 'rowSelection' | 'onRow' | 'onChange' | 'rowClassName'>;
  globalState?: EntitiesSearchProps['globalState'];
  onRowClick?: (record: unknown, args: EntitySearchColumnParamTypeWithState) => void;
  rowIndicator?: EntitiesSearchProps['rowIndicator'] | null;
  additionalColumns?: (false | ColumnType<never>)[];
  actionsToTake?: TableActionItem[];
  hideResultsHeader?: boolean;
  onCreateEntityTab?: () => void;
  showCreateEntityButton?: boolean;
};

const createActions: { [key in SupportedEntitySearchTypes]?: { path: string; labelKey: { id: string } } } = {
  [EntityTypeEnum.PERSON]: {
    path: '/hcp/create',
    labelKey: Locale.Command.Create_Person,
  },
  [EntityTypeEnum.SITE]: {
    path: '/hco/create',
    labelKey: Locale.Command.Create_Site,
  },
  [EntityTypeEnum.LIST]: {
    path: '/list/create',
    labelKey: Locale.Command.Create_Static_List,
  },
};

// TODO: Move this over to the general table configuration type per entity
const entityDescription: { [key in SupportedEntitySearchTypes]?: { id: string } | null } = {
  PERSON: Locale.Text.Entity_search_placeholder_text_Person,
  SITE: Locale.Text.Entity_search_placeholder_text_Site,
  ACTIVITY: Locale.Text.Entity_search_placeholder_text_Activity,
  DCR_GROUP: Locale.Text.Entity_search_placeholder_text_Dcr,
  LIST: Locale.Text.Entity_search_placeholder_text_Lists,
  PURE_ADVANCE_CUSTOMER: null,
};


const EntityTable: React.FC<EntityTableProps> = memo(({
  entityType,
  tableSelection,
  loading,
  data,
  showTableDescription,
  quickActions,
  openLinksInNewTab,
  tableProps,
  globalState,
  onRowClick,
  rowIndicator,
  additionalColumns,
  actionsToTake,
  hideResultsHeader,
  onCreateEntityTab,
  showCreateEntityButton = true
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const localization = useLocalization();
  const navigate = useNavigate();
  const { colorAction: actionColor } = useColors();
  const pageState = tableSelection?.tableProps.pageState ?? useState(defaultPageNumber);
  const perPageState = tableSelection?.tableProps.perPageState ?? useBroadcastStorage<number>('per-page', defaultPerPage);
  const lastVisitedState = useLastVisitState(entityType);
  const tableRowSize = useTableRowSize();
  const [, map] = useMap<'inactive'|'global' | 'apbOptOut', boolean>();
  const queryObject = useQueryParams();
  const showLastVisited = true; // this is currently implemented for all tables
  useEffect(() => {
    let tempSearchQuery = '';
    if (!isEmpty(queryObject)) {
      tempSearchQuery = Object.keys(queryObject).reduce((acc, curr) => `${acc}${curr}=${queryObject[curr]}&`, '?');
    }
    setSearchQuery(tempSearchQuery);
  }, [queryObject, setSearchQuery]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const rowKey = (r: any) => r.id || r.code || r.key;

  const columnConfig = useTableColumns(entityType, useMemo(() => ({
    openInNewTab: openLinksInNewTab,
    cherryPickSupport: true,
  }), [openLinksInNewTab]));

  useEffect(() => {
    tableSelection?.tableProps.onVisibleKeysChange(data.nodes.map(rowKey));
    tableSelection?.tableProps.onDataChange(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const emptyConfig = createActions[entityType];
  const locale = {
    emptyText: (<>
      {!data.loading && !data.error && <Empty
        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
        imageStyle={{
          height: 60,
        }}
        description={
          <span>
            {localization.formatMessage(Locale.Command.No_data)}
          </span>
        }
      >
        <Space>
          {(data.totalCount === 0 && (data.globalSearchCount ?? 0) > 0)
              && <Button
                style={{ backgroundColor: actionColor, color: 'white' }}
                onClick={() => globalState && globalState[1](true)}
              >
                {localization.formatMessage(Locale.Command.Enable_Global_search)}
              </Button>}
          {data.totalCount === 0
            && emptyConfig && showCreateEntityButton
            && !onCreateEntityTab && (
            <NavLink to={emptyConfig.path + searchQuery}>
              <Button style={{ backgroundColor: actionColor, color: 'white' }}>
                {localization.formatMessage(emptyConfig.labelKey)}
              </Button>
            </NavLink>
          )}
          {data.totalCount === 0
            && emptyConfig && showCreateEntityButton
            && onCreateEntityTab
            && <Button style={{ backgroundColor: actionColor, color: 'white' }} onClick={onCreateEntityTab}>
              {localization.formatMessage(emptyConfig.labelKey)}
            </Button>}
        </Space>
      </Empty>}
      {
        !data.loading && data.error
        && <Alert
          type="error"
          message={localization.formatMessage(Locale.Text.Something_went_wrong)}
          banner
        />
      }
    </>

    )
  };

  const columnProps: EntitySearchColumnParamTypeWithState = {
    openInNewTab: openLinksInNewTab,
    cherryPickSupport: true,
    navigate,
    localization,
  };

  return (
    <div className={`entity-table-container ${tableRowSize[0]}`}>
      <div className="above-table">
        <div className="left">
          {!hideResultsHeader && <ApbTableHeader
            count={data.totalCount}
            selectedLabel={tableSelection?.tableProps?.rowSelection?.selectedLabel}
          />}
        </div>
        {showTableDescription && entityDescription[entityType] && (
          <Typography.Paragraph className="middle">
            {localization.formatMessage(entityDescription[entityType]!)}
          </Typography.Paragraph>
        )}
        <div className="right">
          <EntitiesSearchQuickActions quickActions={quickActions} />
          <EntityTableRightActions entityType={entityType} actionsToTake={actionsToTake} />
        </div>
      </div>

      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      <Table<any>
        {...tableProps}
        loading={data.loading || loading}
        dataSource={data?.nodes ?? []}
        size='small'
        columns={[
          ...((data.loading || loading || data?.nodes.length < 5) ? [] : [{
            title: 'S.N',
            key: 'index',
            width: '60px',
            render: (text: string,
              record: unknown,
              index: number) => {
              // @ts-ignore
              const recordID = record!.id;
              return (
                <div className={`${recordID === lastVisitedState[0] ? 'last-visited' : ''}`}>
                  {(pageState[0] - 1) * perPageState[0] + index + 1}
                </div>
              );
            },
          }]),
          ...columnConfig.columns,
          ...(additionalColumns as ColumnsType ?? []).filter(e => e),
        ]}
        locale={locale}
        pagination={false}
        rowKey={rowKey}
        style={{ overflow: 'auto hidden' }}
        rowSelection={tableSelection?.tableProps.rowSelection}
        onRow={((record) => ({
          onClick: (event: BaseSyntheticEvent) => {
            // don't set last visited if the click is not from anchor tag
            if (event.target.nodeName !== 'A') {
              return;
            }
            if (showLastVisited) {
              lastVisitedState[1](record.id);
            }
          },
          onDoubleClick: () => {
            if (onRowClick) {
              onRowClick(record, columnProps);
            }
          }
        }))}
        onChange={columnConfig.onTableChange}
        rowClassName={(record) => {
          let className = '';
          if (rowIndicator?.visible(record)) className += ` ${rowIndicator.color}`;
          if (onRowClick) className += ' row-double-clickable';

          if (isBoolean(record.isActive) && !record.isActive) {
            className += ' row-line inactive';
            if (!map.get('inactive')) map.set('inactive', true);
          }

          if (isBoolean(record.consentInstructions?.optedOutFromAPB) && record.consentInstructions?.optedOutFromAPB) {
            className += ' row-line apbOptOut';
            if (!map.get('apbOptOut')) map.set('apbOptOut', true);
          }

          if (isBoolean(record.isLocal) && !record.isLocal) {
            className += ' row-line global';
            if (!map.get('global')) map.set('global', true);
          }

          return className;
        }}
      />
      <ApbPagination
        perPageState={perPageState}
        pageState={pageState}
        totalCount={data.totalCount}
      >
        <div className="below-table-left">
          {map.get('inactive') && (
            <span className="description inactive">Inactive</span>
          )}
          {map.get('apbOptOut') && (
            <span className="description apbOptOut">Opted out from aPurebase</span>
          )}
          {map.get('global') && (
            <span className="description global">Global Record</span>
          )}
        </div>
      </ApbPagination>
    </div>
  );
}, isEqual);

export default EntityTable;
