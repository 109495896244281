import React, { useEffect } from 'react';
import { gql } from '@apollo/client';
import { Form, Tooltip, Typography } from 'antd';
import { Locale } from '../../../../localization/LocalizationKeys';
import { usePersonFieldMutation } from '../Components/attributeFields/AttributeFields/usePersonFieldMutation';
import AddressForm from '../../../components/Address/AddressForm';
import { personFieldConfig } from './utils/personFieldsUtils';
import {
  AddressInput, AddressTypeEnum,
  UpdateHcpAddressMutationMutation,
  UpdateHcpAddressMutationMutationVariables,
} from '../../../../gql/typings';
import { TableFieldReturnedRecordPageType } from '../../search_old/types';
import { objectWithNull } from '../../../util/objectWithNull';


export const personPrivateAddressFieldConfig = personFieldConfig(
  'privateAddress',
  [
    'id',
    'countryCode',
    'privateAddresses.id',
    'privateAddresses.street',
    'privateAddresses.postalCode',
    'privateAddresses.city',
    'privateAddresses.countryCode',
    'privateAddresses.countyCode',
    'privateAddresses.communeCode',
    'privateAddresses.addressLine2',
  ],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Private_address),
    additionalTableConfig: {
      width: 120
    },
    preserve: true,
    render: (person, { isViewingFromPage }) => {
      const privateAddressField = [
        person.privateAddresses?.street,
        person.privateAddresses?.postalCode,
        person.privateAddresses?.city,
        person.privateAddresses?.countryCode,
      ].filter(e => e).join(', ');
      return (<Typography.Paragraph
        style={{ marginBottom: 0 }}
        ellipsis={{ rows: isViewingFromPage === TableFieldReturnedRecordPageType.DETAIL_PAGE ? 2 : 1 }}
      >
        <Tooltip placement='left' title={privateAddressField}>{privateAddressField}</Tooltip>
      </Typography.Paragraph>);
    },
    updateView: ({ endEditing, record: person }) => {
      const [form] = Form.useForm<AddressInput>();
      const currentInput = Form.useWatch([], form);
      const formatted = objectWithNull(currentInput);

      useEffect(() => {
        form.setFieldsValue(person.privateAddresses ?? { countryCode: person.countryCode });
      }, [form, person]);

      const {
        Renderer
      } = usePersonFieldMutation<UpdateHcpAddressMutationMutation, UpdateHcpAddressMutationMutationVariables>({
        mutation: UPDATE_MUTATION,
        controlSetting: null,
        asModal: true,
      });

      return (
        <Renderer
          heading="Update Private Address Information"
          onClose={endEditing}
          variables={{
            hcpId: person.id,
            input: {
              ...formatted,
              addressTypeCode: AddressTypeEnum.HCPPRIVATE,
            },
          }}
        >
          <AddressForm form={form} />
        </Renderer>
      );
    },
  })
);

const UPDATE_MUTATION = gql`
  mutation UpdateHcpAddressMutation($hcpId: Int!, $input: AddressInput!) {
    setPersonSingleAddress(personId: $hcpId, addressTypeCode: "HCPPRIVATE", addressInput: $input) {
      id
      affiliationEntity {
        id
        ... on Person {
          privateAddresses {
            id
            city
            countryCode
            street
            postalCode
          }
        }
      }
    }
  }
`;

