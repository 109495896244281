import { createContext } from 'react';
import { FormInstance } from 'antd/lib/form';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { StateArray } from '../../util/StateArrayType';
import { TableActionItem } from '../Table/Actions';
import { EntitiesSearchQuickActionItem } from './top/EntitiesSearchQuickActionItemType';
import { EntitiesTableQueryConfig, SupportedEntitySearchTypes } from '../../browse/search_old/types';
import { FlagCountry } from '../Flag/Flag';
import { EntitiesSearchProps } from './index';
import { DefaultCriteriasQueryQuery, MySimpleSavedSearchesQuery } from '../../../gql/typings';
import { TableRowSelectionReturnProps } from '../Table/useTableRowSelection';
import { AdvanceSearchInputCriteria } from './entitiesSearchAdvance';

type EntitiesSearchContentType = {
  entityType: SupportedEntitySearchTypes;
  selectedAdvanceCriterias: AdvanceSearchInputCriteria[];
  selectedCriterias: DefaultCriteriasQueryQuery['defaultCriterias']['nodes']['0']['criteria'][];
  setSelectedCriterias: (value: DefaultCriteriasQueryQuery['defaultCriterias']['nodes']['0']['criteria'][]) => void;

  disabledCriteriaIds?: number[];
  setDisabledCriteriaIds?: (values: number[]) => void;

  selectedCountriesState: StateArray<FlagCountry[] | undefined>;
  setSelectedQueryId?: (value: number) => void;
  searchType?: 'SIMPLE' | 'ADVANCE';

  selectedSaved: null | NonNullable<MySimpleSavedSearchesQuery['viewer']>['savedCriterias']['nodes']['0'];
  setSelectedSaved: (value: | null | NonNullable<MySimpleSavedSearchesQuery['viewer']>['savedCriterias']['nodes']['0']) => void;

  defaultCriterias?: EntitiesSearchProps['defaultCriterias'];

  form: FormInstance;
  includeInactiveRecords: false | StateArray<boolean>;
  globalState: false | StateArray<boolean>;
  includeUnplacedRecords: false | StateArray<boolean>;
  includeOptedOutRecords: false | StateArray<boolean>;

  urlSearchEnabled?: boolean;


  /**
   * When able it will open links within the table in a new table instead of in the same tab.
   */
  openInNewTab?: boolean;

  doSearch: (caller: string) => void;
  doClear: () => void;

  /**
   * a list of possible actions.
   */
  actions?: Array<TableActionItem>;

  /**
   * a list of possible quick actions-
   */
  quickActions?: Array<EntitiesSearchQuickActionItem> | null;

  tableSelection: TableRowSelectionReturnProps;

  data: EntitiesTableQueryConfig;

  tableSize?: SizeType;

  onCreateEntityTab?: () => void;

  showCreateEntityButton?: boolean;

  onlyCherryPicked?: false | StateArray<boolean>;
};

// @ts-ignore
export default createContext<EntitiesSearchContentType>({});
