import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { Col, notification, Row, Skeleton, Space, Tag } from 'antd';
import { gql } from '@apollo/client/core';
import { useNavigate } from 'react-router-dom';
import { EventEmitter } from 'ahooks/lib/useEventEmitter';
import HcpSectionRight from './HcpSectionRight';
import EntityHeaderContainer from '../../../components/ContactCard/EntityHeaderContainer';
import { Locale } from '../../../../localization/LocalizationKeys';
import Icon from '../../../components/Icon/Icon';
import { useColors } from '../../../util/useColor';
import Puffin from '../../../components/Puffin/Puffin';
import ExternalNavLink from '../../../components/ExternalNavLink/ExternalNavLink';
import { useLocalization } from '../../../util/useLocalization';
import { FlagCountryWithUnknown } from '../../../components/Flag/Flag';
import {
  CheckPersonMergeRequestQueryQuery,
  CheckPersonMergeRequestQueryQueryVariables, EntityMergeStatusEnum,
  HcpHeaderQuery,
  HcpHeaderQueryVariables
} from '../../../../gql/typings';
import { StateArray } from '../../../util/StateArrayType';
import { PersonAffiliationsTabsEnum } from './PersonAffiliationsContainer';

type PersonHeaderContainerProps = {
  personId: number;
  activeTabKeyState: StateArray<PersonAffiliationsTabsEnum>;
  refreshEmitter: EventEmitter<void>;
};

const puffinNotificationKey = 'puffin-person-entity-merged-notification';

const PersonHeaderContainer: React.FC<PersonHeaderContainerProps> = ({
  personId,
  activeTabKeyState,
  refreshEmitter,
}) => {
  const { data, loading } = useQuery<HcpHeaderQuery, HcpHeaderQueryVariables>(DATA_QUERY, { variables: { personId } });
  const {
    data: test,
  } = useQuery<CheckPersonMergeRequestQueryQuery, CheckPersonMergeRequestQueryQueryVariables>(MERGE_DATA_QUERY, {
    skip: !data?.person || data.person.isActive,
    variables: { personId },
  });
  const localization = useLocalization();
  const { colorAction: actionColor } = useColors();
  const navigate = useNavigate();
  const title = data?.person?.fullName;
  const renderOwnRecord = data?.person?.maintainerSourceCode === 'LOCAL';
  const renderInactiveBadge = data?.person && !data.person.isActive;
  const gdprStatus = data?.person?.isGdprInformed ? <Icon
    iconType="GDPR_INFORMED"
    text={localization.formatMessage(Locale.Text.GDPR_Informed)}
    toolTip={localization.formatMessage(Locale.Text.Has_received_GDPR_letter, { title })}
  /> : <Icon
    iconType="GDPR_READY"
    text={localization.formatMessage(Locale.Text.GDPR_Informed)}
    toolTip={localization.formatMessage(Locale.Text.Has_not_received_GDPR_letter, { title })}
  />;

  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    if ((test?.person?.mergeRequests?.totalCount ?? 0) > 0) {
      const request = test!.person!.mergeRequests!.nodes![0]!;
      const into = request.into as Extract<NonNullable<
      CheckPersonMergeRequestQueryQuery['person']>['mergeRequests']['nodes']['0']['into'], { ['__typename']: 'Person' }>;
      api.open({
        key: puffinNotificationKey,
        duration: null,
        message: (
          <Puffin
            loop
            type="explaining"
            message={<div style={{ textAlign: 'center' }}>{localization.formatMessage(Locale.Text.Record_has_merge_request, {
              handleState: <strong>{request.status === EntityMergeStatusEnum.UNHANDLED ? 'unhandled' : 'handled'}</strong>,
              recordName: (
                <ExternalNavLink
                  navigate={navigate}
                  to={`/hcp/${into.id}`}
                  onClick={() => api.destroy(puffinNotificationKey)}
                >
                  {into.fullName}
                </ExternalNavLink>
              ),
            })}</div>}
          />
        ),
      });
    }
  }, [api, test, navigate, localization]);

  return (
    <Skeleton loading={loading}>
      {contextHolder}
      <Row>
        <Col span={16}>
          <EntityHeaderContainer
            title={title}
            country={data?.person?.countryCode as FlagCountryWithUnknown}
          />
        </Col>
        <Col span={8}>
          <HcpSectionRight
            activeTabKeyState={activeTabKeyState}
            personId={personId}
            person={data?.person}
            refreshEmitter={refreshEmitter}
          />
        </Col>
        <Col span={24}>
          <Space>
            <div>
              <Space>
                {gdprStatus}
              </Space>
            </div>
            {renderInactiveBadge && <Tag color="#A6A6A6">
              <Icon
                iconType="CLOSE"
                text={localization.formatMessage(Locale.Text.Record_is_inactive)}
              />
            </Tag>}
            {renderOwnRecord && <Tag color={actionColor}>
              {localization.formatMessage(Locale.Text.Client_maintain)}
            </Tag>}
            {data?.person?.isCherryPicked && <Tag color="#85B0D6">
              {localization.formatMessage(Locale.Text.Cherry_picked)}
            </Tag>}
          </Space>
        </Col>
      </Row>
    </Skeleton>
  );
};


const DATA_QUERY = gql`
  query HcpHeader($personId: Int!) {
    person(id: $personId) {
      id
      fullName
      title
      countryCode
      maintainerSourceCode
      isCherryPicked
      isGdprInformed
      apurebaseId
      isActive
      primarySite {
        id
        name
        visitAddress {
          id
          street
          city
          postalCode
        }
        countryCode
        phone: mainContact(type: PHONE) {
          id
          value
        }
        email: mainContact(type: EMAIL) {
          id
          value
        }
      }
      segmentationCode {
        id
        heading
      }
    }
  }
`;


const MERGE_DATA_QUERY = gql`
  query CheckPersonMergeRequestQuery($personId: Int!) {
    person(id: $personId) {
      id
      mergeRequests {
        hash
        totalCount
        nodes {
          id
          status
          entityAffiliationId
          intoEntityAffiliationId
          into {
            ... on Person {
              id
              fullName
            }
          }
        }
      }
    }
  }
`;

export default PersonHeaderContainer;
