/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Form, Input } from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import { MessageDescriptor } from '@formatjs/intl/src/types';
import { AdvanceCriteriaInputProps } from '../AdvanceCriteriaInputTools';
import { useLocalization } from '../../../util/useLocalization';

const AdvanceCriteriaTextInput: React.FC<AdvanceCriteriaInputProps> = React.memo(({
  form,
  criteria,
}) => {
  const { formatMessage } = useLocalization();
  useEffect(() => {
    if (criteria.value) {
      form.setFieldsValue({ [criteria.code]: criteria.value });
    }
  }, [criteria.value]);
  return (
    <Form.Item name={criteria.code}>
      <Input
        id={criteria.code}
        size='large'
        placeholder={criteria.isPlaceholderKey
          ? formatMessage(criteria!.placeholder! as MessageDescriptor)
          : criteria.placeholder || ''}
        /* Seems that autoComplete={off} is not working for chrome. Why newpassword is beint used */
        autoComplete="newpassword"
      />
    </Form.Item>
  );
});

export default AdvanceCriteriaTextInput;
