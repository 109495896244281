/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Form } from 'antd';
import { gql, useApolloClient, useLazyQuery, useQuery } from '@apollo/client';
import { FormInstance } from 'antd/lib/form/Form';
import { useNavigate } from 'react-router';
import queryString from 'query-string';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { useLocation } from 'react-router-dom';
import { isArray, isEmpty, isEqual, isObject, omitBy, uniqBy } from 'lodash';
import Context from './EntitiesSearchContext';
import Results, { EntitySearchRowIndicator } from './results/EntitiesSearchResults';
import { SupportedEntitySearchTypes, useEntitiesSearchQuery } from '../../browse/search_old/types';
import { TableActionItem } from '../Table/Actions';
import { CriteriaBuilderHiddenFilter } from '../../util/criteriaBuilderSelector';
import { EntitiesSearchQuickActionItem } from './top/EntitiesSearchQuickActionItemType';
import { AnyValueType, StateArray } from '../../util/StateArrayType';
import { useBroadcastStorage } from '../../util/useBroadcastStorage';
import { generateTableColumnsSortingKey, orderingFiltering, SortingStorage } from './results/useTableColumns';
import { useLocalization } from '../../util/useLocalization';
import {
  CriteriaTypeEnum,
  DefaultAdvanceSearchQueryQuery,
  DefaultCriteriasQueryQuery,
  DefaultCriteriasQueryQueryVariables, EntityTypeEnum,
  Maybe,
  MySimpleSavedSearchesQuery,
  PersonInput,
  SaveSearchInput,
  Scalars,
  SearchArgInput, SearchConditionEnum, SearchNodesFragmentFragment, SingleAdvanceSearchQuery, SingleAdvanceSearchQueryVariables,
  SiteInput
} from '../../../gql/typings';
import { useSystemCountriesState } from '../../util/useSystemCountriesState';
import { useCriteria } from './useEntitiesSearchCriteria';
import { TableRowSelectionReturnProps, useTableRowSelection } from '../Table/useTableRowSelection';
import { PersonInputCodeMap } from '../../browse/person/create/CreatePerson';
import { SiteInputCodeMap } from '../../browse/site/create/CreateSite';
import EntitiesSearchAdvanceTop from './top/EntitiesSearchAdvanceTop';
import { DEFAULT_CRITERIAS_DATA_QUERY } from './index';

const filterSortBasedOnCriteriaValue = (a: AdvanceSearchInputCriteria, b: AdvanceSearchInputCriteria) => {
  if (!a.value && b.value) {
    return 1;
  }
  return -1;
};


export type AdvanceSearchInputCriteria = {
  code: string;
  value: AnyValueType;
  criteriaId: number | null | undefined;
  heading: string | null | undefined;
  inputType: CriteriaTypeEnum;
  placeholder: string | null | undefined;
  conditionType?: SearchConditionEnum;
  service: string | null | undefined;
  operation?: string;
  inputHeadingMeta?: string;
  parentEntityType?: EntityTypeEnum;
  isHeadingKey?: Maybe<Scalars['Boolean']>;
  isPlaceholderKey?: Maybe<Scalars['Boolean']>;
  allowMultiple?: Maybe<Scalars['Boolean']>;
  allowNoValue?: Maybe<Scalars['Boolean']>;
};


export const EntitiesSearchContext = Context;

export type EntitiesSearchProps = {
  form?: FormInstance;
  entityType: SupportedEntitySearchTypes;
  globalSupport?: boolean;
  globalSearch?: boolean;
  globalState?: false|StateArray<boolean>;
  includeInactiveRecords?: false|StateArray<boolean>;
  includeUnplacedRecords?: false|StateArray<boolean>;
  includeOptedOutRecords?: false|StateArray<boolean>;
  onlyCherryPicked?: false|StateArray<boolean>;
  tableSelection?: TableRowSelectionReturnProps;
  actions?: Array<TableActionItem>;
  quickActions?: Array<EntitiesSearchQuickActionItem>|null;
  defaultCriterias?: {
    criteriaCode: string;
    value: AnyValueType;
    disabled?: boolean;
  }[]|null;
  urlSearchEnabled?: boolean;

  /**
   * This will add extra hidden criteria filter on top of the criteria search.
   * These do not use the dynamicSearch, but rather just the standard CriteriaSearch.
   */
  criteriaFilter?: CriteriaBuilderHiddenFilter;

  /**
   * When able it will open links within the table in a new table instead of in the same tab.
   */
  openInNewTab?: boolean;
  rowIndicator?: EntitySearchRowIndicator;
  children?: React.ReactNode;
  onVisibleKeysChange?: (keys: React.Key[]) => void;
  onSelectedCountriesChange?: (selectedCountries: string[]|undefined) => void;
  tableSize?: SizeType;
  onCreateEntityTab?: () => void;
  showCreateEntityButton?: boolean;
};

export type EntitiesSearchRef = {
  refetch: () => void;
  clearCriterias: () => void;
  formValues: PersonInput;
};

const EntitiesSearchAdvance = forwardRef<EntitiesSearchRef, EntitiesSearchProps>(({
  form = Form.useForm()[0],
  entityType,
  globalSupport = false,
  globalSearch,
  globalState = globalSupport && useState<boolean>(false),
  includeInactiveRecords = useState<boolean>(false),
  includeUnplacedRecords = useState<boolean>(false),
  includeOptedOutRecords = useState<boolean>(false),
  onlyCherryPicked=useState<boolean>(false),
  tableSelection = useTableRowSelection({ entityType }),
  actions,
  quickActions,
  urlSearchEnabled = false,
  openInNewTab,
  rowIndicator,
  defaultCriterias = null,
  criteriaFilter,
  onVisibleKeysChange,
  onSelectedCountriesChange,
  tableSize,
  onCreateEntityTab,
  showCreateEntityButton,
  children,
}, ref) => {
  const [formValues, setFormValues] = useState(() => form.getFieldsValue());
  const navigate = useNavigate();
  const location = useLocation();
  const localization = useLocalization();
  const [disabledCriteriaIds, setDisabledCriteriaIds] = useState<number[]>([]);
  const [selectedQueryId, setSelectedQueryId] = useState<number | null | undefined>(null);
  const [searchInput, setSearchInput] = useState<SaveSearchInput | undefined>(undefined);
  const [querySearchInput, setQuerySearchInput] = useState<SaveSearchInput | undefined>(undefined);

  const urlValues = queryString.parseUrl(location.search).query;
  // const [isIncludeInactiveRecords] = includeInactiveRecords || [false];

  const { data: advanceSearch } = useQuery<DefaultAdvanceSearchQueryQuery>(
    DEFAULT_ADVANCE_SEARCH_DATA_QUERY, {
      fetchPolicy: 'network-only',
      skip: !!(selectedQueryId || urlValues.searchId)
    },
  );

  const [getSearch, {
    data: advanceSearchSingle }] = useLazyQuery<SingleAdvanceSearchQuery
  , SingleAdvanceSearchQueryVariables>(SINGLE_ADVANCE_SEARCH_DATA_QUERY, {
    fetchPolicy: 'no-cache'
  });
  const [
    selectedCriterias,
    setSelectedCriterias,
  ] = useState<NonNullable<DefaultCriteriasQueryQuery['defaultCriterias']>['nodes']['0']['criteria'][]>([]);
  const [
    selectedAdvanceCriterias,
    setSelectedAdvanceCriterias
  ] = useState<AdvanceSearchInputCriteria[]>([]);
  const [
    selectedSaved,
    setSelectedSaved,
  ] = useState<null | NonNullable<MySimpleSavedSearchesQuery['viewer']>['savedCriterias']['nodes']['0']>(null);
  const [ordering] = useBroadcastStorage<SortingStorage[]>(
    generateTableColumnsSortingKey(entityType),
    [],
    orderingFiltering(entityType, navigate, localization)
  );
  const selectedCountriesState = useSystemCountriesState();

  const criteria = useCriteria({
    formValues,
    ordering,
    pageState: tableSelection.tableProps.pageState,
    perPage: tableSelection.tableProps.perPageState[0],
    globalState,
    includeInactiveRecords,
    includeUnplacedRecords,
    includeOptedOutRecords,
    selectedCountriesState,
    entityType,
    criteriaFilter,
    onlyCherryPicked,
    searchInput: querySearchInput
  });
  const data = useEntitiesSearchQuery(entityType, criteria, openInNewTab, globalSearch);
  const [initialLoad, setInitialLoad] = useState(false);

  const apolloClient = useApolloClient();


  const runSearch = useCallback((searchNode: SearchNodesFragmentFragment) => {
    const inputCriterias = getInputCriterias(searchNode?.groups).sort(filterSortBasedOnCriteriaValue);
    const searchInput1 = getSearchInput(searchNode?.searchId, inputCriterias);
    setSearchInput(searchInput1);
    setSelectedAdvanceCriterias(inputCriterias);
  }, []);


  useEffect(() => {
    if (advanceSearch && !selectedQueryId && !urlValues.searchId && !initialLoad) {
      setInitialLoad(true);
      runSearch(advanceSearch?.defaultSearch);
    }
  }, [advanceSearch]);

  useEffect(() => {
    if (selectedQueryId) {
      getSearch({
        variables: {
          searchId: selectedQueryId
        }
      });
    }
  }, [selectedQueryId]);

  useEffect(() => {
    if (advanceSearchSingle) {
      runSearch(advanceSearchSingle?.singleSearch);
    }
  }, [advanceSearchSingle]);
  useEffect(() => {
    onSelectedCountriesChange?.(selectedCountriesState[0]);
  }, [onSelectedCountriesChange, selectedCountriesState[0]]);

  useEffect(() => {
    onVisibleKeysChange?.(data?.data?.connection?.nodes?.map((k: { id: number }) => k.id) ?? []);
  }, [data]);


  const inputParseValue = (values : Array<string> | Array<{ value : string }> | { value: string }): string => {
    if (isArray(values)) {
      return values.map(val => isObject(val) ? inputParseValue(val): val).join(',');
    }
    if (isObject(values)) {
      return values.value;
    }
    return values;
  };
  const getInputCriterias = (
    groups: DefaultAdvanceSearchQueryQuery['defaultSearch']['groups']
  ): AdvanceSearchInputCriteria[] => {
    let inputCriteriaList: AdvanceSearchInputCriteria[] = [];
    groups.forEach(grp => {
      const grpInputCriterias = grp.conditions.map(cond => {
        const configuration = cond.inputConfiguration;
        return {
          code: cond.code,
          criteriaId: cond.criteriaId,
          inputType: configuration?.inputType || CriteriaTypeEnum.Text,
          service: configuration?.valueService,
          heading: configuration?.inputHeading,
          conditionType: cond.type,
          inputHeadingMeta: configuration?.inputHeadingMeta,
          placeholder: configuration?.inputPlaceholder,
          isHeadingKey: configuration?.isInputHeadingKey,
          isPlaceholderKey: configuration?.isInputPlaceholderKey,
          allowMultiple: configuration?.allowMultiple,
          operation: cond.operation,
          allowNoValue: configuration?.allowNoValue,
          parentEntityType: configuration?.parentEntityType,
          value: cond.value
        } as AdvanceSearchInputCriteria;
      });
      inputCriteriaList = [...inputCriteriaList, ...grpInputCriterias];
    });
    return inputCriteriaList;
  };
  const getSearchInput = (
    searchId: number | null | undefined, inputCriteria: AdvanceSearchInputCriteria[]
  ): SaveSearchInput => {
    const searchValue = inputCriteria.map(inp => ({
      conditionCode: inp.code,
      values: (inp.value && (Array.isArray(inp.value) && inp.value.length > 0 ? inp.value as Array<string> :[''])) || ['']
    })).filter(inp => inp.values && inp.values.length > 0);

    return {
      searchId,
      ...(searchValue.length > 0 ? { values: searchValue } : {})
    };
  };
  const doSearch = () => {
    tableSelection.tableProps.pageState[1](1);
    setFormValues(form?.getFieldsValue());
  };

  const doClear = () => {
    navigate(location.pathname);
    form.resetFields();
    const data = apolloClient.readQuery<DefaultCriteriasQueryQuery, DefaultCriteriasQueryQueryVariables>({
      query: DEFAULT_CRITERIAS_DATA_QUERY,
      variables: { entityType },
    });
    setSelectedCriterias(data?.defaultCriterias.nodes.map(n => n.criteria) ?? []);
    if (globalState && globalState[1]) globalState[1](false);
  };

  useEffect(() => {
    const { searchId, ...urlFormValues } = urlValues;
    if (searchId && +searchId !== selectedQueryId) {
      setSelectedQueryId(+searchId);
    }
    if (searchInput && searchId) {
      if (urlFormValues) {
        const formattedValues = Object.keys(urlFormValues).reduce((acc, key) => {

          if (key && urlFormValues[key]) {
            const currentVal = urlFormValues[key] as string;
            if (currentVal.includes(',') && !isValidJSON(currentVal)) {
            // @ts-ignore
              acc[key] = currentVal.split(',');
            } else {
              // @ts-ignore
              acc[key] = urlFormValues[key]; // Adjust the value as desired
            }
          }
          return acc;
        }, {});
        form.setFieldsValue(formattedValues);
        if (!isEqual(formattedValues, formValues)) {
          setFormValues(formattedValues);
        }
      }
    }
  }, [searchInput]);

  useEffect(() => {
    if (isEmpty(formValues)) return;
    if (urlSearchEnabled) {
      // const serialized = queryString.stringify(serializeCriteriaInput(apolloClient, criteria.criteria.criterias));
      let serialized = selectedQueryId ? `searchId=${selectedQueryId}&&` : '';
      const serialisedFormValue = Object.keys(formValues).reduce((acc, key, currentIndex) => {
        if (key) {
          const valToConcat = inputParseValue(formValues[key]);
          acc += `${key}=${valToConcat}${currentIndex !== Object.keys(formValues).length - 1 ? '&&' : ''}`;
          return acc;
        }
        return acc;
      }, '');
      serialized += serialisedFormValue;
      const newUrl = `${location.pathname}?${serialized}`;
      if (serialized && `${location.pathname}${location.search}` !== newUrl) {
        navigate(newUrl);
      }
    } else {
      const newValues = form.getFieldsValue();
      if (!isEqual(formValues, newValues)) {
        setFormValues(form.getFieldsValue());
      }
    }
    const formattedFormValues = omitBy(formValues, v => v === undefined);
    const searchInputTemp = { ...searchInput } as SaveSearchInput;
    const searchArgs: SearchArgInput[] = [];
    Object.keys(formattedFormValues).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(formattedFormValues, key)) {
        searchArgs.push({
          conditionCode: key,
          values: Array.isArray(formattedFormValues[key]) ? formattedFormValues[key] : [formattedFormValues[key]] });
      }
    });
    if (searchArgs.length > 0) {
      if (searchInputTemp.values) {
        const aggregatedValues = uniqBy([...searchInputTemp.values, ...searchArgs].reverse(), 'conditionCode');
        searchInputTemp.values = (aggregatedValues || []) as SearchArgInput[];
        setQuerySearchInput(searchInputTemp);
      }
    }

  }, [formValues]);


  const formatValues = (fValues: object): PersonInput | SiteInput => Object.entries(fValues).reduce((acc, cur) => {
    const criteriaCode = cur[0].split('-')[0]?.split('ci')[1];
    if (!criteriaCode) return acc;
    // @ts-ignore
    const criteriaInputCode = apolloClient.cache.data.data[`Criteria:${criteriaCode}`].code;
    if (!criteriaInputCode) return acc;
    if (entityType === 'SITE') {
      if (!Object.prototype.hasOwnProperty.call(SiteInputCodeMap, criteriaInputCode)) return acc;
      // @ts-ignore
      return { ...acc, [SiteInputCodeMap[criteriaInputCode]]: cur[1] };
    }
    if (entityType === 'PERSON') {
      if (!Object.prototype.hasOwnProperty.call(PersonInputCodeMap, criteriaInputCode)) return acc;
      // @ts-ignore
      return { ...acc, [PersonInputCodeMap[criteriaInputCode]]: cur[1] };
    }
    return acc;
  }, {}) as PersonInput;

  const doRefetch = () => {
    doSearch();
    setSelectedSaved(null);
  };

  useImperativeHandle(ref, () => ({
    refetch: doRefetch,
    clearCriterias: doClear,
    formValues: formatValues(formValues)
  }) as EntitiesSearchRef, [formValues]);
  return (
    <Context.Provider
      value={{
        selectedAdvanceCriterias,
        searchType: 'ADVANCE',
        entityType,
        selectedCriterias,
        setSelectedCriterias,
        globalState,
        tableSelection,
        form,
        setSelectedQueryId,
        doSearch,
        doClear,
        data,
        actions,
        quickActions,
        includeInactiveRecords,
        selectedSaved,
        setSelectedSaved,
        disabledCriteriaIds,
        setDisabledCriteriaIds,
        defaultCriterias,
        urlSearchEnabled,
        openInNewTab,
        includeUnplacedRecords,
        includeOptedOutRecords,
        selectedCountriesState,
        tableSize,
        onCreateEntityTab,
        showCreateEntityButton
      }}
    >
      <div className="entities-search-index-container">
        <EntitiesSearchAdvanceTop
          searchHeading={(selectedQueryId
            ? advanceSearchSingle?.singleSearch.heading
            : advanceSearch?.defaultSearch.heading) || ''}
        />
        {children ?? (
          <Results rowIndicator={rowIndicator} onRowClick={data.onRowClick} />
        )}
      </div>
    </Context.Provider>
  );
});

export default EntitiesSearchAdvance;


export const SEARCH_NODE_FRAGMENT = gql`
  fragment SearchNodesFragment on SearchNode {
    id:searchId
    searchId
    heading
    code
    groups {
      conditions {
        dataType
        id:code
        code
        criteriaId
        type
        paths {
          mapper
          mapperTo
          mapperFrom
          operator
        }
        operation
        inputConfiguration {
          id: inputHeading
          valueService
          inputHeading
          inputPlaceholder
          inputHeadingMeta
          parentEntityType
          isInputPlaceholderKey
          isInputHeadingKey
          allowMultiple
          allowNoValue
          inputType
        }
        operation
        value
      }
      nextOperation
    }
  }
`;

const DEFAULT_ADVANCE_SEARCH_DATA_QUERY = gql`
  query DefaultAdvanceSearchQuery {
    defaultSearch {
    ...SearchNodesFragment
  }
  }
  ${SEARCH_NODE_FRAGMENT}
`;

export const SINGLE_ADVANCE_SEARCH_DATA_QUERY = gql`
  query SingleAdvanceSearch($searchId: ID!) {
    singleSearch(id: $searchId) {
      ...SearchNodesFragment
    }
  }
  ${SEARCH_NODE_FRAGMENT}
`;

function isValidJSON(jsonString: string) {
  try {
    JSON.parse(jsonString);
    return true;
  } catch {
    return false;
  }
}
