import React, { useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, Form, Input, Modal, Select } from 'antd';
import { gql, useQuery } from '@apollo/client';
import { useForm } from 'antd/es/form/Form';
import { useLocalization } from '../../../util/useLocalization';
import { Locale } from '../../../../localization/LocalizationKeys';
import { Optional } from '../../../util/StateArrayType';
import {
  EntityTypeEnum,
  AddressInput, CreateAddAddressQueryQuery, CreateAddAddressQueryQueryVariables
} from '../../../../gql/typings';
import AddressForm from '../../../components/Address/AddressForm';
import { useBroadcastStorage } from '../../../util/useBroadcastStorage';
import { objectWithNull } from '../../../util/objectWithNull';

type CreateAddAddressModalProps = {
  value?: Optional<AddressInput>;
  toPatchValue?: Optional<AddressInput>;
  onChange?: (value: Optional<AddressInput>) => void;
  countryCode?: Optional<string>;
  entityType: EntityTypeEnum;
  usedAddressTypes: Array<string | undefined>;
  showCopy?: boolean;
  isOpen?: boolean;
  onFinish?: () => void;
};

const CreateAddAddressModal: React.FC<CreateAddAddressModalProps> = ({
  value,
  toPatchValue,
  onChange,
  countryCode,
  entityType,
  showCopy,
  usedAddressTypes,
  isOpen = false,
  onFinish
}) => {
  const [form] = useForm<AddressInput>();
  const [visible, setVisible] = useState(isOpen);
  const [, setFormUpdate] = useState(false);
  const localization = useLocalization();
  const [copyAddress, setCopyAddress] = useBroadcastStorage<boolean>('copy-address', false);
  const { data, loading } = useQuery<CreateAddAddressQueryQuery, CreateAddAddressQueryQueryVariables>(DATA_QUERY, {
    variables: { entityType },
  });

  useEffect(() => {
    if (data?.addressTypes.nodes && !value?.addressTypeCode && usedAddressTypes.indexOf(undefined) > -1) {
      const defaultTypeCodes = data.addressTypes.nodes.filter(x => usedAddressTypes.indexOf(x.code) === -1);
      if (defaultTypeCodes.length > 0) {
        onChange?.({
          ...(value ?? {}),
          addressTypeCode: defaultTypeCodes[0]?.code ?? '-1',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (copyAddress && toPatchValue && Object.keys(value || {}).length === 1) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { addressTypeCode, ...rem } = toPatchValue;
      form.setFieldsValue(rem);
      if (!isOpen) {
        onChange?.({ ...value, ...form.getFieldsValue(true) });
      }
      setFormUpdate(prev => !prev);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copyAddress, toPatchValue, form]);

  const valueChange = (newValue: Partial<AddressInput>) => {
    if (newValue.addressTypeCode) onChange?.(newValue as AddressInput);
    else if (value) onChange?.(undefined);
  };

  const onSave = () => {
    const formValues = form.getFieldsValue(true) as AddressInput;
    const formatted= objectWithNull(formValues);
    onChange?.({ ...value, ...formatted as AddressInput });
    setVisible(false);
    if (onFinish) {
      onFinish();
    }
  };

  const formValues = form.getFieldsValue(true);

  const displayStr = useMemo(() => Object.keys(formValues).length > 0
    ? [formValues?.street, formValues?.postalCode, formValues?.city, formValues?.countryCode || countryCode]
      .filter(e => e)
      .join(', '): '', [formValues, countryCode]);

  return (
    <>
      <Input
        onClick={() => setVisible(true)}
        value={displayStr === countryCode ? '' : displayStr}
        placeholder={localization.formatMessage(Locale.Text.Press_to_enter_address_information)}
        allowClear
        onChange={e => {
          if (e.target.value !== '') return;
          onChange?.(undefined);
        }}
      />
      <Modal
        title={localization.formatMessage(Locale.Command.Enter_address_information)}
        open={visible}
        onCancel={() => {
          form.setFieldsValue({});
          setVisible(false);
        }}
        closeIcon={<></>}
        forceRender // <-- This is needed for initial values to work for address fields
        footer={<Button type="primary" onClick={onSave}>
          {localization.formatMessage(Locale.General.Ok)}
        </Button>}
      >
        <Form
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          labelAlign="left"
          form={form}
        >
          <Form.Item label={localization.formatMessage(Locale.Attribute.Type)}>
            <Select
              loading={loading}
              value={value?.addressTypeCode}
              onChange={e => valueChange({
                ...value,
                addressTypeCode: e,
              })}
            >
              {(data?.addressTypes.nodes ?? [])
                .filter(type => (usedAddressTypes.indexOf(type.code) === -1
                  || (usedAddressTypes.indexOf(type.code) > -1
                    && value?.addressTypeCode === type.code)))
                .map(type => (
                  <Select.Option key={type.code} value={type.code}>
                    {localization.formatMessageByStr(type.headingKey)}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <AddressForm form={form} />
          {showCopy && <Form.Item>
            <Checkbox
              checked={copyAddress}
              onChange={e => {
                setCopyAddress(e.target.checked);
              }}
            >Copy Address</Checkbox>
          </Form.Item>}
        </Form>
      </Modal>
    </>
  );
};

const DATA_QUERY = gql`
  query CreateAddAddressQuery($entityType: EntityTypeEnum) {
    addressTypes(criteria: { entityType: $entityType }) {
      hash
      nodes {
        code
        headingKey
      }
    }
  }
`;

export default CreateAddAddressModal;
